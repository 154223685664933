import React from 'react';
import { useParams } from "react-router-dom";
import Loading from '../Loading/Loading';
import { GoogleMap, MarkerF, Polyline, PolylineF, useLoadScript } from '@react-google-maps/api';
import { Timestamp, collection, getDocs, limit, orderBy, query } from 'firebase/firestore';
import { firestore } from '../../Firebase/Firebase';
import gpsArrow from "../../Assets/SVG/gps-arrow.svg";
import { Card, CardContent, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import dayjs from 'dayjs';

const mapOptions:google.maps.MapOptions={
    zoom: 8,
    center: {
        lat: 26.087545,
        lng: 50.549084
    },
    clickableIcons: false,
    fullscreenControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    draggable: true,
    scrollwheel: true,
    disableDoubleClickZoom: true,
    gestureHandling: "greedy",
    disableDefaultUI: true,
    mapTypeId: "hybrid",

  }
export type LocationLogDoc = {
    docID:string;
    latitude:number;
    longitude:number;
    timestamp:number;
    loggedAt?:Timestamp;
};
export default function LocationLog() {
  const { URLTrackingID } = useParams();
  const [loading, setLoading] = React.useState<boolean>(true);
  const [locationLog, setLocationLog] = React.useState<LocationLogDoc[]>([]);
  const [selectedLog, setSelectedLog] = React.useState<LocationLogDoc | null>(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY as string,
  });
  const mapRef = React.useRef<any>(null);
  const center = React.useMemo<{ lat: number; lng: number }>(
    () => ({
      lat: 26.087545,
      lng: 50.549084,
    }),
    []
  );
React.useEffect(() => {
    const q=query(collection(firestore,`Tracking/${URLTrackingID}/LocationLog`),orderBy("loggedAt","asc"),limit(300));
    getDocs(q).then((querySnapshot) => {
        const tempLocationLog:LocationLogDoc[]=[];
        querySnapshot.forEach((doc) => {
            tempLocationLog.push({...doc.data(),docID:doc.id} as LocationLogDoc);
        });
        setLocationLog(tempLocationLog);
        setLoading(false);
    });

}, [URLTrackingID]);
  if (loading || !isLoaded) {
    return <Loading fullscreen />;
  }
  return (
    <>
    <Card sx={{position:"fixed",right:"1rem",top:"1rem",zIndex:"10000",height:"calc(100svh - 2rem)",boxSizing:"border-box",overflowY:"scroll",
    "-webkit-scrollbar": "{display: none}", "-ms-overflow-style": "none", "scrollbar-width": "none"
}}>
        <CardContent>
        <Typography variant="h6">Location Log</Typography>
        <Divider variant='fullWidth'/>
            <Table stickyHeader>
                <TableHead >
                    <TableRow sx={{backgroundColor:"#1c2a57",color:"white"}}>
                        <TableCell sx={{color:"#1c2a57", fontWeight:"bold"}}/>
                        <TableCell sx={{color:"#1c2a57", fontWeight:"bold"}}>Latitude</TableCell>
                        <TableCell sx={{color:"#1c2a57", fontWeight:"bold"}}>Longitude</TableCell>
                        <TableCell sx={{color:"#1c2a57", fontWeight:"bold"}}>Fetched At</TableCell>
                        <TableCell sx={{color:"#1c2a57", fontWeight:"bold"}}>Logged At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locationLog.map((locationEntry,index) => (
                        <TableRow key={locationEntry.docID} sx={{
                            "&:hover":{
                                backgroundColor:"#f2f2f2",
                                cursor:"pointer"
                            },
                            backgroundColor:selectedLog?.docID===locationEntry.docID?"#f2f2f2":"white",
                        }} onClick={() => {
                            if(locationEntry.docID===selectedLog?.docID){
                                setSelectedLog(null);
                                return;
                            }
                            setSelectedLog(locationEntry);
                            mapRef.current.panTo({
                                lat: locationEntry.latitude,
                                lng: locationEntry.longitude,
                            });
                            mapRef.current.setZoom(20);

                        }}>
                            <TableCell >{index+1}</TableCell>
                            <TableCell >{locationEntry.latitude}</TableCell>
                            <TableCell >{locationEntry.longitude}</TableCell>
                            <TableCell >{dayjs(locationEntry.timestamp).format("HH:mm:ss")}</TableCell>
                            <TableCell >{dayjs(locationEntry.loggedAt?.toDate()).format("HH:mm:ss") || "N/A"}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </CardContent>

    </Card>
    <GoogleMap
    center={center}
    mapContainerStyle={{ width: "100%", height: "100svh" }}
    options={mapOptions}
    ref={mapRef}
    onLoad={(map) => {
      mapRef.current = map;
    }}
  >
    <PolylineF
        path={locationLog.map((locationEntry) => ({
            lat: locationEntry.latitude,
            lng: locationEntry.longitude,
        }))}
        options={{
            strokeColor: "#1c2a57",
            strokeOpacity: 0.8,
            strokeWeight: 2,
        }}
    />
      {
        locationLog.length>1 && locationLog.map((locationEntry,index) => {
            if(index===0){
                return null;
            }
            return (
                <MarkerF
                    key={locationEntry.docID}
                    position={{ lat: locationEntry.latitude, lng: locationEntry.longitude }}
                    clickable={true}
                    label={{
                    text: `${index+1}`,
                    color: "white",
                    fontSize: "12px",
                    fontWeight: "bold",
                    }}
                    icon={{
                    path: google.maps.SymbolPath.CIRCLE,
                    scale: 8,
                    fillColor: "#1c2a57",
                    fillOpacity: 1,
                    strokeWeight: 0.4,
                    }}
                />

            );
        })}
      
  </GoogleMap>
  </>
  );
}