import { Typography } from "@mui/material";
import styled from "styled-components";
import SignInForm from "./SignInForm";
import { AuthContextValue, useAuth } from "../../../Contexts/AuthContext";
const MainContainer=styled.div`
display:grid;
grid-template-rows: 1fr 2fr 1fr;
padding:10%;
padding-bottom:0;
padding-top:10%;
box-shadow: -5px 0px 10px 0px rgba(0,0,0,0.75);
background-color:white;
z-index:1;
`;
const Header=styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
width:fit-content;`;


export default function Sidepanel() {
    const authContext:AuthContextValue=useAuth();
    return(
        <MainContainer>
            <Header>
                <Typography variant="h3" color="primary" >Sign In</Typography>
                <Typography variant="body2" color="grey" >Gain Access to Bahrain & GCC's largest road fright logistics network !</Typography>
                <Typography variant="body2" color="grey" >V {process.env.REACT_APP_VERSION}</Typography>
            </Header>
            <SignInForm/>
        </MainContainer>    
    );
}

