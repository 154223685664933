import { Autocomplete, Badge, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, InputLabel, MenuItem, Paper, Select, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography } from "@mui/material";
import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import UploadFile from "@mui/icons-material/UploadFile";
import { FormikProps, useFormik } from "formik";
import styled from "styled-components";
import * as Yup from 'yup';
import { COMMODITIES, CONTAINER_TO_UNIT, Transition } from "./ShipmentForm";
import ContainerSection from "./ContainerSection";
import { query, collection, where, onSnapshot, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";
import { firestore } from "../../../Firebase/Firebase";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import { useAuth } from "../../../Contexts/AuthContext";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ACTIONS, BL, Container, INITIAL_MOVEMENT, Movement, ShipmentForm as ShipmentFormType , CRF, INITIAL_CONTAINER, BulkUpload} from "../Logic/NewShipmentLogic";
import ContainerDialog from "./ContainerDialog";
import dayjs from 'dayjs';


  export function validateContainerNumber(containerNumber:string){
    const regex = /^[A-Z]{4}[0-9]{7}$/;
    if (regex.test(containerNumber)){
      const letterValue = {
        "A": 10,
        "B": 12,
        "C": 13,
        "D": 14,
        "E": 15,
        "F": 16,
        "G": 17,
        "H": 18,
        "I": 19,
        "J": 20,
        "K": 21,
        "L": 23,
        "M": 24,
        "N": 25,
        "O": 26,
        "P": 27,
        "Q": 28,
        "R": 29,
        "S": 30,
        "T": 31,
        "U": 32,
        "V": 34,
        "W": 35,
        "X": 36,
        "Y": 37,
        "Z": 38
    }
    //HLBU8116408
    let sum=0;
    for(let i=0;i<10 ; i++){
      if(i<4){
        //@ts-ignore
        sum+=letterValue[containerNumber[i].toUpperCase()]*(2**i);
      }else{
        sum+=Number(containerNumber[i])*(2**i);
      }
    }
    const first=Math.floor(sum/11);
    const second=first * 11;
    const checkDigit=(sum-second) % 10;
    if(checkDigit===Number(containerNumber[10])){
      return true;
    }
  }
  }

export default function AddContainerMovement({containerTypeOptions,onSubmit,open,setOpen,disableBL,disableCRF,disableBulk}:{containerTypeOptions:string[],open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>,onSubmit:(values:Container)=>void,disableBL?:boolean,disableCRF?:boolean,disableBulk?:boolean}) {
  const [currentTab,setCurrentTab]=React.useState<number>(0);
  const [selectedBL,setSelectedBL]=React.useState<BL | null>(null);
  const [selectedContainer,setSelectedContainer]=React.useState<Container>();
  const [selectedCRF,setSelectedCRF]=React.useState<CRF | null>(null);
  const [selectedBulk,setSelectedBulck]=React.useState<BulkUpload | null>(null);
  const containerForm=useFormik({
    initialValues:INITIAL_CONTAINER as Container,
    validationSchema:Yup.object({
      number:Yup.string().when('CRFRef',{
        is:(CRFRef:string) =>CRFRef && CRFRef.length > 0,
        then:()=>{return(Yup.string())},
        otherwise:()=>{return(Yup.string().required("Invalid Container Number").test("valid-container-number","Invalid Container Number",validateContainerNumber))}
      }),
      type:Yup.string().required("Required"),
      containerMovementType:Yup.string().required("Required"),
      bookingNumber:Yup.string().when('containerMovementType',{
        is:(containerMovementType:string)=>containerMovementType==="EXP",
        then:()=>{return(Yup.string().required("Required").max(30,"Invalid Booking Number"))},
        otherwise:()=>{return(Yup.string().max(30,"Invalid Booking Number"))}
      }),
      sealNumber:Yup.string().when('empty',{
        is:false,
        then:()=>{return(Yup.string().matches(/^[a-zA-Z0-9]+$/,"Invalid seal number").required("Required"))},
        otherwise:()=>{return(Yup.string().equals([""],"Invalid Seal Number"))}
      }),
      commodity:Yup.string().when('empty',{
        //@ts-ignore
        is:false,
        then:()=>{return(Yup.string().required("Required"))},
        otherwise:()=>{return(Yup.string().equals([""],"Invalid Commodity"))}
      }),
      empty:Yup.boolean().required("Required"),
      //@ts-ignore
      netWeight:Yup.number().required("Required").when('empty',{
        is:false,
        then:()=>{return(Yup.number().required("Required").min(0,"Invalid Weight").max(40000,"Invalid Weight"))},
        otherwise:()=>{return(Yup.number().equals([0],"Invalid Weight"))}
      }),
    }),
    onSubmit:(values)=>{
      //autoconfirm on container details submission
      if(values.number && values.type  && (!values.BLRef || values.BLRef==="") && (!values.CRFRef || values.CRFRef==="") && (!values.bulkRef || values.bulkRef==="")){
        values.confirmedSelection=true;
      }
      onSubmit(values);
    },
  });
  console.log(containerForm.errors)
  console.log(containerForm.values)
  React.useEffect(()=>{
    if(selectedBL){
      if(selectedContainer){
        containerForm.setValues({...selectedContainer,BLRef:selectedBL.number,confirmedSelection:true});
      }else{
        containerForm.setValues({...selectedBL.containers[0],BLRef:selectedBL.number});
      }

      console.log("selectedBL",selectedBL)
    }
    else{
      containerForm.resetForm();
    }
  },[selectedBL,selectedContainer]);
  React.useEffect(()=>{
    if(selectedCRF){
      containerForm.setValues({...selectedCRF.containers[0],CRFRef:selectedCRF.bookingNumber})
    }
  },[selectedCRF])
  React.useEffect(()=>{
    if(selectedBulk){
      if(selectedContainer){
        containerForm.setValues({...selectedContainer,bulkRef:selectedBulk.docID,confirmedSelection:true});
      }
      else{
        containerForm.setValues({...selectedBulk.containers[0],bulkRef:selectedBulk.docID});
      } 
    }
  },
  [selectedBulk,selectedContainer])

  React.useEffect(()=>{
    if(!open){
      containerForm.resetForm();
      setSelectedBL(null);
      setSelectedCRF(null);
      setSelectedBulck(null);
      setSelectedContainer(undefined);
      setCurrentTab(0);
    }
  },[open]);

  React.useEffect(()=>{
    if(selectedBL !==null && selectedBL.containers.length-selectedBL.assignedContainers.length===1){
      setSelectedContainer(selectedBL.containers[selectedBL.containers.findIndex((container)=>{return selectedBL.assignedContainers.findIndex((assignedContainerNumber)=>{return assignedContainerNumber===container.number})===-1})])
    }
    else{
    setSelectedContainer(undefined);
    }
  },[selectedBL])
    return (
      <Dialog
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "fit-content",
            minWidth: "10rem",
            minHeight: "10rem",
          },
        }}
        open={open}
        keepMounted
        aria-describedby="Add Container Details"
      >
        <DialogTitle>{`Add Container Details`}</DialogTitle>
        <DialogContent
          style={{
            height: "100%",
            minHeight: "30rem",
            width: "clamp(25rem, 70vw, 50rem)",
            padding: "1rem ",
          }}
        >
                  <Box sx={{ borderBottom: 1, borderColor: "divider",mb:1 }}>
          <Tabs
            value={currentTab}
            onChange={(_, newValue) => {
              if(window.confirm("Are you sure you want to change the tab? All unsaved data will be lost."))
              {
                containerForm.resetForm();
                setSelectedBL(null);
                setSelectedCRF(null);
                setSelectedContainer(undefined);
                setCurrentTab(newValue)
              }
            }}
            aria-label="container tabs"
          >
            <Tab label="By Container Details" {...a11yProps(0)} />
            <Tab label="By B/L" {...a11yProps(1)} disabled={disableBL}/>
            <Tab label="By CRF" {...a11yProps(2)} disabled={disableCRF}/>
            <Tab label="By Bulk Upload" {...a11yProps(3)} disabled={disableBulk}/>
          </Tabs>
        </Box>
        <CustomTabPanel value={currentTab} index={0}>
            <ByContainerDetailsTab containerForm={containerForm} containerTypeOptions={containerTypeOptions} open={open}/>
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={1}>
          <ByBLTab selectedBL={selectedBL} selectedContainer={selectedContainer} setSelectedBL={setSelectedBL} setSelectedContainer={setSelectedContainer} />
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={2}>
          <ByCRFTab selectedCRF={selectedCRF} setSelectedCRF={setSelectedCRF} selectedContainer={selectedContainer} setSelectedContainer={setSelectedContainer}/>
        </CustomTabPanel>
        <CustomTabPanel value={currentTab} index={3}>
          <BulkUploadTab selectedBulk={selectedBulk} setSelectedBulk={setSelectedBulck} selectedContainer={selectedContainer} setSelectedContainer={setSelectedContainer}/>
        </CustomTabPanel>
        </DialogContent>
        <DialogActions>
        <Button
            variant="outlined"
            onClick={() => {
              setOpen(false);
            }}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              containerForm.submitForm();
            }}
          >
            save
          </Button>
  
        </DialogActions>
      </Dialog>
    );
  }

function ByContainerDetailsTab({containerForm,containerTypeOptions,open}:{containerForm:FormikProps<Container>,containerTypeOptions:string[],open:boolean}){
  return(
    <ContainerSection containerForm={containerForm} containerTypeOptions={containerTypeOptions} />
  )
}
function ByBLTab({selectedBL,setSelectedBL,selectedContainer,setSelectedContainer}:{selectedBL:BL | null,setSelectedBL:React.Dispatch<React.SetStateAction<BL | null>>,selectedContainer:Container | undefined,setSelectedContainer:React.Dispatch<React.SetStateAction<Container | undefined>>}){
  const [BLs,setBLs]=React.useState<BL[]>([]);
  const [loadingBLs,setLoadingBLs]=React.useState<boolean>(true);
  const [addNewBLDialog,setAddNewBLDialog]=React.useState<boolean>(false);
  const authContext=useAuth();

  React.useEffect(()=>{
    const q=query(collection(firestore,"BLs"),where("fullfilled","==",false),where("shipper","==",authContext.currentUser?.uid));
    const unsubscribe=onSnapshot(q,(snapshot)=>{
      setBLs(snapshot.docs.map((doc)=>{return {docID:doc.id,...doc.data()} as BL}));
      setLoadingBLs(false);
    })
    return unsubscribe;
  },[]);
  return (
    <>
      <Autocomplete
        disablePortal
        id="BL-box"
        loading={loadingBLs}
        options={BLs}
        renderOption={(props, option) => (
          <Box component="li" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} {...props}>
            <Typography fontWeight="bold">{option.number}</Typography>
            <Typography>{option.containers.length} x Containers</Typography>
          </Box>
        )}
        onChange={(_, value) => {
          setSelectedBL(value);
        }}
        value={selectedBL}
        renderInput={(params) => <TextField {...params} label="B/L Number" />}
        getOptionLabel={(option) => option.number}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            return option.number.toUpperCase().includes(params.inputValue.toUpperCase());
          });
          return filtered;
        }}
        noOptionsText={
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setAddNewBLDialog(true);
            }}
          >
            No B/L Found - add new B/L
          </Button>
        }
      />
      {selectedBL && (
        <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: "scroll", overflowX: "hidden" }}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "lightGrey" }}>
              <TableRow style={{ backgroundColor: "lightGrey" }}>
                <TableCell align="right">Select</TableCell>
                <TableCell>Container Number</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Commodity</TableCell>
                <TableCell align="right">Documents</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBL.containers.map((container, index) => {
                return (
                  <TableRow key={index} sx={{ backgroundColor: selectedContainer?.number === container.number ? "#6d81bf47" : selectedBL.assignedContainers.findIndex((containerNumber)=>{return container.number===containerNumber})!==-1?"grey":"white", "&:last-child td, &:last-child th": { border: 0 } }}>
                    <TableCell>
                      <Checkbox
                        checked={selectedContainer?.number === container.number}
                        onChange={() => {
                          if (selectedContainer?.number === container.number) {
                            setSelectedContainer(undefined);
                          } else {
                            setSelectedContainer(container);
                          }
                        }}
                        disabled={selectedContainer && (selectedBL.containers.length-selectedBL.assignedContainers.length) === 1}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {container.number}
                    </TableCell>
                    <TableCell align="right">{container.type}</TableCell>
                    <TableCell align="right">{container.commodity}</TableCell>
                    <TableCell align="center">
                      {container.documents.length !== 0 ? (
                        <Badge badgeContent={container.documents.length} color="primary">
                          <FolderIcon />
                        </Badge>
                      ) : (
                        <FolderOffIcon color="disabled" />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!selectedBL && (
        <Button
          variant="contained"
          onClick={() => {
            setAddNewBLDialog(true);
          }}
        >
          add new BL
        </Button>
      )}

      <NewBLDialog open={addNewBLDialog} setOpen={setAddNewBLDialog} setSelectedBL={setSelectedBL} />
    </>
  );
}


function NewBLDialog({open,setOpen,setSelectedBL}:{open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>,setSelectedBL:React.Dispatch<React.SetStateAction<BL | null>>}){
  const authContext=useAuth();
  const [containerDialogOpen,setContainerDialogOpen]=React.useState<boolean>(false);
  const [submitting,setSubmitting]=React.useState<boolean>(false);
  const newBLFormik=useFormik({
    initialValues:{
      BLNumber:"",
      containers:[]
    }as {
      BLNumber:string,
      containers:Container[]
    },
    validationSchema:Yup.object({
      BLNumber:Yup.string().matches(/^[a-zA-Z0-9]+$/,"Invalid Booking number").required("B/L Number is required"),
      containers:Yup.array().min(1,"Add at least one container")
    }),
    onSubmit:(values)=>{
      setSubmitting(true);
      const newValues={
        number:values.BLNumber.toUpperCase(),
        containers:values.containers.map((container)=>{return {...container,BLRef:values.BLNumber.toUpperCase()}}),
        fullfilled:false,
        assignedContainers:[],
        //TODO::check for current user
        shipper:authContext.currentUser?.uid,
        createdAt:serverTimestamp(),
        //TODO::work on document upload
        documents:[]
      };
      const docRef=doc(collection(firestore,"BLs"));
      setDoc(docRef,newValues).then(async ()=>{
        const newBLS=await getDoc(doc(collection(firestore,"BLs"),docRef.id))
        setSelectedBL({docID:docRef.id,...newBLS.data()} as BL);
        setOpen(false);
        newBLFormik.resetForm();
        setSubmitting(false);
      }).catch((error)=>{
        console.error(error);
        window.alert("An error occured while adding new B/L. Please try again later");
        setSubmitting(false);
      })

    }
  })
  return(
    <>

    <Dialog open={open}       PaperProps={{
        style: {
          maxWidth: "fit-content",
          minWidth: "50%",
          minHeight: "70%",
        },
      }}>
      <DialogTitle>Add New B/L </DialogTitle>
      <DialogContent style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%",padding:"1rem"}} >
        <TextField label="B/L Number" variant="outlined"
        value={newBLFormik.values.BLNumber}
        onChange={newBLFormik.handleChange("BLNumber")}
        onBlur={newBLFormik.handleBlur("BLNumber")}
        error={newBLFormik.touched.BLNumber && Boolean(newBLFormik.errors.BLNumber)}
        helperText={newBLFormik.touched.BLNumber && newBLFormik.errors.BLNumber}
        inputProps={{
          style: { textTransform: "uppercase" },
        }}
        />
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "lightGrey" }}>
          <TableRow>
            <TableCell>Container Number</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Commodity</TableCell>
            <TableCell align="right">Net Weight&nbsp;(kg)</TableCell>
            <TableCell align="right">Documents</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newBLFormik.values.containers.map((container, index) => {
                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }}}>
                      <TableCell component="th" scope="row">
                        {container.number}
                      </TableCell>
                      <TableCell align="right">{container.type}</TableCell>
                      <TableCell align="right">{container.commodity}</TableCell>
                      <TableCell align="right">{container.netWeight}</TableCell>
                      <TableCell align="center">
                        {container.documents.length !== 0 ? (
                          <Badge badgeContent={container.documents.length} color="primary">
                            <FolderIcon />
                          </Badge>
                        ) : (
                          <FolderOffIcon color="disabled" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color='error'
                          onClick={() => {
                            if(window.confirm("Are you sure you want to delete container number "+container.number+" ?")){
                            newBLFormik.setFieldValue("containers",newBLFormik.values.containers.filter((_,i)=>{return i!==index}));
                          }
                          }}
                          startIcon={<DeleteForeverIcon/>}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
    
        </TableBody>
      </Table>
    </TableContainer>
    {newBLFormik.touched.containers && newBLFormik.errors.containers && newBLFormik.values.containers.length===0 && <Typography color="error">Please add at least one container </Typography>}
        <Button variant='contained' onClick={()=>{
          setContainerDialogOpen(true);
        }}>add container</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={()=>{
          newBLFormik.resetForm();
          setOpen(false);
        }}
        disabled={submitting}
        >cancel</Button>
        <Button onClick={()=>{
          newBLFormik.handleSubmit();
        }}
        disabled={submitting}
        variant="contained"
        >save</Button>
      </DialogActions>
    </Dialog>

    <ContainerDialog containerTypeOptions={Object.keys(CONTAINER_TO_UNIT)} openContainerDialog={containerDialogOpen} setOpenContainerDialog={setContainerDialogOpen} onSubmit={(container:Container)=>{
      if(newBLFormik.values.containers.find((c)=>{return c.number===container.number})){
        window.alert("Container number "+container.number+" already exists in the list");
        return;
      }
      newBLFormik.setFieldValue("containers",[...newBLFormik.values.containers,container]);
    }}
    hasToBeMovementType="IMP"
    hasToBeFull excludeContainerNumbers={
      newBLFormik.values.containers.map((container)=>{return container.number})
    }/>
    </>
  )
}

function ByCRFTab({selectedCRF,setSelectedCRF,selectedContainer,setSelectedContainer}:{selectedCRF:CRF | null,setSelectedCRF:React.Dispatch<React.SetStateAction<CRF | null>>,selectedContainer:Container | undefined,setSelectedContainer:React.Dispatch<React.SetStateAction<Container | undefined>>}){
  const [CRFs,setCRFs]=React.useState<CRF[]>([]);
  const [loadingCRFs,setLoadingCRFs]=React.useState<boolean>(true);
  const [addNewCRFDialog,setAddNewCRFDialog]=React.useState<boolean>(false);
  const authContext=useAuth();
  React.useEffect(()=>{
    const q=query(collection(firestore,"CRFs"),where("fullfilled","==",false),where("shipper","==",authContext.currentUser?.uid));
    const unsubscribe=onSnapshot(q,(snapshot)=>{
      setCRFs(snapshot.docs.map((doc)=>{return {docID:doc.id,...doc.data()} as CRF}));
      setLoadingCRFs(false);
    })
    return unsubscribe;
  },[]);
  return (
    <>
      <Autocomplete
        disablePortal
        id="CRF-box"
        options={CRFs}
        renderOption={(props, option) => (
          <Box component="li" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} {...props}>
            <Typography fontWeight="bold">{option.bookingNumber}</Typography>
            <Typography>{option.containers.length} x Containers</Typography>
          </Box>
        )}
                noOptionsText={
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setAddNewCRFDialog(true);
            }}
          >
            No CRF Found - add new CRF
          </Button>
        }
        onChange={(_,value)=>{
          setSelectedCRF(value);
          if(value !==null && value.containers.length===1){
            setSelectedContainer(value.containers[0])
          }
          else{
          setSelectedContainer(undefined);
        }
        
        }}
        value={selectedCRF}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Booking Number"
          />
        )}
        getOptionLabel={(option) => option.bookingNumber}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            return option.bookingNumber.toUpperCase().includes(params.inputValue.toUpperCase());
          });
          return filtered;
        }}
      />
   { selectedCRF &&   <TableContainer component={Paper} sx={{maxHeight:300,overflowY:"scroll",overflowX:"hidden"}}>
      <Table sx={{ minWidth: 750 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "lightGrey" }}>
          <TableRow style={{ backgroundColor: "lightGrey" }}>
            <TableCell>Container Number</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Commodity</TableCell>
            <TableCell align="right">Net Weight&nbsp;(kg)</TableCell>
            <TableCell align="right">Documents</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedCRF.containers.map((container, index) => {
                  return (
                    <TableRow key={index} sx={{backgroundColor:selectedContainer?.number===container.number?"#6d81bf47":"white", "&:last-child td, &:last-child th": { border: 0 }}}>
                      <TableCell component="th" scope="row">
                        TBD
                      </TableCell>
                      <TableCell align="right">{container.type}</TableCell>
                      <TableCell align="right">EMPTY</TableCell>
                      <TableCell align="right">EMPTY</TableCell>
                      <TableCell align="center">
                        {container.documents.length !== 0 ? (
                          <Badge badgeContent={container.documents.length} color="primary">
                            <FolderIcon />
                          </Badge>
                        ) : (
                          <FolderOffIcon color="disabled" />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })
            }
    
        </TableBody>
      </Table>
    </TableContainer>}
{!selectedCRF &&      <Button
      variant='contained'
        onClick={() => {
          setAddNewCRFDialog(true);
        }}
      >
        add new CRF
      </Button>}

      <NewCRFDialog open={addNewCRFDialog} setOpen={setAddNewCRFDialog} setSelectedCRF={setSelectedCRF} />
    </>
  );
}

function NewCRFDialog({open,setOpen,setSelectedCRF}:{open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>,setSelectedCRF:React.Dispatch<React.SetStateAction<CRF | null>>}){
  const authContext=useAuth();
  const [containerDialogOpen,setContainerDialogOpen]=React.useState<boolean>(false);
  const [submitting,setSubmitting]=React.useState<boolean>(false);
  const newCRFFormik=useFormik({
    initialValues:{
      bookingNumber:"",
      containers:[]
    }as {
      bookingNumber:string,
      containers:Container[]
    },
    validationSchema:Yup.object({
      bookingNumber:Yup.string().matches(/^[a-zA-Z0-9]+$/,"Invalid Booking number").required("Booking Number is required").max(30,"Invalid Booking Number"),
      containers:Yup.array().min(1,"Add at least one container")
    }),
    onSubmit:(values)=>{
      setSubmitting(true);
      const newValues={
        bookingNumber:values.bookingNumber.toUpperCase(),
        containers:values.containers.map((container)=>{
          return {
            ...container,
            CRFRef:values.bookingNumber.toUpperCase(),
            containerMovementType:"EXP",
            bookingNumber:values.bookingNumber.toUpperCase()
          }
        }),
        fullfilled:false,
        //TODO:: check for currentUser
        shipper:authContext.currentUser?.uid,
        createdAt:serverTimestamp(),
        //TODO::work on document upload
        documents:[]
      }
      const docRef=doc(collection(firestore,"CRFs"));
      setDoc(docRef,newValues).then(async ()=>{
        const newCRFs=await getDoc(doc(collection(firestore,"CRFs"),docRef.id))
        setSelectedCRF({docID:docRef.id,...newCRFs.data()} as CRF)
        setOpen(false);
        newCRFFormik.resetForm();
        setSubmitting(false);
      }).catch((error)=>{
        console.error(error);
        window.alert("An error occured while adding new CRF. Please try again later");
        setSubmitting(false);
      })

    }
  })
  return(
    <>

    <Dialog open={open}    PaperProps={{
        style: {
          maxWidth: "fit-content",
          minWidth: "50%",
          minHeight: "70%",
        },
      }}>
      <DialogTitle>Add New CRF</DialogTitle>
      <DialogContent style={{display:"flex",flexDirection:"column",gap:"0.5rem",width:"100%",padding:"1rem"}} >
        <TextField label="Booking Number" variant="outlined"
        value={newCRFFormik.values.bookingNumber}
        onChange={newCRFFormik.handleChange("bookingNumber")}
        onBlur={newCRFFormik.handleBlur("bookingNumber")}
        error={newCRFFormik.touched.bookingNumber && Boolean(newCRFFormik.errors.bookingNumber)}
        helperText={newCRFFormik.touched.bookingNumber && newCRFFormik.errors.bookingNumber}
        inputProps={{
          style: { textTransform: "uppercase" },
        }}
        />
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 750 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "lightGrey" }}>
          <TableRow>
            <TableCell>Container Number</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Commodity</TableCell>
            <TableCell align="right">Net Weight&nbsp;(kg)</TableCell>
            <TableCell align="right">Documents</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {newCRFFormik.values.containers.map((container, index) => {
                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }}}>
                      <TableCell component="th" scope="row">
                        TBD
                      </TableCell>
                      <TableCell align="right">{container.type}</TableCell>
                      <TableCell align="right">{!container.empty ? container.commodity : "EMPTY"}</TableCell>
                      <TableCell align="right">{!container.empty ? container.netWeight : "EMPTY"}</TableCell>
                      <TableCell align="center">
                        {container.documents.length !== 0 ? (
                          <Badge badgeContent={container.documents.length} color="primary">
                            <FolderIcon />
                          </Badge>
                        ) : (
                          <FolderOffIcon color="disabled" />
                        )}
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          color='error'
                          onClick={() => {
                            if(window.confirm("Are you sure you want to delete container number "+container.number+" ?")){
                              newCRFFormik.setFieldValue("containers",newCRFFormik.values.containers.filter((_,i)=>{return i!==index}));
                          }
                          }}
                          startIcon={<DeleteForeverIcon/>}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
    
        </TableBody>
      </Table>
    </TableContainer>
    {newCRFFormik.touched.containers && newCRFFormik.errors.containers && newCRFFormik.values.containers.length===0 && <Typography color="error">Please add at least one container </Typography>}
        {newCRFFormik.values.containers.length===0 && <Button variant='contained' onClick={()=>{
          setContainerDialogOpen(true);
        }}>add containers</Button>}
      </DialogContent>
      <DialogActions>
        <Button 
        disabled={submitting}
        onClick={()=>{
          newCRFFormik.resetForm();
          setOpen(false);
        }}>cancel</Button>
        <Button onClick={()=>{
          newCRFFormik.handleSubmit();
        }}
        disabled={submitting}
        >save</Button>
      </DialogActions>
    </Dialog>

        <CRFContainerDialog open={containerDialogOpen} setOpen={setContainerDialogOpen} onSubmit={(containers:Container[])=>{
          newCRFFormik.setFieldValue("containers",containers)
        }}/>
    </>
  )
}
function CRFContainerDialog({open,setOpen,onSubmit}:{open:boolean,setOpen:React.Dispatch<React.SetStateAction<boolean>>,onSubmit:(containers:Container[])=>void}){
  const formik=useFormik(
    {
      initialValues:{
        containers:[]
      } as{
        containers:Container[]
      },
      validationSchema:Yup.object({
          containers:Yup.array().min(1,"Add at least one container")
        })
      
      ,onSubmit:(values)=>{
        onSubmit(values.containers)
        formik.resetForm();
        setOpen(false);
      }
    }
  )
  console.log(formik.values.containers)
  return(
    <Dialog open={open}>
      <DialogTitle>
      </DialogTitle>
      <DialogContent>
          <div style={{display:"grid",gridTemplateColumns:"1fr 1fr 1fr",gridAutoRows:"1fr",gap:"0.5rem",justifyContent:"center",alignItems:"center"}}>
            {Object.keys(CONTAINER_TO_UNIT).map((containerType,index)=>{
              return(
              <>
                <TextField type="number" key={`${index}_input`}
                InputProps={{
                  inputProps:{
                    min:0,
                    max:99
                  }
                }}
                value={formik.values.containers.filter((container,index)=>{
                  return container.type===containerType
                }).length}
                onChange={(e)=>{
                  const preparedContainers:Container[]=formik.values.containers.filter((container)=>{
                    return container.type!==containerType})
                    console.log("after filter", preparedContainers)
                    for(let i =0;i<parseInt(e.target.value);i++){
                      preparedContainers.push({
                        ...INITIAL_CONTAINER,
                        type:containerType,
                        empty:true,
                      })
                    }
                  console.log("after push", preparedContainers)
                  formik.setFieldValue("containers",preparedContainers)
                }}
                ></TextField>
                <Typography style={{width:"100%",textAlign:"center"}} key={`${index}_X`}>x</Typography>
                <Typography key={`${index}_${containerType}`}>{containerType}</Typography>
              </>
              )
            })}
          </div>
        </DialogContent>
        <DialogActions>
        <Button onClick={()=>{
          formik.resetForm();
          setOpen(false);
        }}>cancel</Button>
        <Button onClick={()=>{
          formik.handleSubmit();

        }}>save</Button>
      </DialogActions>
    </Dialog>
  )
}
function BulkUploadTab({
  selectedBulk,
  setSelectedBulk,
  selectedContainer,
  setSelectedContainer,
}: {
  selectedBulk: BulkUpload | null;
  setSelectedBulk: React.Dispatch<React.SetStateAction<BulkUpload | null>>;
  selectedContainer: Container | undefined;
  setSelectedContainer: React.Dispatch<React.SetStateAction<Container | undefined>>;
}) {
  const [Bulks, setBulks] = React.useState<BulkUpload[]>([]);
  const [loadingBulks, setLoadingBulks] = React.useState<boolean>(true);
  const authContext = useAuth();
  const [autoCompleteInputValue, setAutoCompleteInputValue] = React.useState<string>("");
  React.useEffect(() => {
    const q = query(collection(firestore, "Bulks"), where("fullfilled", "==", false), where("shipper", "==", authContext.currentUser?.uid));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setBulks(
        snapshot.docs.map((doc) => {
          return { docID: doc.id, ...doc.data() } as BulkUpload;
        })
      );
      setLoadingBulks(false);
    });
    return unsubscribe;
  }, []);
  return (
    <>
      <Autocomplete
        disablePortal
        id="Bulks-box"
        options={Bulks}
        renderOption={(props, option) => (
          <Box component="li" style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }} {...props}>
            <Typography component="div" fontWeight="bold" style={{display:"flex",alignItems:"center",gap:"0.2rem"}}> {autoCompleteInputValue!=="" && option.containers.findIndex((container) => {
              return container.number.toUpperCase().startsWith(autoCompleteInputValue.toUpperCase());
            }) !== -1? <> {option.containers[option.containers.findIndex((container) => {
              return container.number.toUpperCase().startsWith(autoCompleteInputValue.toUpperCase());
            })].number} - <Typography style={{color:"gray"}}>Bulk ID: {option.docID}</Typography></>:`Bulk ID: ${option.docID}`}</Typography>
            <Typography>{option.containers.length} x Containers , added on {dayjs(option.createdAt.toDate()).format("D-MMM-YYYY hh:mm A")}</Typography>
          </Box>
        )}
        noOptionsText={
            "No Bulk uploads found..."
        }
        onChange={(_, value) => {
          setSelectedBulk(value);
          if (value !== null && value.containers.length === 1) {
            setSelectedContainer(value.containers[0]);
          } else {
            setSelectedContainer(undefined);
          }
        }}
        value={selectedBulk}
        inputValue={autoCompleteInputValue}
        onInputChange={(_, value) => {
          setAutoCompleteInputValue(value);
        }}
        renderInput={(params) => <TextField {...params} label="Bulk ID or Container Number" />}
        getOptionLabel={(option) => option.docID}
        filterOptions={(options, params) => {
          const filtered = options.filter((option) => {
            return option.docID.toUpperCase().includes(params.inputValue.toUpperCase()) || option.containers.findIndex((container) => {
              return container.number.toUpperCase().startsWith(params.inputValue.toUpperCase());
            }) !== -1;
          });
          return filtered;
        }}
      />
      {selectedBulk && (
        <TableContainer component={Paper} sx={{ maxHeight: 300, overflowY: "scroll", overflowX: "hidden" }}>
          <Table sx={{ minWidth: 750 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "lightGrey" }}>
              <TableRow style={{ backgroundColor: "lightGrey" }}>
                <TableCell align="left">Select</TableCell>
                <TableCell>Container Number</TableCell>
                <TableCell align="right">Type</TableCell>
                <TableCell align="right">Commodity</TableCell>
                <TableCell align="right">Documents</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedBulk.containers.map((container, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        selectedContainer?.number === container.number
                          ? "#6d81bf47"
                          : selectedBulk.assignedContainers.findIndex((containerNumber) => {
                              return container.number === containerNumber;
                            }) !== -1
                          ? "grey"
                          : "white",
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedContainer?.number === container.number}
                        onChange={() => {
                          if (selectedContainer?.number === container.number) {
                            setSelectedContainer(undefined);
                          } else {
                            setSelectedContainer(container);
                          }
                        }}
                        disabled={selectedContainer && selectedBulk.containers.length - selectedBulk.assignedContainers.length === 1}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {container.number}
                    </TableCell>
                    <TableCell align="right">{container.type}</TableCell>
                    <TableCell align="right">{container.commodity}</TableCell>
                    <TableCell align="center">
                      {container.documents.length !== 0 ? (
                        <Badge badgeContent={container.documents.length} color="primary">
                          <FolderIcon />
                        </Badge>
                      ) : (
                        <FolderOffIcon color="disabled" />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{display:"flex",gap:"0.5rem",flexDirection:"column"}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const FileUploadButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  align-items: center;
`;