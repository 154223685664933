import CircularProgress from '@mui/material/CircularProgress';
export default function Loading({
    fullscreen,
    opacity,

} :{fullscreen?:boolean,
    opacity?:number}) {
    return(
        <div style={fullscreen? {width:"100vw",height:"100vh",zIndex:"3000",opacity:opacity? opacity:1,display:"flex",justifyContent:"center",alignItems:"center"}: {width:"100%",height:"100%",opacity:opacity? opacity:1,display:"flex",justifyContent:"center",alignItems:"center"}}>
            <CircularProgress/>
        </div>
    );

}