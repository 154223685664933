import { Typography } from "@mui/material";
import styled from "styled-components";
import JoinForm from "./JoinForm";
const MainContainer=styled.div`
display:grid;
width:100%;
grid-template-rows: 1fr 2fr 1fr;
padding:10%;
padding-bottom:0;
padding-top:10%;
box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.75);
background-color:white;
z-index:1;
`;
const Header=styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
width:100%;`;
export default function Sidepanel() {

    return(
        <MainContainer>
            <Header>
                <Typography variant="h3" color="primary" >Join us!</Typography>
                <Typography variant="body2" color="grey" >Gain Access to Bahrain & GCC’s largest road fright logistics network !</Typography>
            </Header>
            <JoinForm/>
        </MainContainer>    
    );
}

