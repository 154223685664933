import React from 'react';
import {ACTIONS,Container,Facility,INITIAL_CONTAINER,INITIAL_MOVEMENT,INITIAL_STATE,Movement,NewShipmentReducer, ShipmentForm as ShipmentFormType, Truck} from "../Logic/NewShipmentLogic";
import styled from 'styled-components';
import {HeadingStyle,SectionHeadingStyle,SubHeadingStyle, SubTextStyle,} from "../Styles/NewShipmentStyles";
import { Button, CircularProgress, MobileStepper, Pagination, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import { mapOptions } from './ShipmentForm';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { useAuth } from '../../../Contexts/AuthContext';
import { createShipment } from '../../../Firebase/Firestore/Shipments';
import { useNavigate } from 'react-router-dom';

export default function ShipmentFinalizationPage({globalFormState,globalDispatch,isLoaded,center,setStep}:{globalFormState:ShipmentFormType,globalDispatch:Function,isLoaded:boolean,center:{lat:number,lng:number},setStep:Function}){
  const [selectedTruck, setSelectedTruck] = React.useState<number>(0);
  const [submitting,setSubmitting]=React.useState<boolean>(false);
  const {currentUser}=useAuth();
  const navigate=useNavigate();
  return (
    <LocalMainContainer>
      <Typography style={HeadingStyle} color="primary">
        Finalize Shipment
      </Typography>

      <CenteringContainer>
        <PaperContainer>
          <DetailsContainer style={{marginBottom:"1rem",borderBottom:"3px solid black",width:"100%",paddingBottom:"1rem",gap:"1.5rem"}}>
            <DetailsSegment>
              <Typography style={SectionHeadingStyle}>
                Shipment Date & Time
              </Typography>
              <Typography style={SubTextStyle}>
                {globalFormState.values.dateTime
                  .toDate()
                  .toLocaleString("en-GB", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}
              </Typography>
            </DetailsSegment>
            <DetailsSegment>
              <Typography style={SectionHeadingStyle}>Total Number Trucks</Typography>
              <Typography style={SubTextStyle}>
                {globalFormState.values.trucks.length}
              </Typography>
            </DetailsSegment>
          </DetailsContainer>
          <DetailsContainer style={{width:"100%"}}>
          {isLoaded ? (
            <GoogleMap
              center={center}
              mapContainerStyle={{
                width: "50%",
                height: "30rem",
                borderRadius: "10px 10px 0 0",
              }}
              options={mapOptions}
            >
              {selectedTruck !== undefined &&
              globalFormState.values.trucks[selectedTruck].movements.length !==
                0
                ? globalFormState.values.trucks[selectedTruck].movements.map(
                    (movement, index) => {
                      return (
                        <MarkerF
                          zIndex={index}
                          key={index}
                          position={{
                            lat: movement.facility.coordinates.lat,
                            lng: movement.facility.coordinates.lng,
                          }}
                          clickable={false}
                          icon={{
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 10,
                            fillColor: "#1c2a57",
                            fillOpacity: 1,
                            strokeWeight: 0.4,
                          }}
                          label={{
                            text: `${(index + 1).toString()}`,
                            color: "#fff",
                          }}
                        />
                      );
                    }
                  )
                : null}
            </GoogleMap>
          ) : (
            <div
              style={{
                width: "50%",
                height: "30rem",
                borderRadius: "10px 10px 0 0",
                backgroundColor: "lightGrey",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CircularProgress />
            </div>
          )}

          

            <DetailsColumn style={{width:"50%"}}>
            <div style={{display:"flex",flexDirection:"column", width:"50%"}}>
              <Typography style={SectionHeadingStyle}>Truck Type</Typography>
              <Typography style={SubTextStyle}>
                {globalFormState.values.trucks[selectedTruck].type}
              </Typography>
            </div>
              <Stepper activeStep={-1} orientation='vertical'  style={{flexWrap:"wrap",columnGap:"0.5rem",maxWidth:400}}>
                {globalFormState.values.trucks[selectedTruck].movements.map(
                  (movement: Movement, index) => (
                    <Step key={index} expanded active>
                          <StepLabel>
                            {movement.facility.name} {movement.facility.selectedSequanceModifier? <Typography color="primary" fontWeight="bold" fontSize="0.75rem"> {`(${movement.facility.selectedSequanceModifier})`}</Typography> :null}
                          </StepLabel>                      
                        <StepContent>
                        <div style={{ display: "flex", gap: "0.5rem" }}>
                          {movement.containers.dropoff.length !== 0 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                key={index}
                                variant="caption"
                                fontWeight="bold"
                              >
                                Drop-off
                              </Typography>
                              {movement.containers.dropoff.map(
                                (container, index) => {
                                  return (
                                    <Typography
                                      key={index}
                                      variant="caption"
                                      color="error"
                                    >
                                          {container.CRFRef?`Container W/ CRF ${container.CRFRef}`:container.BLRef?container.confirmedSelection?container.number:`Container W/ B/L ${container.BLRef}`:container.bulkRef?container.confirmedSelection?container.number:`Contianer W/ BULK ${container.bulkRef}`:container.number}
                                          </Typography>
                                  );
                                }
                              )}
                            </div>
                          )}
                          {movement.containers.pickup.length !== 0 && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                justifyContent: "flex-start",
                              }}
                            >
                              <Typography
                                key={index}
                                variant="caption"
                                fontWeight="bold"
                              >
                                Pickup
                              </Typography>
                              {movement.containers.pickup.map(
                                (container, index) => {
                                  return (
                                    <Typography
                                      key={index}
                                      variant="caption"
                                      color="green"
                                    >
                                          {container.CRFRef?`Container W/ CRF ${container.CRFRef}`:container.BLRef?container.confirmedSelection?container.number:`Container W/ B/L ${container.BLRef}`:container.bulkRef?container.confirmedSelection?container.number:`Contianer W/ BULK ${container.bulkRef}`:container.number}
                                          </Typography>
                                  );
                                }
                              )}
                            </div>
                          )}
                        </div>
                      </StepContent>
                    </Step>
                  )
                )}
              </Stepper>
            </DetailsColumn>
          </DetailsContainer>
          <MobileStepper
        style={{display:"flex",width:"100%",justifyContent:"center",alignItems:"center"}}
        variant="text"
        steps={globalFormState.values.trucks.length}
        position="static"
        activeStep={selectedTruck}
        nextButton={
          <Button
            size="large"
            onClick={()=>{setSelectedTruck((prevSelected)=>{return prevSelected+1})}}
            disabled={selectedTruck === globalFormState.values.trucks.length - 1}
            style={{paddingLeft:"1rem"}}
          >
            Next
              <KeyboardArrowRight />

          </Button>
        }
        backButton={
          <Button size="large" style={{paddingRight:"1rem"}}onClick={()=>{setSelectedTruck((prevSelected)=>{return prevSelected-1})}}
          disabled={selectedTruck === 0}>
            <KeyboardArrowLeft />

            Back
          </Button>
        }
      />
    <ActionsDiv>
          <Button
            onClick={() => {
              setStep(0);
            }}
            variant="outlined"
           
          >
            {" "}
            back
          </Button>
          <Button
            onClick={() => {
              if(currentUser!==null){
                console.log("booking ",globalFormState.values);
                setSubmitting(true);
                createShipment({user:currentUser,shipmentValues:globalFormState.values}).then((res)=>{

                    if(res.success){
                        globalDispatch({type:ACTIONS.RESET});
                        setStep(0);
                        navigate("/Dashboard");
                        
                    }
                    else {
                        alert(`Ops something went wrong: ${res.error}`);
                    }
                }).catch((err)=>{
                    alert(`Ops something went wrong: ${err}`);
                }).finally(()=>{setSubmitting(false)});
              }
              else{
                alert("ERR:USER_NOT_FOUND\nops something went wrong");
              }
            }}
            variant="contained"
            color='success'
            disabled={submitting}
          >
            
            {submitting?<CircularProgress/>:"Confirm"}
          </Button>
          </ActionsDiv>
        </PaperContainer>
      </CenteringContainer>
    </LocalMainContainer>
  );
} 

const LocalMainContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;
gap: 0.5rem;
`;
const CenteringContainer=styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
padding: 1rem;
`;
const PaperContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;
padding: 1rem;
gap: 1rem;
background-color: white;
border-radius: 10px;`;
const DetailsContainer=styled.div`
display: flex;
gap: 1rem;
`;
const DetailsColumn=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 1rem;
`;
const DetailsSegment=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`;
const ActionsDiv=styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 1rem;
width: 100%;
`;