import React from 'react';
import { Button, Typography,Drawer,TextField,InputAdornment, Badge, CircularProgress, MobileStepper, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import styled from "styled-components";
// import { FirebaseMovement, Movement, Shipment } from '../../../../Models/Shipments';
import { useAuth } from '../../../../Contexts/AuthContext';
import { and, collection, doc, getDoc, getDocs, onSnapshot, or, orderBy, query, where } from 'firebase/firestore';
import { firestore } from '../../../../Firebase/Firebase';
import { useNavigate,useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { styled as muiStyled } from '@mui/material/styles';

import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import { FirebaseMovement, Shipment } from '../../../../Firebase/Firestore/Shipments';
import { Movement } from '../../../POSTGITEXT-NewShipment/Logic/NewShipmentLogic';

import MyLocationIcon from '@mui/icons-material/MyLocation';
import HistoryIcon from '@mui/icons-material/History';
import { env } from 'process';
import Loading from '../../../Loading/Loading';
const SHIPMENT_TYPES:{[key:number]:string}={
    0:"HOURLY",

}
const mapOptions:google.maps.MapOptions ={
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    mapTypeId: "hybrid",
  };
export default function ShipmentsTile({style} : {style?:React.CSSProperties}) {
    const authContext=useAuth();
    const [shipments,setShipments]=React.useState<Shipment[]>([]);
    const {URLShipment}=useParams();
    const {currentUser}=useAuth();
    const {isLoaded}=useLoadScript({
        googleMapsApiKey:process.env.REACT_APP_GOOGLE_CLOUD_API_KEY as string,
    });
    const navigate=useNavigate();
    // const coordsToKM=({start,end}:{start:{lat:number,lng:number},end:{lat:number,lng:number}}):number=>{
    //     if(
    //         start===null || start===undefined || end===null || end===undefined
    //     ){
    //         return 0;
    //     }
    //     const R = 3958.8; // Radius of the Earth in miles
    //     const rlat1 = start.lat * (Math.PI/180); // Convert degrees to radians
    //     const rlat2 = end.lat * (Math.PI/180); // Convert degrees to radians
    //     const difflat = rlat2-rlat1; // Radian difference (latitudes)
    //     const difflon = (end.lng-start.lng) * (Math.PI/180); // Radian difference (longitudes)
  
    //     const d = 2 * R * Math.asin(Math.sqrt(Math.sin(difflat/2)*Math.sin(difflat/2)+Math.cos(rlat1)*Math.cos(rlat2)*Math.sin(difflon/2)*Math.sin(difflon/2)));
    //     return d;
    // }

    React.useEffect(() => {
      if(currentUser?.uid===undefined){
        return;
      }
      //TODO (DEFERRED) 24-Dec-23:: think if you want the query to include completed or not (probably not)
        const q=query(collection(firestore,"Shipments"),where("shipperID","==",currentUser?.uid),orderBy("status","asc"),orderBy("dateTime","desc"));
        const unsubscribe=onSnapshot(q,(querySnapshot)=>{
            const shipments:Shipment[]=[];
            querySnapshot.forEach((doc)=>{
                console.log(doc.data(),"doc.data()");
                console.log(doc.id,"doc.id");
                const shipment:Shipment={
                    ...doc.data() as Shipment,
                    id:doc.id,
                    dateTime:dayjs(doc.data().dateTime.toDate()),   
                }
                console.log("shipperID",shipment.shipperID,"userID",currentUser?.uid);
                shipments.push(shipment);
            })
            setShipments(shipments);
        });
        return unsubscribe;
    },[authContext.currentUser?.uid]);
    return(
        <MainContainer style={style}>
            <SegmentDiv>
                <Typography style={{fontWeight:700,fontSize:"2rem"}}>Dashboard</Typography>
            </SegmentDiv>
            <SegmentDiv>
            {shipments.map((shipment)=>{
                return(
                    <ShipmentCard key={shipment.id}
                     onClick={()=>{
                        navigate(`/Dashboard/${shipment.id}`)
                    }}>
                        <ShipmintStatusIndicator status={shipment.status} assignedTrucks={shipment.assignedTrucks}/>
                        <Typography fontWeight={700} fontSize="0.8rem" style={{width:"100%"}}>REF{shipment.id.toUpperCase()}</Typography>
                            <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                                <Typography fontWeight={700} fontSize="0.8rem">{shipment.trucks.length} Trucks</Typography>
                                <Typography fontWeight={500} fontSize="0.7rem" color="grey">{SHIPMENT_TYPES[shipment.type]}</Typography>
                            </div>
                            <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
                            <Typography fontWeight={700} fontSize="0.8rem">
                                {Object.keys(shipment.assignedTrucks).reduce((acc,curr)=>{
                                    return acc+(shipment.assignedTrucks[curr]===""?0:1);
                                },0)}
                                / {shipment.trucks.length}
                            </Typography>
                            <Typography fontWeight={500} fontSize="0.7rem" color="grey">Trucks Assigned</Typography>


                            </div>
                        <MuiltiLineDiv>
                            <Typography fontWeight={700} fontSize="0.8rem">{shipment.dateTime.format("D-MMM-YYYY")}</Typography>
                            <Typography fontWeight={700} fontSize="0.8rem"> {shipment.dateTime.format("HH:mm")} </Typography>
                            <Typography  fontWeight={500} fontSize="0.7rem" color="grey">{shipment.dateTime.format("dddd")}</Typography>
                        </MuiltiLineDiv>
                        <Typography  fontWeight={500} fontSize="0.7rem" color="grey" style={{position:"absolute",bottom:"1rem",right:"1rem"}}>Created on {dayjs(shipment.createdAt.toDate()).format("D-MMM-YYYY")}</Typography>
                    </ShipmentCard>
                )
            })}
            {shipments.length===0 && <div style={{width:"100%", display:"flex",flexDirection:"column",alignItems:"center",gap:"0.5rem"}}>  <Typography variant='h6' color="primary" fontWeight="bold">No Shipments found :(</Typography> <Button variant='contained' onClick={()=>{
              navigate("/NewShipment")
            }}>start a new shipment</Button> </div>}
            </SegmentDiv>
            <SidePanel URLShipment={URLShipment} shipments={shipments}/>
        </MainContainer>
    );
}

const SidePanel=({URLShipment,shipments}:{URLShipment:string | undefined,shipments:Shipment[]})=>{
    const [loading,setLoading]=React.useState<boolean>(true);
    const [selectedTruckIndex,setSelectedTruckIndex]=React.useState<number>(0);
    const [movements,setMovements]=React.useState<FirebaseMovement[]>([]);
    const [selectedShipment,setSelectedShipment]=React.useState<Shipment | undefined>(shipments.find((shipment)=>{
      return shipment.id===URLShipment;
  }));
  const [selectedTruckId,setSelectedTruckId]=React.useState<string>(selectedShipment?.trucks[selectedTruckIndex].trackingId || "");
  console.log("selectedTruckId",selectedTruckId);

  React.useEffect(()=>{
    if(selectedShipment){
      setSelectedTruckId(Object.keys(selectedShipment.assignedTrucks).at(selectedTruckIndex) || "");
    }
  },[selectedTruckIndex]);  
    React.useEffect(()=>{
        //TODO (DEFERRED) 24-Dec-23:: stop loading all movement documnets and instead do the filtering on the query side
        if(URLShipment && URLShipment!==""){
          const q=query(collection(firestore,"Shipments",URLShipment,"Movements"));
          getDocs(q).then((querySnapshot)=>{
            const movements:FirebaseMovement[]=[];
            querySnapshot.forEach((doc)=>{
              movements.push(doc.data() as FirebaseMovement);
            })
            setMovements(movements);
            console.warn(movements);

            if(shipments.length===0){
              //was accessed directly from url
              getDoc(doc(collection(firestore,"Shipments"),URLShipment)).then((doc)=>{
                if(doc.exists()){
                  const shipment:Shipment={
                    ...doc.data() as Shipment,
                    id:doc.id,
                    dateTime:dayjs(doc.data().dateTime.toDate()),   
                }
                setSelectedShipment(shipment);
                setSelectedTruckId(shipment?.trucks[0].trackingId || "");
              }
              else{
                window.alert("Shipment not found");
                navigate("/dashboard");
              }
              }).catch((e)=>{
                window.alert("Error loading shipment details "+e.message);
                navigate("/dashboard");
              })
            }else{
              const shipment:Shipment | undefined=shipments.find((shipment)=>{
                return shipment.id===URLShipment;
              });
              setSelectedShipment(shipment);
            setSelectedTruckId(shipment?.trucks[0].trackingId || "");
          }
          }).catch(()=>{
            alert("Error loading shipment details");
          })
          .finally(()=>{
            setSelectedTruckIndex(0);
          
            
            setLoading(false);
          })

        }
        else{
            setSelectedShipment(undefined);
            setMovements([]);
            setLoading(true);
        }
      },[URLShipment]);    
    const center=React.useMemo<{lat:number,lng:number}>(()=>({
        lat: 26.087545,
        lng: 50.549084
    }),[]);

    const navigate=useNavigate();
    const google=window.google;
    return (
      <Drawer
        anchor="right"
        open={URLShipment ? true : false}
        onClose={() => {
          
            navigate("/dashboard");
          
        }}
        style={{ zIndex: 10000 }}
      >
        <DraweContent>
            <div style={{display:"flex",justifyContent:"space-between",width:"100%"}}>
          <Typography
            fontWeight={700}
            fontSize="1.5rem"
            style={{ borderBottom: "2px solid #818181", paddingLeft: "1rem" }}
          >
            REF{selectedShipment?.id.toUpperCase()}
          </Typography>
          { selectedShipment?.status &&<>
          {selectedShipment.status===2 && <Button startIcon={<MyLocationIcon/>} onClick={()=>{
            //open new tab
            window.open(`${process.env.REACT_APP_URL}/Tracking/${selectedShipment?.id}`)
          }} variant='contained' style={{marginRight:"0.5rem"}}>Track</Button>}

          {selectedShipment.status>=3 && <Button startIcon={<HistoryIcon/>} onClick={()=>{
            //open new tab
            window.open(`${process.env.REACT_APP_URL}/History/${selectedShipment?.id}`)
          }} variant='contained' style={{marginRight:"0.5rem"}}>view shipment log</Button>}
          </>
        }
          
          </div>

          {selectedShipment !== undefined ? (
            <>
              {google?.maps && ( //needed to check if google maps is loaded in
                <GoogleMap
                  mapContainerStyle={{ width: "100%", height: "30vh" }}
                  center={center}
                  zoom={9}
                  options={mapOptions}
                >
                  {
                    movements.filter((movement)=>{return movement.truckTrackingId===selectedTruckId}).sort((a,b)=>{return a.index-b.index}).map((movement,index)=>{
                        return(
                            <MarkerF
                            key={index}
                            position={{
                            lat: movement.facility.coordinates.lat,
                            lng: movement.facility.coordinates.lng,
                            }}
                            icon={{
                                path: google.maps.SymbolPath.CIRCLE,
                                scale: 10,
                                fillColor: "#1c2a57",
                                fillOpacity: 1,
                                strokeWeight: 0.4,
                              }}
                              label={{
                                text: `${(index + 1).toString()}`,
                                color: "#fff",
                              }}
                        />
                        )
                    })
                  }
                </GoogleMap>
              )}
              
              <MobileStepper
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  padding:"0"
                }}
                variant="text"
                steps={selectedShipment.trucks.length}
                position="static"
                activeStep={selectedTruckIndex}
                nextButton={
                  <Button
                    size="small"
                    onClick={() => {
                      setSelectedTruckIndex((prevSelected) => {
                        return prevSelected + 1;
                      });
                    }}
                    disabled={
                      selectedTruckIndex === selectedShipment.trucks.length - 1
                    }
                    style={{ paddingLeft: "1rem" }}
                  >
                    Next
                    <KeyboardArrowRight />
                  </Button>
                }
                backButton={
                  <Button
                    size="small"
                    style={{ paddingRight: "1rem" }}
                    onClick={() => {
                      setSelectedTruckIndex((prevSelected) => {
                        return prevSelected - 1;
                      });
                    }}
                    disabled={selectedTruckIndex === 0}
                  >
                    <KeyboardArrowLeft />
                    Back
                  </Button>
                }
              />
              {
                !loading?
              <ShipmentDetailsContainer>
                <DetailsColumn style={{ width: "50%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <Typography style={SectionHeadingStyle}>
                      Truck Type
                    </Typography>
                    <Typography style={SubTextStyle}>
                      {selectedShipment.trucks[selectedTruckIndex].type}
                    </Typography>
                  </div>
                  <Stepper
                    activeStep={-1}
                    orientation="vertical"
                    style={{ minWidth: "38vw" ,maxHeight:"15rem",overflowY:"scroll"}}
                  >
                    {movements.filter((movement)=>{return movement.truckTrackingId===selectedTruckId}).sort((a,b)=>{return a.index-b.index}).map(
                      (movement: Movement, index) => (
                        <Step key={index} expanded active>
                          <StepLabel>{movement.facility.name}</StepLabel>
                          <StepContent>
                            <div style={{ display: "flex", gap: "0.5rem" }}>
                              {movement.containers.dropoff.length !== 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <Typography
                                    key={index}
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Drop-off
                                  </Typography>
                                  {movement.containers.dropoff.map(
                                    (container, index) => {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="caption"
                                          color="error"
                                        >
                                          {container.CRFRef?`Container W/ CRF ${container.CRFRef}`:container.BLRef?container.confirmedSelection?container.number:`Container W/ B/L ${container.BLRef}`:container.bulkRef?container.confirmedSelection?container.number:`Contianer W/ BULK ${container.bulkRef}`:container.number}
                                        </Typography>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                              {movement.containers.pickup.length !== 0 && (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    justifyContent: "flex-start",
                                  }}
                                >
                                  <Typography
                                    key={index}
                                    variant="caption"
                                    fontWeight="bold"
                                  >
                                    Pickup
                                  </Typography>
                                  {movement.containers.pickup.map(
                                    (container, index) => {
                                      return (
                                        <Typography
                                          key={index}
                                          variant="caption"
                                          color="green"
                                        >
                                          {container.CRFRef?`Container W/ CRF ${container.CRFRef}`:container.BLRef?container.confirmedSelection?container.number:`Container W/ B/L ${container.BLRef}`:container.bulkRef?container.confirmedSelection?container.number:`Contianer W/ BULK ${container.bulkRef}`:container.number}

                                        </Typography>
                                      );
                                    }
                                  )}
                                </div>
                              )}
                            </div>
                          </StepContent>
                        </Step>
                      )
                    )}
                  </Stepper>
                </DetailsColumn>
              </ShipmentDetailsContainer>
:<Loading />
}
            </>
          ) : null}
        </DraweContent>
      </Drawer>
    );
}
const STATUS_FLAGS:{
    [key:number]:string
}={
    0:"Registered",
    1:"Confirmed",
    2:"In Progress",
    3:"Fulfilled",
    4:"Completed",
    404:"Cancelled",
    405:"Failed to Confirm"
}
const STATUS_COLORS:{
    [key:number]:string
}={
    0:"orange",
    1:"lightgreen",
    2:"aqua",
    3:"green",
    4:"darkgreen",
    404:"red",
    405:"red"
}
const ShipmintStatusIndicator=({status,assignedTrucks}:{status:number,assignedTrucks:{[trackingDocId:string]:string}})=>{
    return(
        <StatusDiv>
            <Typography style={{fontWeight:700,fontSize:"1rem"}}>{STATUS_FLAGS[status]}</Typography>
            <div style={{display:"flex",gap:"0.1rem"}}>
            {Array(5).fill(0).map((_,index)=>{
                return(
                    <div key={index} style={{display:"flex",flexDirection:"row",gap:"0.5rem",alignItems:"center"}}>
                        <div style={{width:"1rem",height:"0.5rem",backgroundColor:index<=status?STATUS_COLORS[status]:"grey",borderRadius:"0.2rem"}}/>
                    </div>
                )
            })}
            </div>
        </StatusDiv>
    )
}
const MainContainer=styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    `;
const SegmentDiv=styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: 1fr;
    gap: 1rem;
    padding: 1rem;
    box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px 10px 0px 0px;
    background-color:white;
    `;
const ShipmentCard=styled.div`
    position: relative;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr 1.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 0.6fr;
    grid-template-rows: 1fr;
    gap: 2rem;
    border-radius: 10px;
    border: 1px solid #CAC4D0;
    padding: 1rem;
    width:100%;
    height:100%;
    align-items: center;
    cursor: pointer;
    &:hover{
        box-shadow: 5px 5px 10px 5px rgba(0, 0, 0, 0.25);
        background-color: #f0f0f0;
    }
`;
const StatusDiv=styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start;`
;
const MuiltiLineDiv=styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.1rem;

    `;
const DraweContent=styled.div`
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap:1rem;
    padding-top:1rem;
`;
const ShipmentDetailsContainer=styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
    width: 100%;
    padding: 0 1rem 1rem 1rem;
`;
const Section=styled.div`
    display:flex;
    flex-direction:column;
    gap:1rem;
    width:100%;
    `;
const SectionHeader=styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    border-bottom:3px solid #CAC4D0;
    width:fit-content;
    `;
const SectionGrid=styled.div`
    display:grid;
    `;
const AddressGrid=styled(SectionGrid)`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 1rem;

    `;
const DetailsGrid=styled(SectionGrid)`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    gap: 1rem;`;
    
const CargoGrid=styled(SectionGrid)`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2,1fr);`

const TruckGrid=styled(SectionGrid)`
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;`;

    export const DrawerHeader = muiStyled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        ...theme.mixins.toolbar,
      }));
    
    export const HeadingStyle={
        fontWeight: 700,
        fontSize: "2rem",
        letterSpacing: "0.1px"
    }
    export const SubHeadingStyle={
        fontWeight: 700,
        fontSize: "1.5rem",
        letterSpacing: "0.1px"
    }
    export const SectionHeadingStyle={
        fontWeight: 700,
        fontSize: "1.2rem",
        color:"rgb(63, 61, 86)",
    }
    
    export const SubTextStyle={
        fontWeight: 500,
        fontSize: "1rem",
        color:"grey",
        letterSpacing: "0.1px"
    }
    const LocalMainContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;
gap: 0.5rem;
`;
const CenteringContainer=styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100%;
padding: 1rem;
`;
const PaperContainer=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
height: 100%;
padding: 1rem;
gap: 1rem;
background-color: white;
border-radius: 10px;`;
const DetailsContainer=styled.div`
display: flex;
gap: 1rem;
`;
const DetailsColumn=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
gap: 1rem;
`;
const DetailsSegment=styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;
`;
const ActionsDiv=styled.div`
display: flex;
flex-direction: row;
justify-content: flex-end;
align-items: center;
gap: 1rem;
width: 100%;
`;