import React from 'react';
import { Button,  InputAdornment, TextField,IconButton } from '@mui/material';
import styled from 'styled-components';
import {string,object,} from 'yup';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AuthContextValue, AuthFunctionReturn, useAuth } from '../../../Contexts/AuthContext';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';


//----------------

//----------------CONSTANTS----------------
const ACTIONS={
    UPDATE_EMAIL:"update_email",
    UPDATE_PASSWORD:"update_password",
}
//---------------- Types ----------------
type SigninFormState={
    values:{
    email:string;
    password:string;
}
};
type SingInFormAction={
    type:string;
    payload:string;
};
type SignInError={
    show:boolean;
    error?:{
        message:string;
    }
}
//---------------- Validation ----------------
const emailValidation=string().email();
const passwordValidation=string().min(8).max(30);
const SigninFormValidationSchema=object({
    email:emailValidation.required(),
    password:passwordValidation.required()
});
//---------------- State Management ----------------
const initialState:SigninFormState={
    values:{
        email:"",
        password:""
    },
}
const reducer=(state:SigninFormState,action:SingInFormAction):SigninFormState=>{
    switch(action.type){
        case ACTIONS.UPDATE_EMAIL:
            return{
                ...state,
                values:{
                    ...state.values,
                    email:action.payload
                }
            }
        case ACTIONS.UPDATE_PASSWORD:
            return{
                ...state,
                values:{
                    ...state.values,
                    password:action.payload
                }
            }
        default:
            return state;
    }
};

//--------------------------------------------------MAIN COMPONENT-----------------------------------------------------------------//
export default function SignInForm() {

    const [form,dispatch]=React.useReducer(reducer,initialState);
    const [showPassword,setShowPassword]=React.useState<boolean>(false);
    const [error,setError]=React.useState<SignInError>({show:false});
    const [loading,setLoading]=React.useState<boolean>(false);
    const authContext:AuthContextValue=useAuth();
    const handleSubmit=()=>{
        setLoading(true);
        setError({show:false});
        try{
            if(SigninFormValidationSchema.validateSync(form.values)){
                authContext.signInEmail(form.values).then((result:AuthFunctionReturn)=>{
                    if(!result.success){
                        setError({show:true,error:result.error});
                    }
                    setLoading(false);
                })
            };
        }catch(err :any){
            const randomDely=Math.floor(Math.random()*1000);
            setTimeout(()=>{
                setError({show:true,error:{message:"Invalid login credentials"}});
                setLoading(false);
            },randomDely);
        }
    };

    
    return(
        <MainForm onSubmit={(e)=>{
            e.preventDefault();
            handleSubmit();
        }}>
            <AnimatePresence>
            {error.show && 
            <motion.div initial={{opacity:0}} animate={{opacity:1}} exit={{opacity:0}} style={{width:"100%"}}>
                <Alert severity="error" sx={{marginTop:"1%",width:"100%"}} >{error.error?.message}</Alert>
            </motion.div>
            }
            </AnimatePresence>
            <TextField label="Email" id="email" variant="standard" fullWidth
            autoComplete='email'
            value={form.values.email} onChange={(e)=>{
                dispatch({type:ACTIONS.UPDATE_EMAIL,payload:e.target.value});
            }}/>
            <TextField label="Password" id="password" variant="standard" fullWidth
            autoComplete='password'
            type={showPassword?"text":"password"}
            value={form.values.password} onChange={(e)=>{
                dispatch({type:ACTIONS.UPDATE_PASSWORD,payload:e.target.value});
            }}
            InputProps={{
                endAdornment: <InputAdornment position="end">
                    {showPassword?
                    <IconButton onClick={()=>setShowPassword(!showPassword)} size='small'>
                    <VisibilityOffIcon  />
                    </IconButton>:
                     <IconButton onClick={()=>setShowPassword(!showPassword)} size='small'>
                    <VisibilityIcon />
                    </IconButton>
                    }
                </InputAdornment>,
              }}    
            
            />
        

            <Button variant="contained" type='submit' sx={{marginTop:"5%"}} fullWidth disabled={loading}> {loading ? <CircularProgress size={20} style={{display:"absolute",right:"1rem"}}/> :"Sign In"}</Button>

        </MainForm>
    );
}

//-----------STYLES----------------
const MainForm=styled.form`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:flex-start;
width:100%;
gap:1.5rem;
height:fit-content;
`;