import { styled as muiStyled } from '@mui/material/styles';

export const MainContainer=muiStyled('div')(()=>({
    position:'fixed',
    overflowY:'scroll',
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'flex-start',
    width:'100%',
    height:'100%',
    backgroundColor:'#EDEDED'

}));
export const DrawerHeader = muiStyled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));

export const HeadingStyle={
    fontWeight: 700,
    fontSize: "2rem",
    letterSpacing: "0.1px"
}
export const SubHeadingStyle={
    fontWeight: 700,
    fontSize: "1.5rem",
    letterSpacing: "0.1px"
}
export const SectionHeadingStyle={
    fontWeight: 700,
    fontSize: "1.2rem",
    color:"rgb(63, 61, 86)",
}

export const SubTextStyle={
    fontWeight: 500,
    fontSize: "1rem",
    color:"grey",
    letterSpacing: "0.1px"
}