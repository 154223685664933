import { Outlet, Navigate } from "react-router-dom";
import { AuthContextValue, useAuth } from "../Contexts/AuthContext";
import Loading from "../Pages/Loading/Loading";
export default function PrivateRoutes() {
  const authContext:AuthContextValue=useAuth();
  return (

    <>
    {
      authContext.authLoading ? <Loading /> :
      authContext.currentUser ?  <Outlet /> : <Navigate to="/SignIn" />}
    </>
    ) ;
}
