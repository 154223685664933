import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import styled from "styled-components";
import { HeadingStyle, SectionHeadingStyle, SubHeadingStyle, } from "../Styles/NewShipmentStyles";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ConstructionIcon from '@mui/icons-material/Construction';
import { useNavigate } from "react-router-dom";
type Selection={name:string,description:string,icon:React.ReactElement,link:string}
const SELECTIONS:Selection[]=[
    {
        name:"Bulk",
        description:"Add multiple containers at once",
        icon:<UnarchiveIcon fontSize="large" color="primary"/>,
        link:"bulk"

    },
    {
        name:"Manual",
        description:"Add containers one by one",
        icon:<ConstructionIcon fontSize="large" color="primary"/>,
        link:"manual"
    }
];
export default function BulkOrManual() {
  return (

      <CenteringDiv>
            {SELECTIONS.map((selection,index)=>{
                return <SelectionCard selection={selection} key={index}/>
            })}  
        </CenteringDiv>
  );
}

function SelectionCard({selection}:{selection:Selection}){
    const navigate=useNavigate();
    return(
    <Card sx={{ width: "20%" ,minWidth:"15rem","&:hover":{scale:"1.1"},transition:"ease 0.3s"}}>
    <CardActionArea onClick={()=>{
        navigate(selection.link);
    }}>

      <CardContent>
        <div style={{width:"100%",display:"flex",justifyContent:"center",padding:"1rem"}}>
        {selection.icon}
        </div>
        <Typography gutterBottom variant="h5" component="div">
          {selection.name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {selection.description}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
  )
}
const LocalMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;

const CenteringDiv = styled.div`
  width: 100%;
  height: 50svh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;

`;