import styled from "styled-components";
import Sidepanel from "./components/Sidepanel";
import BahrainImage from "../../Assets/Images/Bahrain4.png";

const MainContainer=styled.div`
display:grid;  
grid-template-columns: 1fr 3fr;
width:100vw;
height:100vh;

`;
export default function Join() {
    return(
        <MainContainer>
            <Sidepanel/>
            <div style={{backgroundImage:`url(${BahrainImage})`, width:"100%",height:"100%",filter:"brightness(0.7)"}} />
        </MainContainer>
    )
}