import { Badge, TableCell } from "@mui/material";
import { Container } from "../Logic/NewShipmentLogic";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOffIcon from "@mui/icons-material/FolderOff";

export default function ContainerInfoCells({ container }: { container: Container }) {
  return (
    <>
      <TableCell component="th" scope="row">
        {container.confirmedByDriver || container.confirmedSelection ? container.number : "TBD"}
      </TableCell>
      <TableCell align="left">{container.confirmedByDriver || container.confirmedSelection ? container.type : "TBD"}</TableCell>
      <TableCell align="center">{container.confirmedByDriver || container.confirmedSelection ? (container.empty ? "EMPTY" : container.netWeight) : "TBD"}</TableCell>
      <TableCell align="left">{container.confirmedByDriver || container.confirmedSelection ? container.bookingNumber!==""? container.bookingNumber :"-": "TBD"}</TableCell>
      <TableCell align="left">{container.confirmedByDriver || container.confirmedSelection ? container.sealNumber!==""?container.sealNumber:"-": "TBD"}</TableCell>
      <TableCell align="left">{container.BLRef ? container.BLRef : "NO"}</TableCell>
      <TableCell align="left">{container.CRFRef ? container.CRFRef : "NO"}</TableCell>
      <TableCell align="center">
        {container.documents.length !== 0 ? (
          <Badge badgeContent={container.documents.length} color="primary">
            <FolderIcon />
          </Badge>
        ) : (
          <FolderOffIcon color="disabled" />
        )}
      </TableCell>
    </>
  );
}
