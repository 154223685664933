import React from 'react';
import { Button, FormControl, InputAdornment, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import styled from 'styled-components';
import ReactCountryFlag from 'react-country-flag';
import { object, string, number, date, InferType } from 'yup';
//----------------

//----------------CONSTANTS----------------
const ACTIONS={
    UPDATE_NAME:"update-name",
    UPDATE_EMAIL:"update-email",
    UPDATE_COUNTRY:"update-country",
    UPDATE_PHONE:"update-phone"
}
const COUNTRIES:Country[]=[
    {
        name:"Bahrain",
        code:973,
        abreveation:"BH"
    },
    {
        name:"Saudi Arabia",
        code:966,
        abreveation:"SA"
    }
];
//---------------- Types ----------------
type JoinFormState={
    values:{
    name:string;
    email:string;
    country:Country;
    phone:string;
}
    errors:{
        name:string;
        email:string;
        phone:string;
    }
};
type JoinFormAction={
    type:string;
    payload:string;
};
type Country={
    name:string;
    abreveation:string;
    code:number;
}
//---------------- Validation ----------------
const nameValidation=string().min(3).max(20).matches(/^[a-zA-Z ']+$/);
const emailValidation=string().email();
const phoneValidation=string().min(8).max(15).matches(/^\d+$/);
const JoinFormValidationSchema=object({
    name:nameValidation.required(),
    email:emailValidation.required(),
    phone:phoneValidation.required(),
    country:object({
        name:string().required(),
        abreveation:string().required(),
        code:number().required()
    }).required()
});

//---------------- State Management ----------------
const initialState:JoinFormState={
    values:{
    name:"",
    email:"",
    country:{name:"Bahrain",abreveation:"BH",code:973},
    phone:"",
},
    errors:{
        name:"",
        email:"",
        phone:"",
    }
}
const reducer=(state:JoinFormState,action:JoinFormAction):JoinFormState=>{
    switch(action.type){
        case ACTIONS.UPDATE_NAME:
            if(!nameValidation.isValidSync(action.payload)){
                return {values:{...state.values,name:action.payload},errors:{...state.errors,name:"Invalid Name"}}
            }
            return {values:{...state.values,name:action.payload},errors:{...state.errors,name:""}};
        case ACTIONS.UPDATE_EMAIL:
            if(!emailValidation.isValidSync(action.payload)){
                return {values:{...state.values,email:action.payload},errors:{...state.errors,email:"Invalid Email"}}
            }
            return {values:{...state.values,email:action.payload},errors:{...state.errors,email:""}};
        case ACTIONS.UPDATE_COUNTRY:
            const country:Country=COUNTRIES.find((country:Country)=>country.abreveation===action.payload)!;
            return {...state,values:{...state.values,country:country}};
        case ACTIONS.UPDATE_PHONE:
            //check if payload is not a number
            if(isNaN(Number(action.payload))){
                return state;
            }
            if(!phoneValidation.isValidSync(action.payload)){
                return {values:{...state.values,phone:action.payload},errors:{...state.errors,phone:"Invalid Phone Number"}}
            }
            return {values:{...state.values,phone:action.payload},errors:{...state.errors,phone:""}};
        default:
            console.warn("Invalid Action Type");
            return state;
    };
};

//--------------------------------------------------MAIN COMPONENT-----------------------------------------------------------------//
export default function JoinForm() {

    const [form,dispatch]=React.useReducer(reducer,initialState);
    return(
        <MainForm>

            <TextField label="Name" id="name" variant="standard" fullWidth
            autoComplete='name'
            error={form.errors.name!==""} helperText={form.errors.name}
            FormHelperTextProps={{sx:{position:"absolute",bottom:"-1.3rem"}}}
            value={form.values.name} onChange={(e)=>{
                dispatch({type:ACTIONS.UPDATE_NAME,payload:e.target.value});
            }}/>

            <TextField label="Email" id="email" variant="standard" fullWidth
            autoComplete='email'
            error={form.errors.email!==""} helperText={form.errors.email}
            FormHelperTextProps={{sx:{position:"absolute",bottom:"-1.3rem"}}}
            value={form.values.email} onChange={(e)=>{
                dispatch({type:ACTIONS.UPDATE_EMAIL,payload:e.target.value});
            }}/>

            <div style={{display:"flex",gap:"0.5rem",alignItems:"flex-end",width:"100%"}}>

                <FormControl sx={{width:"20%"}}>
                    {/* <InputLabel id="country">Country</InputLabel> */}
                    <Select
                        labelId="country"
                        id="country-select"
                        variant='standard'
                        value={form.values.country.abreveation}
                        onChange={(e)=>{
                            dispatch({type:ACTIONS.UPDATE_COUNTRY,payload:e.target.value});
                        }}

                        renderValue={(value:string)=><ReactCountryFlag style={{margin:"0.3rem"}} countryCode={value} svg/>}
                        >
                        {COUNTRIES.map((country:Country)=>{
                            return(
                                <MenuItem value={country.abreveation} key={country.code}>
                                    <ListItemIcon>
                                        <ReactCountryFlag countryCode={country.abreveation} svg/>
                                    </ListItemIcon>
                                    <ListItemText>
                                        {country.name} (+ {country.code})
                                    </ListItemText>
                                </MenuItem>
                            );
                        }
                        )}
                    </Select>
                </FormControl>

                <TextField label="Phone Number" id="phone" variant="standard" sx={{width:"80%"}}
                autoComplete='phone'
                InputProps={{
                    startAdornment: <InputAdornment position="start">+{form.values.country.code}</InputAdornment>,
                  }}
                error={form.errors.phone!==""} helperText={form.errors.phone}
                FormHelperTextProps={{sx:{position:"absolute",bottom:"-1.3rem"}}}
                value={form.values.phone} onChange={(e)=>{
                    dispatch({type:ACTIONS.UPDATE_PHONE,payload:e.target.value});
                }}/>

            </div>

            <Button variant="contained" sx={{marginTop:"20%"}} fullWidth>Join</Button>

        </MainForm>    
    );
}

//-----------STYLES----------------
const MainForm=styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:center;
gap:1.5rem;
width:100%;
height:100%;
`;