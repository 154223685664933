import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { GoogleMap, MarkerF } from '@react-google-maps/api';
import parse from 'autosuggest-highlight/parse';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import { useFormik } from "formik";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import * as Yup from 'yup';
import { useAuth } from '../../../Contexts/AuthContext';
import { firestore } from '../../../Firebase/Firebase';
import { createFacility } from "../../../Firebase/Firestore/Facilities";
import { Facility, INITIAL_CITY, INITIAL_FACILITY, INITIAL_MOVEMENT, Movement } from "../Logic/NewShipmentLogic";
import { Transition, mapOptions } from "./ShipmentForm";
export default function FacilityDialog({movement,setMovement,openFacilityDialog,setOpenFacilityDialog}:{movement:Movement,setMovement:Function,openFacilityDialog:boolean,setOpenFacilityDialog:Function}) {
    const [addNewFacility,setAddNewFacility]=React.useState<boolean>(false);
    const {currentUser}=useAuth();
    const [facilities,setFacilities]=React.useState<Facility[]>([]);
    const [loading,setLoading]=React.useState<boolean>(true);
    console.log("selected facility", movement.facility);
    React.useEffect(()=>{
      const q=query(collection(firestore,"Facilities"),where("owner","in",[currentUser?.uid,"DEFAULT"]));
      const unsubscribe=onSnapshot(q,(querySnapshot)=>{
          const facilities:Facility[]=[];
          querySnapshot.forEach((doc)=>{
              let facility:Facility={
                  ...doc.data(),
                  id:doc.id,
                  name:doc.data().name as string,
                  owner:doc.data().owner as string,
                  addressLine1:doc.data().addressLine1 as string,
                  addressLine2:doc.data().addressLine2 as string,
                  city:doc.data().city as google.maps.places.AutocompletePrediction,
                  coordinates:doc.data().coordinates as {lat:number,lng:number},
                  primaryContact:doc.data().primaryContact as {name:string,number:string},
                  
                }
                //filtering out undefined secondary contact in facility object
                doc.data().secondaryContact && (facility.secondaryContact=doc.data().secondaryContact as {name:string,number:string});
                facilities.push(facility);
          }
          )
          setFacilities(facilities);
          setLoading(false);
      });
      return unsubscribe;
  },[]);
      return(
        <>
          <Dialog
          fullWidth
          PaperProps={{
            style:{
              maxWidth:"fit-content",
              minWidth:"10rem",
              minHeight:"10rem"
            }
          }}
          open={openFacilityDialog}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="Facility Dialog"
  
        >
          <DialogTitle>
              {`Destination Facility Details`}
          </DialogTitle>
          <DialogContent style={{height:"100%",minHeight:"30rem",width:"50rem",padding:"1rem "}}>
          <Autocomplete
              loading={loading}
              disablePortal
              id="facilities-combo-box"
              options={facilities}
              getOptionLabel={(option) => option?.name || ""}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: "100%",marginBottom:"1rem" }}
              value={movement.facility?.id?movement.facility:null}
              renderInput={(params) => <TextField {...params} label="Facility" fullWidth/>}
              noOptionsText={
                <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  setAddNewFacility(true);
                }}
              >
                No Facility Found - add new Facility
              </Button>
              }
              onChange={(event,value)=>{
                if(value!==null){
                setMovement({...movement,facility:value});
              }else{
                setMovement({...movement,facility:INITIAL_MOVEMENT.facility});
              }
              }}
              
              renderOption={
                  (props,facility)=>{
                  return (
                      <li {...props}>
                          <Grid container alignItems="center">
                            <Grid item sx={{ display: 'flex', width: 44 }}>
                              <LocationOnIcon sx={{ color: 'text.secondary' }} />
                            </Grid>
                            <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                            <Typography variant="body2">
                                {facility?.name}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                {facility?.addressLine1}
                              </Typography>
                              </Grid>
                          </Grid>
                      </li>
                      )
                  }
              }
          />
          {movement.facility && movement.facility.id ? (
          <>
          <Typography variant="h6" color="primary">{movement.facility.name}</Typography>
          <FacilityDetails>
  
                
                  
                  <InlineDiv>
                   <strong>City:</strong><Typography variant="body2" color="text.secondary">{movement.facility.city.description}</Typography>
                  </InlineDiv>
                  <InlineDiv>
                   <strong>Address:</strong> <Typography variant="body2" color="text.secondary">{movement.facility.addressLine1},{movement.facility.addressLine2}</Typography>
                  </InlineDiv>
                  
                  <InlineDiv>
                    <strong>Primary Contact:</strong>
                    <Typography variant="body2" color="text.secondary"> {`${movement.facility.primaryContact.name}, ${movement.facility.primaryContact.number}` } </Typography>
                  </InlineDiv>
                  {
                    movement.facility.secondaryContact &&
                      (<InlineDiv>
                        <strong>Secondary Contact:</strong>
                        <Typography variant="body2" color="text.secondary"> {`${movement.facility.secondaryContact.name}, ${movement.facility.secondaryContact.number}` } </Typography>
                      </InlineDiv>)
                  }
          </FacilityDetails>
          {movement.facility.actionSequenceModifiers && Object.keys(movement.facility.actionSequenceModifiers).length>0?
          <>
                    <Divider style={{margin:"0.5rem 0 0.5rem 0",borderBottomWidth:"thick", borderRadius:"0.5rem"}} />
                        <FormControl>
                        <FormLabel id="action-sequence-modifieres-group-label" style={{color:"black",fontSize:"1rem",fontWeight:"bold"}}>Additional Steps</FormLabel>
                        <RadioGroup
                          aria-labelledby="action-sequence-modifieres-group-label"
                          name="action-sequence-modifieres-group"
                          row

                          value={movement.facility.selectedSequanceModifier}
                        >
                          {Object.keys(movement.facility.actionSequenceModifiers).map((actionModifier)=>{
                            return <FormControlLabel style={{ pointerEvents: "none" }}
                            key={actionModifier} value={actionModifier} control={<Radio style={{ pointerEvents: "auto" }}
                            />} label={actionModifier} onClick={(e)=>{
                              if(actionModifier===movement.facility.selectedSequanceModifier){
                                setMovement({...movement,facility:{...movement.facility,selectedSequanceModifier:""}});
                              }
                              else{
                                setMovement({...movement,facility:{...movement.facility,selectedSequanceModifier:actionModifier}});

                              }
                            }}/>
                          })
                        }
                          
                        </RadioGroup>
                      </FormControl>
                      </>
                      :null
                        }
                          <GoogleMap center={movement.facility.coordinates as google.maps.LatLngLiteral} 
                          mapContainerStyle={{height:"15rem",borderRadius:"10px 10px 0 0",marginTop:"1rem",marginBottom:"1rem"}}
                          options={mapOptions}
                         >
                          <MarkerF position={movement.facility.coordinates as google.maps.LatLngAltitudeLiteral} clickable={false}/>
                        </GoogleMap>

  
                        </>):
                        (
                          <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"flex-end",alignItems:"flex-end",marginTop:"22rem"}}>
                            <Button variant='contained' 
                            onClick={()=>{
                              setAddNewFacility(true);
                            }}
                            >Add New Facility</Button>
                          </div>
                        )
                      }
          </DialogContent>
          <DialogActions style={{borderTop:"1px solid #c4c4c4",marginTop:"1rem"}}>
            <Button variant="contained" 
            onClick={()=>{
              setOpenFacilityDialog(false);
            }}
            color="primary">
              Save
            </Button>
          </DialogActions>
          </Dialog>
  
          <NewFacilityDialog setMovement={setMovement}  setAddNewFacility={setAddNewFacility} open={addNewFacility}/> 
            </>
      )
  
  }
  

  function NewFacilityDialog({setMovement,open,setAddNewFacility}:{setMovement:Function,open:boolean,setAddNewFacility:Function}) {
    {
      const SELECTION_BOUNDS= {
          north: 26.35,
          south: 25.75,
          west: 50.35,
          east: 50.73,
      }
      const mapRef=React.useRef<google.maps.Map|null>(null);
  
      
      const [loading,setLoading]=React.useState<boolean>(false);
      const onMapLoad=(map:google.maps.Map)=>{
          mapRef.current=map;
      };
      const newFacilityFormState =useFormik({
        initialValues:INITIAL_FACILITY,
        validationSchema:Yup.object({
          name:Yup.string().required("Required"),
          city:Yup.object().shape({
            description:Yup.string().required("Required"),
          }),
          addressLine1:Yup.string().required("Required"),
          addressLine2:Yup.string(),
          coordinates:Yup.object().shape({
            lat:Yup.number().required("Required").notOneOf([0],"Required"),
            lng:Yup.number().required("Required").notOneOf([0],"Required"),
          }),
          primaryContact:Yup.object().shape({
            name:Yup.string().matches(/^[a-zA-Z ']+$/,"Invalid name").max(20,"Invalid name").required("Required"),
            number:Yup.string().matches(/^[0-9+]+$/,"Invalid number").max(20,"Invalid number").required("Required"),
          }),
      
        }),
        onSubmit:(values)=>{
          if(currentUser!==null){
          setLoading(true);
          createFacility({facilityValues:{...values,owner:currentUser.uid}}).then((res)=>{
            if(res.success){
              setMovement({...INITIAL_MOVEMENT,facility:res.facility});
              setAddNewFacility(false);
            }else{
              alert("ops something went wrong");
              console.error(res.error);
            }
          }).catch((err)=>{
            alert("ops something went wrong");
            console.error(err);
          }).finally(()=>{
            setLoading(false)
            newFacilityFormState.resetForm();
          });
          }else{
            alert("ERR:USER_NOT_FOUND\nops something went wrong");
          }
        },
      })
  
      console.log("facility form state",newFacilityFormState);
      console.log("error truthness",newFacilityFormState.touched.city?.description &&
      Boolean(newFacilityFormState.errors.city?.description))
      console.log("error printing",newFacilityFormState.touched.city?.description &&
      newFacilityFormState.errors.city?.description)
      const {
        suggestions:{data},
        setValue:setSearchedValue,
    }=usePlacesAutocomplete(
        {
            requestOptions:{
                types:['(cities)'],
                componentRestrictions:{country: ['bh'] },
                // bounds:{
                //     south: 25.75,
                //     west: 50.35,
                //     north: 26.35,
                //     east: 50.73,
                // }, bahrain bounds
            }
        }
    );
        React.useEffect(()=>{
          if(newFacilityFormState.values.coordinates.lat!==0 && newFacilityFormState.values.coordinates.lng!==0){
            mapRef.current?.panTo(newFacilityFormState.values.coordinates);
            newFacilityFormState.setFieldValue("coordinates",newFacilityFormState.values.coordinates);
          }
        },[newFacilityFormState.values.coordinates.lat,newFacilityFormState.values.coordinates.lng])
        React.useEffect(()=>{
          if(newFacilityFormState.values.city.description===""){
            return;
          }
          getGeocode({address:newFacilityFormState.values.city.description}).then((results)=>{
            if(results.length>0){
                const {lat,lng}=getLatLng(results[0]);
                mapRef.current?.panTo({lat,lng});
                mapRef.current?.setZoom(13);
                
            }
        }).catch((err)=>{
            console.error(err);
        })
      }
      ,[newFacilityFormState.values.city.description])
      const center=React.useMemo<google.maps.LatLngLiteral>(()=>{return newFacilityFormState.values.coordinates || {lat: 26.12, lng: 50.53};},[]);
      const {currentUser}=useAuth();
      // function handleCreate() {
      //     const PrimaryValid=Object.values(newFacilityFormState.errors?.primaryContact || {}).every((value) => value === undefined);
      //         const SecondaryValid=Object.values(newFacilityFormState.errors?.secondaryContact || {}).every((value) => value === undefined);
      //         if(PrimaryValid && SecondaryValid && Object.values({...newFacilityFormState.errors,primaryContact:undefined,secondaryContact:undefined}).every((value) => value === undefined)){
      //             setLoading(true);
      //             createFacility({...newFacilityFormState.values,owner:currentUser?.uid}).then((res)=>{
      //             if(res.success){
      //                 globalDispatch({
      //                     type:facilityDialog==="Pickup"? ACTIONS.FACILITIES.UPDATE_PICKUP : ACTIONS.FACILITIES.UPDATE_DELIVERY,
      //                     payload:res.facility
      //                 })
      //                 setAddNewFacility(false);
      //                 // setFacilityDialog(null);
  
      //             }
      //             else{
  
      //                 alert("ops something went wrong");
      //                 console.error(res.error);
      //             }
      //         }).catch((err)=>{
      //             alert("ops something went wrong");
      //                 console.error(err);
      //         }).finally(()=>{setLoading(false)});
      //         }
      //         else{
      //     for (const key of Object.keys(NEW_FACILITY_ACTIONS)) {
      //         newFacilityFormDispatch({
      //           type: NEW_FACILITY_ACTIONS[key as keyof typeof NEW_FACILITY_ACTIONS],
      //           payload: "VALIDATE"
      //         });
      //     }
      // }
      //   }
      return (
        <Dialog
          open={open}
          PaperProps={{
            style: {
              maxWidth: "fit-content",
              minWidth: "10rem",
              minHeight: "10rem",
            },
          }}
        >
          <DialogTitle>New Facility</DialogTitle>
          <DialogContent
            style={{
              height: "100%",
              minHeight: "10rem",
              width: "35rem",
              padding: "2rem",
            }}
          >
            <NewFacilityForm>
              <TextField
                required
                value={newFacilityFormState.values.name}
                name="name"
                id="name"
                error={
                  newFacilityFormState.touched.name &&
                  Boolean(newFacilityFormState.errors.name)
                }
                helperText={
                  newFacilityFormState.touched.name &&
                  newFacilityFormState.errors.name
                }
                onChange={(e) => {
                  newFacilityFormState.setFieldValue("name", e.target.value);
                }}
                onBlur={newFacilityFormState.handleBlur("name")}
                FormHelperTextProps={{
                  style: { position: "absolute", bottom: "-1.3rem" },
                }}
                label="Facility Name"
                variant="standard"
                inputProps={{ maxLength: 50 }}
              />
              {/* <TextField
                required
                label="City"
                variant="standard"
                name="city"
                id="city"
                value={newFacilityFormState.values.city}
                disabled
                inputProps={{ maxLength: 50 }}
              /> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Autocomplete
                  options={data}
                  style={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    option.place_id === value.place_id
                  }
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.description
                  }
                  onInputChange={(e, newInputValue) => {
                    setSearchedValue(newInputValue);
                  }}
                  onChange={async (e, newValue) => {
                    // console.log(newValue);
                    if (newValue !== null) {
                      await newFacilityFormState.setFieldValue("city", newValue);
                    }else{
                      await newFacilityFormState.setFieldValue("city", INITIAL_CITY);
                    }
                    await newFacilityFormState.setFieldTouched("city.description", true);
  
                  }}
                  onBlur={newFacilityFormState.handleBlur("city.description")}
                  value={newFacilityFormState.values.city }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      variant="standard"
                      style={{ width: "100%" }}
                      required
                      error={
                        newFacilityFormState.touched.city?.description &&
                        Boolean(newFacilityFormState.errors.city?.description)
                      }
                      helperText={
                        newFacilityFormState.touched.city?.description &&
                        newFacilityFormState.errors.city?.description
                      }
                      FormHelperTextProps={{
                        sx: { position: "absolute", bottom: "-1.3rem" },
                      }}
                    />
                  )}
                  renderOption={(props, option) => {
                    const matches =
                      option.structured_formatting.main_text_matched_substrings ||
                      [];
  
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map((match: any) => [
                        match.offset,
                        match.offset + match.length,
                      ])
                    );
                    return (
                      <li {...props}>
                        <Grid container alignItems="center">
                          <Grid item sx={{ display: "flex", width: 44 }}>
                            <LocationOnIcon sx={{ color: "text.secondary" }} />
                          </Grid>
                          <Grid
                            item
                            sx={{
                              width: "calc(100% - 44px)",
                              wordWrap: "break-word",
                            }}
                          >
                            {parts.map((part: any, index: number) => (
                              <Box
                                key={index}
                                component="span"
                                sx={{
                                  fontWeight: part.highlight ? "bold" : "regular",
                                }}
                              >
                                {part.text}
                              </Box>
                            ))}
                            <Typography variant="body2" color="text.secondary">
                              {option.structured_formatting.secondary_text}
                            </Typography>
                          </Grid>
                        </Grid>
                      </li>
                    );
                  }}
                />
                {/* {error? <Typography variant="body2" color="error" style={{position:"absolute",bottom:"-0.1rem"}}>{error}</Typography>:null} */}
              </div>
  
              <TextField
                required
                label="Address Line 1"
                variant="standard"
                name="addressLine1"
                id="addressLine1"
                value={newFacilityFormState.values.addressLine1}
                onChange={(e) => {
                  newFacilityFormState.setFieldValue(
                    "addressLine1",
                    e.target.value
                  );
                }}
                onBlur={newFacilityFormState.handleBlur("addressLine1")}
                error={
                  newFacilityFormState.touched.addressLine1 &&
                  Boolean(newFacilityFormState.errors.addressLine1)
                }
                helperText={
                  newFacilityFormState.touched.addressLine1 &&
                  newFacilityFormState.errors.addressLine1
                }
                FormHelperTextProps={{
                  style: { position: "absolute", bottom: "-1.3rem" },
                }}
                inputProps={{ maxLength: 50 }}
              />
  
              <TextField
                label="Address Line 2"
                variant="standard"
                name="addressLine2"
                id="addressLine2"
                value={newFacilityFormState.values.addressLine2}
                onChange={(e) => {
                  newFacilityFormState.setFieldValue(
                    "addressLine2",
                    e.target.value
                  );
                }}
                onBlur={newFacilityFormState.handleBlur("addressLine2")}
                error={
                  newFacilityFormState.touched.addressLine2 &&
                  Boolean(newFacilityFormState.errors.addressLine2)
                }
                helperText={
                  newFacilityFormState.touched.addressLine2 &&
                  newFacilityFormState.errors.addressLine2
                }
                FormHelperTextProps={{
                  style: { position: "absolute", bottom: "-1.3rem" },
                }}
                inputProps={{ maxLength: 50 }}
              />
              <InlineInputs>
                <TextField
                  required
                  label="Primary Contact Name"
                  variant="standard"
                  name="primaryContact.name"
                  id="PrimaryContact.name"
                  value={newFacilityFormState.values.primaryContact.name}
                  onChange={(e) => {
                    newFacilityFormState.setFieldValue(
                      "primaryContact.name",
                      e.target.value
                    );
                  }}
                  onBlur={newFacilityFormState.handleBlur("primaryContact.name")}
                  error={
                    newFacilityFormState.touched.primaryContact?.name &&
                    Boolean(newFacilityFormState.errors.primaryContact?.name)
                  }
                  helperText={
                    newFacilityFormState.touched.primaryContact?.name &&
                    newFacilityFormState.errors.primaryContact?.name
                  }
                  FormHelperTextProps={{
                    style: { position: "absolute", bottom: "-1.3rem" },
                  }}
                  inputProps={{ maxLength: 50 }}
                  style={{width:"100%"}}
                />
                <TextField
                  required
                  label="Primary Contact Number"
                  variant="standard"
                  name="PrimaryContact.number"
                  id="PrimaryContact.number"
                  value={newFacilityFormState.values.primaryContact.number}
                  onChange={(e) => {
                    newFacilityFormState.setFieldValue(
                      "primaryContact.number",
                      e.target.value
                    );
                  }}
                  onBlur={newFacilityFormState.handleBlur(
                    "primaryContact.number"
                  )}
                  error={
                    newFacilityFormState.touched.primaryContact?.number &&
                    Boolean(newFacilityFormState.errors.primaryContact?.number)
                  }
                  helperText={
                    newFacilityFormState.touched.primaryContact?.number &&
                    newFacilityFormState.errors.primaryContact?.number
                  }
                  FormHelperTextProps={{
                    style: { position: "absolute", bottom: "-1.3rem" },
                  }}
                  inputProps={{ maxLength: 50 }}
                  style={{width:"100%"}}
                />
              </InlineInputs>
              <div style={{marginTop: "1rem",marginBottom: "1rem",}}>
              <Typography variant="caption" style={{margin:0}} color={newFacilityFormState.touched.coordinates?.lat && newFacilityFormState.errors.coordinates?.lat ? "error" : "primary"}>Please click on the map to precicly place marker* </Typography>
  
              <GoogleMap
                zoom={15}
                center={center}
                mapContainerStyle={{
                  height: "15rem",
                  borderRadius: "10px 10px 0 0",
                  border: newFacilityFormState.touched.coordinates?.lat && newFacilityFormState.errors.coordinates?.lat ? "2px solid #d53939" : "none",
                }}
                onLoad={onMapLoad}
                onClick={(e) => {
                  if(e.latLng?.lat() && e.latLng.lng() && e.latLng?.lat() < SELECTION_BOUNDS.north && e.latLng?.lat() > SELECTION_BOUNDS.south && e.latLng?.lng() < SELECTION_BOUNDS.east && e.latLng?.lng() > SELECTION_BOUNDS.west){
                  newFacilityFormState.setFieldValue("coordinates", {
                    lat: e.latLng?.lat(),
                    lng: e.latLng?.lng(),
                  });
                }
                else{
                  alert("Please select a location within Bahrain");
                }
                }}
                options={mapOptions}
              >
                <MarkerF
                  position={
                    newFacilityFormState.values
                      .coordinates as google.maps.LatLngAltitudeLiteral
                  }
                  clickable={false}
                  visible={newFacilityFormState.values.coordinates.lat !== 0 && newFacilityFormState.values.coordinates.lng !== 0}
                />
              </GoogleMap>
              </div>
            </NewFacilityForm>
          </DialogContent>
          <DialogActions
            style={{ borderTop: "1px solid #c4c4c4", marginTop: "1rem" }}
          >
            <Button
              variant="outlined"
              disabled={loading}
              onClick={() => {
                setAddNewFacility(false);
                newFacilityFormState.resetForm();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={loading}
              onClick={() => {
                console.log("create");
                console.log("values", newFacilityFormState.values);
                console.log("errors", newFacilityFormState.errors);
                newFacilityFormState.submitForm();
              }}
            >
              {loading ? (
                <CircularProgress size={24} style={{ color: "white" }} />
              ) : (
                "Create"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
  }
  }
  const NewFacilityForm=styled.form`
display:flex;
flex-direction:column;
width:100%;
height:100%;
gap:1.3rem`;

const InlineInputs=styled.div`
display:flex;
flex-direction:row;
width:100%;
gap:1.3rem;
`;


const FacilityDetails=styled.div`
display:grid;
grid-template-columns:1fr 1fr;
width:100%;
height:100%;
justify-content:flex-start;
align-items:flex-start;
margin-top:1rem;
gap:rem;`;
const InlineDiv=styled.div`
display:flex;
flex-direction:row;
width:100%;
justify-content:flex-start;
align-items:center;
gap:0.1rem;
`;