import  Dayjs  from "dayjs";
import type { Dayjs as DayjsType } from "dayjs";
import { Timestamp } from "firebase/firestore";
//types

export type Truck={
    basePrice:number,
    extraCosts:any[],
    type:string,
    movements:Movement[],
    currentLoad:Container[],
}

type SequenceModifierAction= string | {[action:string]:SequenceModifierAction}
export type Facility={
    id:string,
    owner:string,
    name:string,
    addressLine1:string,
    addressLine2:string,
    city:google.maps.places.AutocompletePrediction,
    actionSequenceModifiers?:{[action:string]:SequenceModifierAction}
    selectedSequanceModifier?:string,
    coordinates:{
        lat:number,
        lng:number,
    },
    primaryContact:{
        name:string,
        number:string,
    },
    secondaryContact?:{
        name:string,
        number:string,
    }
}
export type BL={
    docID:string,
    number:string,
    containers:Container[],
    assignedContainers:String[],
    fullfilled:boolean,
    documents:File[],
    shipper:string,
    createdAt:Timestamp,
}
export type BulkUpload={
    docID:string,
    containers:Container[],
    assignedContainers:String[],
    fullfilled:boolean,
    numberOfTrucks:number,
    shipper:string,
    createdAt:Timestamp,
}
export type CRF={
    docID:string,
    bookingNumber:string,
    containers:Container[],
    documents:File[],
    fullfilled:boolean,
    shipper:string,
    createdAt:Timestamp,
}
export type Container={
    number:string,
    sealNumber:string,
    type:string,
    empty:boolean,
    commodity:string,
    netWeight:number,
    bookingNumber:string,
    containerMovementType:string,
    documents:File[],
    BLRef?:string,
    CRFRef?:string,
    bulkRef?:string,
    confirmedSelection?:boolean,
    confirmedByDriver?:boolean,
}
export type Movement={
    facility:Facility,
    containers:{
        pickup:Container[],
        dropoff:Container[],
    },
    notes:string,
    documents:File[],
}

export type ShipmentFormValues={
    dateTime:DayjsType,
    trucks:Truck[],
}
export type ShipmentForm={
    values:ShipmentFormValues,
}

export type ShipmentFormAction={
    type:string,
    payload?:any,
}

export const ACTIONS={
    SET_SHIPMENT_DATE_TIME:"SET_SHIPMENT_DATE_TIME",
    TRUCKS:{
        ADD_TRUCK:"ADD_TRUCK",
        REMOVE_TRUCK:"REMOVE_TRUCK",
        SET_TYPE:"SET_TYPE",
        MOVEMENT:{
            ADD_MOVEMENT:"ADD_MOVEMENT",
            POP_MOVEMENT:"POP_MOVEMENT",
        }
    },
    RESET:"RESET",
}

export const INITIAL_STATE:ShipmentForm={
    values:{
        dateTime:Dayjs().add(2,"day").hour(8).minute(0).second(0).millisecond(0),
        trucks:[]
    }
}
export const INITIAL_CONTAINER:Container={
    number:"",
    type:"",
    sealNumber:"",
    bookingNumber:"",
    containerMovementType:"",
    empty:false,
    commodity:"",
    netWeight:0,
    documents:[],

}
export const INITIAL_CITY:google.maps.places.AutocompletePrediction={
    description:"",
    matched_substrings:[],
    place_id:"",
    structured_formatting:{
        main_text:"",
        main_text_matched_substrings:[],
        secondary_text:"",
    },
    terms:[],
    types:[],
}

export const INITIAL_COORDINATES={
    lat:0,
    lng:0,
}
export const INITIAL_FACILITY:Facility={
    id:"",
    name:"",
    owner:"",
    addressLine1:"",
    addressLine2:"",
    city:INITIAL_CITY,
    coordinates:INITIAL_COORDINATES,
    primaryContact:{
        name:"",
        number:"",
    },
    secondaryContact:{
        name:"",
        number:"",
    }
}
export const INITIAL_MOVEMENT:Movement={
    facility:INITIAL_FACILITY,
    containers:{
        pickup:[],
        dropoff:[],
    },
    notes:"",
    documents:[],

}

// const parseFacilitySequenceModifier=({facility}:{facility:Facility}):{[action:string]:SequenceModifierAction[]}=>{
//     if(facility.actionSequenceModifiers && facility.selectedSequanceModifier && facility.actionSequenceModifiers[facility.selectedSequanceModifier]){
//         const sequenseToParse=facility.actionSequenceModifiers[facility.selectedSequanceModifier];
//         sequenseToParse.forEach((actionSequence)=>{
//             if(typeof actionSequence==="string"){
//                 return {[actionSequence]:[]}
//             }
//         })

//     }else{
//         return {}
//     }
    
// }
export const INITIAL_TRUCK:Truck={type:"",basePrice:0,extraCosts:[],movements:[],currentLoad:[]}

export const NewShipmentReducer=(state:ShipmentForm=INITIAL_STATE,action:ShipmentFormAction)=>{
    console.log(state);
    switch(action.type){
        case ACTIONS.SET_SHIPMENT_DATE_TIME:
            return {...state,values:{...state.values,dateTime:action.payload}}
        case ACTIONS.TRUCKS.ADD_TRUCK:
            return {...state,values:{...state.values,trucks:[...state.values.trucks,INITIAL_TRUCK]}}
        case ACTIONS.TRUCKS.REMOVE_TRUCK:
            return {...state,values:{...state.values,trucks:state.values.trucks.filter((truck,index)=>index!==action.payload.index)}}
        case ACTIONS.TRUCKS.SET_TYPE:
            return {...state,values:{...state.values,trucks:state.values.trucks.map((truck,index)=>index===action.payload.index?{...truck,type:action.payload.type}:truck)}}
        case ACTIONS.TRUCKS.MOVEMENT.ADD_MOVEMENT:
            let newCurrentLoad=state.values.trucks[action.payload.index].currentLoad;
            newCurrentLoad=newCurrentLoad.filter((container)=>{
                for (let i = 0; i < action.payload.movement.containers.dropoff.length; i++) {
                    if(action.payload.movement.containers.dropoff[i].number===container.number){
                        return false;
                    }
                }
                return true;
            });
            newCurrentLoad=[...newCurrentLoad,...action.payload.movement.containers.pickup];            
            return {...state,values:{...state.values,trucks:state.values.trucks.map((truck,index)=>index===action.payload.index?{...truck,movements:[...truck.movements,action.payload.movement],currentLoad:newCurrentLoad}:truck)}}
        case ACTIONS.TRUCKS.MOVEMENT.POP_MOVEMENT:
            let newCurrentLoad2=state.values.trucks[action.payload.index].currentLoad;
            newCurrentLoad2=newCurrentLoad2.filter((container)=>{
                for (let i = 0; i < state.values.trucks[action.payload.index].movements[state.values.trucks[action.payload.index].movements.length-1].containers.pickup.length; i++) {
                    if(state.values.trucks[action.payload.index].movements[state.values.trucks[action.payload.index].movements.length-1].containers.pickup[i].number===container.number){
                        return false;
                    }
                }
                return true;
            });
            newCurrentLoad2=[...newCurrentLoad2,...state.values.trucks[action.payload.index].movements[state.values.trucks[action.payload.index].movements.length-1].containers.dropoff];
            return {...state,values:{...state.values,trucks:state.values.trucks.map((truck,index)=>index===action.payload.index?{...truck,movements:state.values.trucks[action.payload.index].movements.slice(0,state.values.trucks[action.payload.index].movements.length-1),currentLoad:newCurrentLoad2}:truck)}}

        case ACTIONS.RESET:
            return {...INITIAL_STATE,dateTime:Dayjs().add(1,"day").add(1,"hour").minute(0).second(0).millisecond(0)};
        default:
            console.warn("Action not found: ",action.type);
            return state;

        
    }
}