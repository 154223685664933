import React from "react";
import { Box, Typography } from "@mui/material";
import NavDrawer from "../globalComponents/Navigation/Navigation";
import { DrawerHeader, HeadingStyle, MainContainer } from "./Styles/NewShipmentStyles";
import { motion } from 'framer-motion';
import ShipmentForm from "./Components/ShipmentForm";
import BulkOrManual from "./Components/BulkOrManual";
import { Route, Routes } from "react-router-dom";
import { AddBulkShipment } from "./Components/AddBulkShipment";
import styled from "styled-components";
import { INITIAL_STATE, NewShipmentReducer } from "./Logic/NewShipmentLogic";
import { useLoadScript } from "@react-google-maps/api";

const libraries=["places"] as ["places"];

export default function NewShipment() {
  const [globalFormState, globalDispatch] = React.useReducer(
    NewShipmentReducer,
    INITIAL_STATE
  );
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY as string,
    libraries: libraries,
  });
  return (
    <MainContainer>
      <NavDrawer />

      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }}>
          <LocalMainContainer>
            <Typography style={HeadingStyle} color="primary">
              New Shipment
            </Typography>
          </LocalMainContainer>
          <Routes>
            <Route path="/" element={<BulkOrManual />} />
            <Route path="/bulk" element={<AddBulkShipment globalFormState={globalFormState} globalDispatch={globalDispatch} />}/>
            <Route path="/manual" element={<ShipmentForm  globalFormState={globalFormState} globalDispatch={globalDispatch} isLoaded={isLoaded}/>}  />
          </Routes>
        </motion.div>
      </Box>
    </MainContainer>
  );
}

const LocalMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;