import { Typography, Step, StepLabel, Button, Box ,Stepper, Card, CardContent, CardActions, ButtonGroup, TableRow, Table, TableBody, TableCell, TableHead, TableContainer} from "@mui/material";
import { move, useFormik } from "formik";
import React from "react";
import styled from "styled-components";
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { validateContainerNumber } from "./AddContainerMovement";
import { ACTIONS, BulkUpload, Container, INITIAL_FACILITY, Movement } from "../Logic/NewShipmentLogic";
import { CONTAINER_TO_UNIT, TRUCKS_AND_CONTAINER_CAPACITY } from "./ShipmentForm";
import ContainerInfoCells from "./ContainerInfoCells";
import {ShipmentForm as ShipmentFormType } from "../Logic/NewShipmentLogic";
import FacilityDialog from "./FacilityDialog";
import { firestore } from "../../../Firebase/Firebase";
import { addDoc, collection, serverTimestamp } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Dayjs from "dayjs";
import { useAuth } from "../../../Contexts/AuthContext";



export function AddBulkShipment({globalFormState,globalDispatch}: {globalFormState:ShipmentFormType,globalDispatch:Function}){
    const [activeStep, setActiveStep] = React.useState(0);
    const navigate=useNavigate();
    const authContext=useAuth();
    console.log(activeStep)
    const [loading,setLoading]=React.useState(false);
    const form=useFormik({
        initialValues:{
            containerMovementType:"",
            done:[true,false,true,false,true,false,false],
            file:null,
            containers:[],
            initialFacility:INITIAL_FACILITY,
            numberOfTrucks:0,
        },
        onSubmit:(values)=>{
          setLoading(true);
          addDoc(collection(firestore,"Bulks"),{containers:values.containers,shipper:authContext.currentUser?.uid,fullfilled:false,assignedContainers:[],initialFacility:values.initialFacility,numberOfTrucks:values.numberOfTrucks,createdAt:serverTimestamp()}).then((ref)=>{
            for(let i=0;i<values.numberOfTrucks;i++){
              globalDispatch({type:ACTIONS.TRUCKS.ADD_TRUCK});
              //TODO:: Set truck type to the first type in the list fix this line when oyu
              globalDispatch({type:ACTIONS.TRUCKS.SET_TYPE,payload:{index:i,type:Object.keys(TRUCKS_AND_CONTAINER_CAPACITY)[0]}});
              let container:Container=values.containers[i];
              if(container===undefined){
                window.alert("Error matching containers to trucks!\nPlease switch the mode to manual and add the containers to the trucks manually");
                return;
              }
              const movement:Movement={facility:form.values.initialFacility,containers:{pickup:[
                {...container,bulkRef:ref.id}
              ],dropoff:[]},notes:"",documents:[]};
              globalDispatch({type:ACTIONS.TRUCKS.MOVEMENT.ADD_MOVEMENT,payload:{index:i,movement}});
            }
            console.log("globalFormState",globalFormState);
            navigate("/newshipment/manual");
            

          }).catch((error)=>{window.alert("Error saving container details!\nPlease check your internet connection and try again: "+error)}).finally(()=>{setLoading(false)});
          
        }
    })
    console.log(form.values);
    const readCSV=(file:File,index:number)=>{
        const containers:Container[]=[];
        const reader=new FileReader();
        reader.readAsText(file);
        reader.onload=()=>{
            if (reader.result===null){
                window.alert("Invalid File, Please upload a valid CSV file");
                return;
            }
            const lines=reader.result.toString().replace(/(\r)/gm,"").split("\n").filter((line)=>line.length>0);
            console.log("lines",lines)
            for(let index=0;index<lines.length;index++){
                const line=lines[index];
              if(index===0){
                continue;
            }
            if(line.length===0){
              continue;
          }
            const values=line.split(",");
            if(values.length===0){
                continue;
            }
            if(values.length<6){
                window.alert("Invalid File Structure!\nError with container at line "+(index+1)+" Please check the file and try again");
                containers.splice(0,containers.length);
                return;
            }
            const container:Container={
                number:values[0].toUpperCase(),
                type:values[1].toUpperCase(),
                bookingNumber:values[2].toUpperCase(),
                sealNumber:values[3].toUpperCase(),
                netWeight:Number(values[4]),
                commodity:values[5],
                empty:false,
                containerMovementType:form.values.containerMovementType,
                documents:[],
                confirmedSelection:false,
                confirmedByDriver:false,
            };
            if(containers.find((c)=>c.number===container.number) !== undefined){
              window.alert("Duplicate Container Number!\nError with container number at line "+(index+1)+" Please check the container number and try again");
              containers.splice(0,containers.length);
              form.setFieldValue("file",null);
              return;
            }
            if(Object.keys(CONTAINER_TO_UNIT).find((type)=>type===container.type)===undefined){
              window.alert("Invalid Container Type!\nError with container type at line "+(index+1)+"\nAllowed types are "+(Object.keys(CONTAINER_TO_UNIT).join(", ")));
              containers.splice(0,containers.length);
              form.setFieldValue("file",null);
              return;
            }
            if((container.bookingNumber === "" && form.values.containerMovementType === "EXP") || container.sealNumber === "" || container.commodity === "" || container.netWeight < 0){
              window.alert("Invalid Container Details!\nError with container details at line "+(index+1)+" Please fill in all the container details (booking number,seal number,commodity, net weight>=0) and try again");
              containers.splice(0,containers.length);
              form.setFieldValue("file",null);
              return;
            }
            if(!validateContainerNumber(container.number)){
                window.alert("Invalid Container Number!\nError with container number at line "+(index+1)+" Please check the container number and try again");
                containers.splice(0,containers.length);
                form.setFieldValue("file",null);
                return;
            }
            containers.push(container);
        }
        if(containers.length>0){
        form.setFieldValue("containers",containers);
        form.setFieldValue("done",form.values.done.map((value:boolean,localIndex:number)=>(localIndex===index?true:value)));
        setActiveStep(activeStep+1>steps.length-1?activeStep:activeStep+1);
      }else{
        window.alert("No Containers Found!\nPlease check the file and try again");
      }
      form.setFieldValue("file",null);
    };
  }
    React.useEffect(()=>{
      console.log("file being read")
        if(form.values.file){
            readCSV(form.values.file,2);
        }
    },[form.values.file]);
    const steps: React.ReactElement[]=[
        <SelectDateAndTime index={0} title="Select Date and Time" globalDispatch={globalDispatch} globalFormState={globalFormState}/>,
        <SelectMovementType index={1} title="Select Movement Type" form={form}/>,
        <DownloadTemplate index={2} title="Download Template" form={form}/>,
        <UploadCSV index={3} title="Upload CSV" form={form} />,
        <ConfirmContainers index={4} title="Confirm Containers" form={form} />,
        <InitialFacility index={5} title="Select Initial Facility" form={form} />,
        <NumberOfTrucks index={6} title="Select Number of Trucks" form={form} />
];
    return(
        <CenteringDiv>
            <Card>
                <CardContent >
                {steps[activeStep]}
                </CardContent>
                <CardActions>
                    <Button variant="outlined" disabled={activeStep===0} style={{marginLeft:"auto"}} onClick={()=>{
                        setActiveStep(activeStep-1<0?activeStep:activeStep-1);
                    }}>back</Button>
                    {activeStep !== form.values.done.length-1 ?<Button variant="contained" disabled={!form.values.done[activeStep]} onClick={()=>{
                        setActiveStep(activeStep+1>steps.length-1?activeStep:activeStep+1);
                    }}>next</Button>:<Button disabled={loading} color={"success"} variant="contained" onClick={()=>{
                      form.submitForm();
                    }}>continue</Button>}
                </CardActions>
            </Card>
        </CenteringDiv>
    );
}

function SelectDateAndTime({index,title,globalFormState,globalDispatch}:{index:number,title:string,globalFormState:ShipmentFormType,globalDispatch:Function}){
    return(
        <>
      <StepTitle>
        <StepNumber>
          <Typography>{index+1}</Typography>{" "}
        </StepNumber>
        {title}
      </StepTitle>
      <StepContent>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Shipment Date"
                  value={globalFormState.values.dateTime}
                  onChange={(newValue) => {
                    globalDispatch({
                      type: ACTIONS.SET_SHIPMENT_DATE_TIME,
                      payload: newValue,
                    });
                  }}
                  format="DD/MMM/YYYY"
                  minDate={Dayjs().add(1, "day")}
                />
                <TimePicker
                  label="Time"
                  value={globalFormState.values.dateTime}
                  onChange={(newValue) => {
                    globalDispatch({
                      type: ACTIONS.SET_SHIPMENT_DATE_TIME,
                      payload: newValue,
                    });
                  }}
                />
              </LocalizationProvider>
      </StepContent>
    </>
    );
}

function SelectMovementType({ index, title, form }: { index: number; title: string; form: any }) {
  return (
    <>
      <StepTitle>
        <StepNumber>
          <Typography >{index+1}</Typography>{" "}
        </StepNumber>
        {title}
      </StepTitle>
      <StepContent>
        <ButtonGroup fullWidth style={{ gridColumn: "1/3" }}>
          <Button
            onClick={() => {
              form.setFieldValue("containerMovementType", "IMP");
              form.setFieldValue("done", form.values.done.map((value: boolean, localIndex: number) => (localIndex === index ? true : value)));
              form.handleBlur("containerMovementType");
            }}
            variant={form.values.containerMovementType === "IMP" ? "contained" : "outlined"}
            color={form.touched.containerMovementType && Boolean(form.errors.containerMovementType) ? "error" : "primary"}
          >
            Import
          </Button>
          <Button
            onClick={() => {
              form.setFieldValue("containerMovementType", "EXP");
              form.setFieldValue("done", form.values.done.map((value: boolean, localIndex: number) => (localIndex === index ? true : value)));

              form.handleBlur("containerMovementType");
            }}
            variant={form.values.containerMovementType === "EXP" ? "contained" : "outlined"}
            color={form.touched.containerMovementType && Boolean(form.errors.containerMovementType) ? "error" : "primary"}
          >
            Export
          </Button>
          {/* <Button
            onClick={() => {
              form.setFieldValue("containerMovementType", "OTHR");
              form.setFieldValue("done", form.values.done.map((value: boolean, localIndex: number) => (localIndex === index ? true : value)));
              form.handleBlur("containerMovementType");
            }}
            variant={form.values.containerMovementType === "OTHR" ? "contained" : "outlined"}
            color={form.touched.containerMovementType && Boolean(form.errors.containerMovementType) ? "error" : "primary"}
          >
            Other
          </Button> */}
        </ButtonGroup>
      </StepContent>
    </>
  );
}

function DownloadTemplate({index,title,form}:{index:number,title:string,form:any}){
    return(
        
    <>
      <StepTitle>
        <StepNumber>
          <Typography>{index+1}</Typography>{" "}
        </StepNumber>
        {title}
      </StepTitle>
      <StepContent>
      <Typography>Download the template</Typography>
            <Button variant="contained" startIcon={<DownloadIcon/>}
            onClick={()=>{
              const row=form.values.containerMovementType==="EXP"?["Container Number *","Container Type (20GP/20HC/40GP/40HC) *","Booking Number *","Seal Number *","Net Weight * (kg)","Commodity *"]:form.values.containerMovementType==="IMP"?["Container Number *","Container Type (20GP/20HC/40GP/40HC) *","Booking Number","Seal Number *","Net Weight * (kg)","Commodity *"]:[["Container Number *","Container Type (20GP/20HC/40GP/40HC) *","Empty? (Y/N)","Booking Number (* if not empty)","Seal Number","Net Weight (* if not empty) (kg)","Commodity (* if not empty)"]];
              let csvContent = "data:text/csv;charset=utf-8," + row.join(",") + "\n";
              const link=document.createElement("a");
              link.href=encodeURI(csvContent);
              link.target="_blank";
              link.download=`${form.values.containerMovementType} - Bulk Template.csv`;
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }}  
            >Download</Button>
      </StepContent>
    </>
    );
}

function UploadCSV({index,title,form}:{index:number,title:string,form:any}){
    return(
    <>
      <StepTitle>
        <StepNumber>
          <Typography>{index+1}</Typography>{" "}
        </StepNumber>
        {title}
      </StepTitle>
      <StepContent>
      <Typography>Upload the CSV file</Typography>
            {/* <Button variant="contained" startIcon={<UploadIcon/>}>Upload</Button> */}

        <input
        accept=".csv"
        style={{ display: "none" }}
        id="csvfile"
        name="csvfile"
        type="file"
        onChange={(event) => {
            console.log(event.target.files);
            if(event.target.files!==null && event.target.files[0]){
          form.setFieldValue(
            "file",
            event.target.files[0]
          );
          event.target.value='';
        }
        }}
      />
      <label htmlFor="csvfile">
        <Button
          variant="contained"
          component="span"
            startIcon={<UploadIcon />}
        >
            Upload
        </Button>
      </label>

      </StepContent>
    </>
    );
}

function ConfirmContainers({index,title,form}:{index:number,title:string,form:any}){
  return(
      
  <>
    <StepTitle>
      <StepNumber>
        <Typography>{index+1}</Typography>{" "}
      </StepNumber>
      {title}
    </StepTitle>
    <StepContent  >
      <TableContainer sx={{ maxHeight: 220 }}>
    <Table stickyHeader>
    <TableHead >
          <TableRow >
            <TableCell style={{backgroundColor:"lightGrey"}} />
            <TableCell style={{backgroundColor:"lightGrey"}} >Container Number</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Type</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Net Weight&nbsp;(kg)</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Booking Number</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Seal Number</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Related to B/L</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Related to CRF</TableCell>
            <TableCell style={{backgroundColor:"lightGrey"}} align="right">Documents</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
      {form.values.containers.map((container:Container,index:number)=>{
        return(
          <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell>{index+1}</TableCell>
            <ContainerInfoCells key={index} container={{...container,confirmedSelection:true}} />
          </TableRow>
        )
      })}
      </TableBody>
      </Table>
      </TableContainer>
    </StepContent>
  </>
  );
}
function InitialFacility({index,title,form}:{index:number,title:string,form:any}){
  const [movement,setMovement]=React.useState<Movement>({facility:form.values.initialFacility,containers:{pickup:[],dropoff:[]},notes:"",documents:[]});
  const [openFacilityDialog,setOpenFacilityDialog]=React.useState(false);
  React.useEffect(()=>{
    if(movement.facility.id!==""){
    form.setFieldValue("done",form.values.done.map((value:boolean,localIndex:number)=>(localIndex===index?true:value)));
    form.setFieldValue("initialFacility",movement.facility);
  }

  },[movement]);
  return(
  <>
    <StepTitle>
      <StepNumber>
        <Typography>{index+1}</Typography>{" "}
      </StepNumber>
      {title}
    </StepTitle>
    <StepContent>
      <Typography>Initial Facility</Typography>
      <Button variant={movement.facility.id===""?"contained":"outlined"} onClick={()=>setOpenFacilityDialog(true)}>{movement.facility.id===""?"Set Initial Facility":"Edit Initial Facility"}</Button>
    </StepContent>
    <FacilityDialog movement={movement} openFacilityDialog={openFacilityDialog} setMovement={setMovement} setOpenFacilityDialog={setOpenFacilityDialog}/>
  </>
  );
}
function NumberOfTrucks({index,title,form}:{index:number,title:string,form:any}){
  return(
  <>
    <StepTitle>
      <StepNumber>
        <Typography>{index+1}</Typography>{" "}
      </StepNumber>
      {title}
    </StepTitle>
    <StepContent>
    <ButtonGroup>
      {[1,2,3,4,5,6,7,8,9,10].map((number)=>{
        return(
          <Button key={number} disabled={form.values.containers.length<number} variant={number===form.values.numberOfTrucks?"contained":"outlined"} onClick={()=>{
            form.setFieldValue("done",form.values.done.map((value:boolean,localIndex:number)=>(localIndex===index?true:value)));
            form.setFieldValue("numberOfTrucks",number);
          }}>{number}</Button>
        );
      })}

    </ButtonGroup>
    </StepContent>
  </>
  );
}

const StepContent=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:1rem;
padding:1rem;
min-height:10rem;
min-width:30rem;
`;
const StepNumber=styled.div`
width:2rem;
height:2rem;
border-radius:50%;
background-color:#1c2a57;
color:white;
display:flex;
justify-content:center;
align-items:center;
`;
const StepTitle=styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
gap:1rem;
font-weight:700;
font-size:1.2rem;
color:rgb(63, 61, 86);
`;
const CenteringDiv = styled.div`
  width: 100%;
  height: 50svh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

