import styled from "styled-components";
import Sidepanel from "./components/Sidepanel";
import BahrainImage from "../../Assets/Images/Bahrain4.png";

const MainContainer=styled.div`
display:grid;  
grid-template-columns: 2fr 1.5fr;
width:100vw;
height:100vh;
@media (max-width: 992px) {
    grid-template-columns: 1fr 1fr;
}
@media (max-width: 768px) {
    grid-template-columns: 1fr;
}
`;
export default function SignIn() {
    return(
        <MainContainer>
            <div style={{backgroundImage:`url(${BahrainImage})`, width:"100%",height:"100%",filter:"brightness(0.7)" ,backgroundSize:"cover"}} />
            <Sidepanel/>
        </MainContainer>
    )
}