import { FieldValue, Timestamp, addDoc, collection, doc, getDoc, getFirestore, runTransaction, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore";
import { Container, Movement, ShipmentForm,ShipmentFormValues, Truck } from "../../Pages/POSTGITEXT-NewShipment/Logic/NewShipmentLogic";
import firebaseApp, { firestore } from "../Firebase";
import { useAuth } from "../../Contexts/AuthContext";
import { User } from "firebase/auth";
import { Dayjs as DayjsType} from "dayjs";
import dayjs from "dayjs";
export type trackingDoc=Omit<Truck,"movements"|"currentLoad"> &{

    shipmentId:string,
    shipperEmail:string,
    trackingId:string,
    shipmentDateTime:Date,
    status:number,
    basePrice:number,
    extraCosts:any[],
    type:string,
    currentLoad?:string[],
    carrierId?:string,
    driverDeviceId?:string,
    driverMobile?:string,
    driverName?:string,
    startDateTime?:Timestamp,
}

export type MovementAction={
    action:string,
    container?:Container,
    dateTime:Timestamp
    coords:{
        lat:number,
        lng:number,
    }
}
export type TASAppointment={
    ref:string,
    dateTime:Timestamp,
}
export type FirebaseMovement=Movement &{
    docId:string,
    addedAt?:Timestamp,
    completed?:boolean,
    actionLog?:MovementAction[],
    index:number,
    truckTrackingId:string,
    TASAppointment?:TASAppointment,
}
export type ShipmentDoc={
    assignedTrucks:{
        [trackingDocId:string]:string
    }
    dateTime:Date,
    trucks:Omit<trackingDoc,"shipmentId"|"currentLoad"|"status"|"shipmentDateTime">[];
    shipperID:string,
    shipperName:string,
    shipperEmail:string,
    status:number
    type:number,
    createdAt:Timestamp,
    movementMaxIndcies:{
        [movementDocId:string]:number
    },

}
export type Shipment= Omit<ShipmentDoc,"dateTime"> &{
    id:string,
    dateTime:DayjsType,
    assignedTrucks:{
        [key:string]:string
    },
}
export type incomingShipmentDoc=Omit<Shipment,"id"|"dateTime"> &{
    dateTime:Timestamp,
}

export async function createShipment({shipmentValues,user}:{shipmentValues: ShipmentFormValues,user:User}): Promise<{success:true} | {success:false,error:string}> {
    try{
    const assignedTrucks:{[trackingDocId:string]:string}={};
    const movements:{[movementDocId:string]:FirebaseMovement}={};
    const shipmentDocRef=doc(collection(firestore,"Shipments"));
    const movementMaxIndcies:{[movementDocId:string]:number}={};
    
    const preparedTrucks:trackingDoc[]=shipmentValues.trucks.map((truck)=>{
        const trackingId=doc(collection(firestore,"Tracking")).id;
        assignedTrucks[trackingId]="";

        truck.movements.forEach((movement,index)=>{
            const movementDocId=doc(collection(firestore,`Shipments/${shipmentDocRef}/Movements`)).id;
            movements[movementDocId]={...movement,index:index,truckTrackingId:trackingId,docId:movementDocId};
        })
        movementMaxIndcies[trackingId]=truck.movements.length-1;
        return{
            basePrice:truck.basePrice,
            extraCosts:truck.extraCosts,
            type:truck.type,
            shipmentId:shipmentDocRef.id,
            shipperEmail:user.email as string,
            trackingId:trackingId,
            shipmentDateTime:shipmentValues.dateTime.toDate(),
            status:0,
        }
    });

    const preparedShipment:ShipmentDoc={
        dateTime:shipmentValues.dateTime.toDate(),
        assignedTrucks:assignedTrucks,
        trucks:preparedTrucks.map((truck)=>({
            basePrice:truck.basePrice,
            extraCosts:truck.extraCosts,
            type:truck.type,
            trackingId:truck.trackingId,
            shipperEmail:user.email as string,
        })),
        shipperID:user.uid,
        shipperName:user.displayName as string,
        shipperEmail:user.email as string,
        status:0,
        type:0,
        createdAt:serverTimestamp() as Timestamp,
        movementMaxIndcies:movementMaxIndcies,
    }
    const batch=writeBatch(firestore);
    batch.set(shipmentDocRef,preparedShipment);

    preparedTrucks.forEach((truck)=>{
        const trackingDocRef=doc(collection(firestore,"Tracking"),truck.trackingId);
        batch.set(trackingDocRef,truck);
    })
    Object.keys(movements).forEach((movementDocId)=>{
        const movementInShipmentDocRef=doc(collection(firestore,`Shipments/${shipmentDocRef.id}/Movements`),movementDocId);
        const movememntInTrackingDocRef=doc(collection(firestore,`Tracking/${movements[movementDocId].truckTrackingId}/Movements`),movementDocId);
        batch.set(movementInShipmentDocRef,movements[movementDocId]);
        batch.set(movememntInTrackingDocRef,movements[movementDocId]);
    }
    );
    await batch.commit();
    return {success:true as const};
    }
    catch(err :any ){
        return {success:false as const ,error:err.message};
    }
}


export async function addNewMovement({shipmentId,truckTrackingId,movement}:{shipmentId:string,truckTrackingId:string,movement:Movement}): Promise<{success:true} | {success:false,error:string}> {
    try{
        return runTransaction(firestore, async (transaction) => {
            const shipmentDocRef=doc(firestore,"Shipments",shipmentId);
            const shipmentDoc=await getDoc(shipmentDocRef);
            if(!shipmentDoc.exists()){
                throw new Error("Shipment does not exist");
            }
            const shipmentData=shipmentDoc.data() as ShipmentDoc;
            const newIndex=shipmentData.movementMaxIndcies[truckTrackingId]+1;
            const movementDocId=doc(collection(firestore,`Shipments/${shipmentId}/Movements`)).id;
            const movementInShipmentDocRef=doc(collection(firestore,`Shipments/${shipmentId}/Movements`),movementDocId);
            const movememntInTrackingDocRef=doc(collection(firestore,`Tracking/${truckTrackingId}/Movements`),movementDocId);
            const movementDocData={...movement,index:newIndex,truckTrackingId:truckTrackingId,addedAt:serverTimestamp()};
            transaction.set(movementInShipmentDocRef,movementDocData);
            transaction.set(movememntInTrackingDocRef,movementDocData);
            transaction.update(shipmentDocRef,{[`movementMaxIndcies.${truckTrackingId}`]:newIndex});
            return {success:true as const};

            
        });
    }
    catch(err :any ){
        return {success:false as const ,error:err.message};
    }
}

export async function endShipment({shipmentId}:{shipmentId:string}):Promise<{success:true} | {success:false,error:string}>{
    try{
        const shipmentDocRef=doc(firestore,"Shipments",shipmentId);
        const shipmentDoc=await getDoc(shipmentDocRef);
        if(!shipmentDoc.exists()){
            throw new Error("Shipment does not exist");
        }        
        return updateDoc(shipmentDocRef,{status:3}).then(
            ()=>{return {success:true as const};}
        ).catch(
            (err)=>{throw new Error(err);}
        )
    }
    catch(err :any ){
        return {success:false as const ,error:err.message};
    }

}

export async function getShipment({shipmentId}:{shipmentId:string}):Promise<{success:true,shipment:Shipment} | {success:false,error:string}>{
    try{
        const shipmentDocRef=doc(firestore,"Shipments",shipmentId);
        const shipmentDoc=await getDoc(shipmentDocRef);
        if(!shipmentDoc.exists()){
            throw new Error("Shipment does not exist");
        }
        const shipment:Shipment={
            ...shipmentDoc.data() as Shipment,
            id:shipmentDoc.id,
            dateTime:dayjs(shipmentDoc.data().dateTime.toDate()),   
        }
        return {success:true as const,shipment:shipment};
    }
    catch(err :any ){
        return {success:false as const ,error:err.message};
    }
}