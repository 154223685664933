import { Box, Button, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Slide, Step, StepContent, StepLabel, Stepper, Typography } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import styled from "styled-components";
import { HeadingStyle, SectionHeadingStyle, SubHeadingStyle, } from "../Styles/NewShipmentStyles";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from '@mui/icons-material/Delete';
import { TransitionProps } from "@mui/material/transitions";
import { TimePicker } from "@mui/x-date-pickers";
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import Dayjs from "dayjs";
import { ACTIONS, INITIAL_STATE, Movement, NewShipmentReducer, ShipmentForm as ShipmentFormType } from "../Logic/NewShipmentLogic";
import AddMovementDialog from "./AddMovementDialog";
import ShipmentFinalizationPage from "./ShipmentFinilizationPage";
import TruckCard from "./TruckCard";
export const TRUCKS_AND_CONTAINER_CAPACITY : {[truckType: string]: number} = {
  // "20ft Flatbed": 1,
  "40ft Flatbed": 2,
};
//TODO:: update the container to unit issue we have with CRF
export const CONTAINER_TO_UNIT: {[containerType: string]:{
  [empty:number]:number
}} = {
  "20GP": {
    0: 2,
    // 1: 1,
    1:2

  },
  "20HC": {
    0: 2,
    // 1: 1,
    1:2
  },
  "40GP": {
    0: 2,
    1: 2,
  },
  "40HC": {
    0: 2,
    1: 2,
  },
};
export const COMMODITIES=[
  "Agricultural Products" , "Building stone" , "Articles of metal" , "Basic chemicals" , "Chemical products and preparations" , "Coal" , "Crude petroleum" , "Diesel and other fuel oils" , "Electronics" , "Fertilizers" , "Furniture",
  "Gasoline, kerosene, and ethanol",
  "Gravel and crushed stone",
  "Machinery",
  "Rolled Aluminum",
  "Metallic ores",
  "Miscellaneous manufactured products",
  "Mixed freight/General",
  "Motorized and other vehicles",
  "Natural sands",
  "Nonmetallic mineral products",
  "Other fossil fuel products",
  "Other nonmetallic minerals",
  "Other transportation equipment",
  "Paper or paperboard articles",
  "Pharmaceutical products",
  "Plastics and rubber",
  "Precision instruments",
  "Printed products",
  "Textiles and leather",
  "Other"
  ];
export const mapOptions:google.maps.MapOptions={
  zoom: 8,
  center: {
      lat: 26.087545,
      lng: 50.549084
  },
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  draggable: true,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  gestureHandling: "greedy",
  disableDefaultUI: true,
  mapTypeId: "hybrid",
}

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShipmentForm({globalFormState,globalDispatch,isLoaded}: {globalFormState:ShipmentFormType,globalDispatch:Function,isLoaded:boolean}) {
  const [step, setStep] = React.useState<number>(0);


  const center = React.useMemo<{ lat: number; lng: number }>(
    () => ({
      lat: 26.087545,
      lng: 50.549084,
    }),
    []
  );
  const nextPage= React.useCallback(()=>{
    if(Dayjs(globalFormState.values.dateTime).isBefore(Dayjs().add(1,"day"))){
      alert("Shipment date/time cannot be in less than 24 hours");
      return;
    }
    if(globalFormState.values.trucks.length===0){
      alert("Please add atleast one truck");
      return;
    }
    for(let i=0;i<globalFormState.values.trucks.length;i++){
      if(globalFormState.values.trucks[i].type===""){
        alert(`Please select a truck type for truck ${i+1}`);
        return;
      }
      if(globalFormState.values.trucks[i].movements.length===0){
        alert(`Please add atleast one movement for truck ${i+1}`);
        return;
      }
    }
    setStep((prevStep)=>{return(prevStep+1)});

  },[globalFormState,step]);
  return(
    <>
    {step === 0 ? <ShipmentDetailsPage globalFormState={globalFormState} globalDispatch={globalDispatch} isLoaded={isLoaded} center={center} nextPage={nextPage} /> :  
    <ShipmentFinalizationPage globalFormState={globalFormState} globalDispatch={globalDispatch} isLoaded={isLoaded} center={center} setStep={setStep} />}
    </>
  )
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function ShipmentDetailsPage({globalFormState,globalDispatch,isLoaded,center,nextPage}:{globalFormState:ShipmentFormType,globalDispatch:Function,isLoaded:boolean,center:{lat:number,lng:number},nextPage:Function}) {
  const [addMovement, setAddMovement] = React.useState<boolean>(false);
  const [selectedTruck, setSelectedTruck] = React.useState<number | undefined>(
    undefined
  );
  return (
    <>
      <MainGrid>
        <FormDiv>
          <Typography style={{ ...SubHeadingStyle, marginBottom: "1rem" }} color="primary">
            Shipment Details
          </Typography>
          <DateTimeSection>
            <Typography style={SectionHeadingStyle}>Date & Time</Typography>
            <DateTimeDiv>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Shipment Date"
                  value={globalFormState.values.dateTime}
                  onChange={(newValue) => {
                    globalDispatch({
                      type: ACTIONS.SET_SHIPMENT_DATE_TIME,
                      payload: newValue,
                    });
                  }}
                  format="DD/MMM/YYYY"
                  minDate={Dayjs().add(1, "day")}
                />
                <TimePicker
                  label="Time"
                  value={globalFormState.values.dateTime}
                  onChange={(newValue) => {
                    globalDispatch({
                      type: ACTIONS.SET_SHIPMENT_DATE_TIME,
                      payload: newValue,
                    });
                  }}
                />
              </LocalizationProvider>
            </DateTimeDiv>
          </DateTimeSection>
          <TruckAdditionSection>
            <Typography style={SectionHeadingStyle}>Vehicles</Typography>
            <TrucksSection>
              {globalFormState.values.trucks.map((truck, index) => {
                return <TruckCard key={index} truck={truck} index={index} selectedTruck={selectedTruck} setSelectedTruck={setSelectedTruck} globalDispatch={globalDispatch} />;
              })}
            </TrucksSection>
            <Button
              variant="contained"
              style={{ width: "100%", height: "100%" }}
              endIcon={<AddIcon />}
              disabled={globalFormState.values.trucks.length >= 10}
              onClick={() => {
                globalDispatch({
                  type: ACTIONS.TRUCKS.ADD_TRUCK,
                });
              }}
            >
              add truck
            </Button>
          </TruckAdditionSection>

          <Button
            variant="contained"
            style={{ position: "absolute", bottom: "1rem", right: "1rem" }}
            onClick={() => {
              nextPage();
            }}
          >
            next
          </Button>
        </FormDiv>
        {selectedTruck !== undefined && (
          <DriverDetailsDiv>
            {isLoaded ? (
              <GoogleMap
                center={center}
                mapContainerStyle={{
                  width: "100%",
                  height: "20rem",
                  borderRadius: "10px 10px 0 0",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}
                options={mapOptions}
              >
                {selectedTruck !== undefined && globalFormState.values.trucks[selectedTruck].movements.length !== 0
                  ? globalFormState.values.trucks[selectedTruck].movements.map((movement, index) => {
                      return (
                        <MarkerF
                          key={index}
                          position={{
                            lat: movement.facility.coordinates.lat,
                            lng: movement.facility.coordinates.lng,
                          }}
                          clickable={false}
                          zIndex={index}
                          icon={{
                            path: google.maps.SymbolPath.CIRCLE,
                            scale: 10,
                            fillColor: "#1c2a57",
                            fillOpacity: 1,
                            strokeWeight: 0.4,
                          }}
                          label={{ text: `${(index + 1).toString()}`, color: "#fff" }}
                        />
                      );
                    })
                  : null}
              </GoogleMap>
            ) : (
              <div
                style={{
                  width: "100%",
                  height: "20rem",
                  borderRadius: "10px 10px 0 0",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                  backgroundColor: "lightGrey",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            <FormControl fullWidth>
              <InputLabel id="truck-type-label">Truck Type</InputLabel>
              <Select
                labelId="truck-type-label"
                id="truck-type"
                label="Truck Type"
                disabled={globalFormState.values.trucks[selectedTruck].movements.length !== 0}
                value={globalFormState.values.trucks[selectedTruck].type}
                onChange={(event) => {
                  globalDispatch({
                    type: ACTIONS.TRUCKS.SET_TYPE,
                    payload: {
                      index: selectedTruck,
                      type: event.target.value as string,
                    },
                  });
                }}
              >
                {Object.keys(TRUCKS_AND_CONTAINER_CAPACITY).map((truck) => {
                  return (
                    <MenuItem value={truck} key={truck}>
                      {truck}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {globalFormState.values.trucks[selectedTruck].type !== "" && (
              <MovementsDiv>
                <Box sx={{ maxWidth: 400 }}>
                  <Stepper activeStep={-1} orientation="vertical" nonLinear style={{ flexWrap: "wrap", columnGap: "0.5rem", maxWidth: 400 }}>
                    {globalFormState.values.trucks[selectedTruck].movements.map((movement: Movement, index) => (
                      <Step key={index} expanded active>
                        <StepLabel>
                          {movement.facility.name}{" "}
                          {movement.facility.selectedSequanceModifier ? (
                            <Typography color="primary" fontWeight="bold" fontSize="0.75rem">
                              {" "}
                              {`(${movement.facility.selectedSequanceModifier})`}
                            </Typography>
                          ) : null}
                        </StepLabel>
                        <StepContent>
                          <div style={{ display: "flex", gap: "0.5rem" }}>
                            {movement.containers.dropoff.length !== 0 && (
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <Typography key={index} variant="caption" fontWeight="bold">
                                  Drop-off
                                </Typography>
                                {movement.containers.dropoff.map((container, index) => {
                                  return (
                                    <Typography key={index} variant="caption" color="error">
                                      {" "}
                                      {container.CRFRef
                                        ? `Container W/ CRF ${container.CRFRef}`
                                        : container.BLRef
                                        ? container.confirmedSelection
                                          ? container.number
                                          : `Container W/ B/L ${container.BLRef}`
                                        : container.bulkRef
                                        ? container.confirmedSelection
                                          ? container.number
                                          : `Contianer W/ BULK ${container.bulkRef}`
                                        : container.number}
                                    </Typography>
                                  );
                                })}
                              </div>
                            )}
                            {movement.containers.pickup.length !== 0 && (
                              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                <Typography key={index} variant="caption" fontWeight="bold">
                                  Pickup
                                </Typography>
                                {movement.containers.pickup.map((container, index) => {
                                  return (
                                    <Typography key={index} variant="caption" color="green">
                                      {" "}
                                      {container.CRFRef
                                        ? `Container W/ CRF ${container.CRFRef}`
                                        : container.BLRef
                                        ? container.confirmedSelection
                                          ? container.number
                                          : `Container W/ B/L ${container.BLRef}`
                                        : container.bulkRef
                                        ? container.confirmedSelection
                                          ? container.number
                                          : `Contianer W/ BULK ${container.bulkRef}`
                                        : container.number}
                                    </Typography>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                          {index === globalFormState.values.trucks[selectedTruck].movements.length - 1 ? (
                            <IconButton
                              onClick={() => {
                                if (window.confirm("Are you sure you want to delete this movement")) {
                                  globalDispatch({
                                    type: ACTIONS.TRUCKS.MOVEMENT.POP_MOVEMENT,
                                    payload: {
                                      index: selectedTruck as number,
                                    },
                                  });
                                }
                              }}
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          ) : null}
                        </StepContent>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                <Button
                  variant="contained"
                  style={{
                    width: "calc(100% - 2rem)",
                    position: "absolute",
                    bottom: "1rem",
                  }}
                  onClick={() => {
                    setAddMovement(true);
                  }}
                >
                  add movement
                </Button>
              </MovementsDiv>
            )}
          </DriverDetailsDiv>
        )}
      </MainGrid>
      {selectedTruck !== undefined && (
        <AddMovementDialog
          open={addMovement}
          setOpen={setAddMovement}
          truck={globalFormState.values.trucks[selectedTruck as number]}
          onSubmit={async (movement: Movement) => {
            globalDispatch({
              type: ACTIONS.TRUCKS.MOVEMENT.ADD_MOVEMENT,
              payload: {
                index: selectedTruck as number,
                movement: movement,
              },
            });
          }}
        />
      )}
    </>
  );
}
const NewFacilityForm=styled.form`
display:flex;
flex-direction:column;
width:100%;
height:100%;
gap:1.3rem`;

const InlineInputs=styled.div`
display:flex;
flex-direction:row;
width:100%;
gap:1.3rem;
`;

const MovementsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  outline: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  `;
const LocalMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;
const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  gap: 2rem;
`;
const FormDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height:fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  gap: 2rem;
  padding-bottom: 5rem;
  position: sticky;
  top: 5rem;
`;

const DriverDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;
const DateTimeSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;
const DateTimeDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

const TruckAdditionSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;


const TrucksSection = styled.section`
  display: grid;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap:1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  `;