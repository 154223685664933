import React from "react";
import { FormikProps } from "formik";
import { Container } from "../Logic/NewShipmentLogic";
import { UploadFile } from "@mui/icons-material";
import { TextField, FormControl, InputLabel, Select, MenuItem, FormHelperText, FormControlLabel, Checkbox, InputAdornment, Autocomplete, Button, IconButton, ButtonGroup } from "@mui/material";
import { COMMODITIES } from "./ShipmentForm";
import CloseIcon from '@mui/icons-material/Close';
import styled from "styled-components";

export default function ContainerSection({containerForm,containerTypeOptions,hasToBeFull,hasToBeMovementType}:{containerForm:FormikProps<Container>,containerTypeOptions:string[],hasToBeFull?:boolean,hasToBeMovementType?:string}) {
    React.useEffect(() => {
        if(hasToBeMovementType!==undefined && hasToBeMovementType !==""){
          containerForm.setFieldValue("containerMovementType",hasToBeMovementType);
        }
    },[hasToBeMovementType]);
    return( <div
        style={{
          display: "grid",
          width: "100%",
          gap: "1rem",
          gridTemplateColumns: "1fr 1fr",
        }}
      >
        <TextField
          fullWidth
          required
          label="Container Number"
          value={containerForm.values.number}
          onChange={async (event) => {
            await containerForm.setFieldValue(
              "number",
              event.target.value.toUpperCase()
            );
            containerForm.setFieldTouched("number", true);
          }}
          onBlur={containerForm.handleBlur("number")}
          error={
            containerForm.touched.number &&
            Boolean(containerForm.errors.number)
          }
          helperText={
            containerForm.touched.number && containerForm.errors.number
          }
          inputProps={{
            style: { textTransform: "uppercase" },
            maxLength: 11,
          }}
        />
        <FormControl fullWidth style={{zIndex:1500}}>
          <InputLabel id="contianer-type-label" required>
            Type
          </InputLabel>
          <Select
               MenuProps={{
                style: {zIndex: 35001}
            }}
            required
            labelId="contianer-type-label"
            id="container-type"
            label="Type"
            value={containerForm.values.type}
            onChange={(event) => {
              containerForm.setFieldValue(
                "type",
                event.target.value as string
              );
            }}
            onBlur={containerForm.handleBlur("type")}
            error={
              containerForm.touched.type && Boolean(containerForm.errors.type)
            }
          >
            
            {containerTypeOptions.map((contianerType) => {
              return (
                <MenuItem value={contianerType} key={contianerType}>
                  {contianerType}
                </MenuItem>
              );
            })}

          </Select>
          {containerForm.touched.type &&
            Boolean(containerForm.errors.type) && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                {containerForm.errors.type}
              </FormHelperText>
            )}
        </FormControl>
        <ButtonGroup fullWidth style={{gridColumn:"1/3"}} disabled={hasToBeMovementType!==undefined && hasToBeMovementType !==""}>
          <Button onClick={()=>{
            containerForm.setFieldValue("containerMovementType","IMP");
            containerForm.handleBlur("containerMovementType");
            containerForm.setFieldValue("empty",false);

          }}
          variant={containerForm.values.containerMovementType==="IMP"?"contained":"outlined"}
          color={containerForm.touched.containerMovementType && Boolean(containerForm.errors.containerMovementType)? "error":"primary"}

          >Import</Button>
          <Button onClick={()=>{
            containerForm.setFieldValue("containerMovementType","EXP");
            containerForm.handleBlur("containerMovementType");
            containerForm.setFieldValue("empty",false);

          }}
          variant={containerForm.values.containerMovementType==="EXP"?"contained":"outlined"}
          color={containerForm.touched.containerMovementType && Boolean(containerForm.errors.containerMovementType)? "error":"primary"}

          >Export</Button>
            <Button onClick={()=>{
            containerForm.setFieldValue("containerMovementType","OTHR");
            containerForm.handleBlur("containerMovementType");
          }}
          variant={containerForm.values.containerMovementType==="OTHR"?"contained":"outlined"}
          color={containerForm.touched.containerMovementType && Boolean(containerForm.errors.containerMovementType)? "error":"primary"}
          >Other</Button>
        </ButtonGroup>
        <TextField
          fullWidth
          required={containerForm.values.containerMovementType === "EXP"}
          label="Booking Number"
          value={containerForm.values.bookingNumber}
          onChange={async (event) => {
            await containerForm.setFieldValue(
              "bookingNumber",
              event.target.value.toUpperCase()
            );
            containerForm.setFieldTouched("bookingNumber", true);
          }}
          onBlur={containerForm.handleBlur("bookingNumber")}
          error={
            containerForm.touched.bookingNumber &&
            Boolean(containerForm.errors.bookingNumber)
          }
          helperText={
            containerForm.touched.bookingNumber && containerForm.errors.bookingNumber
          }
          inputProps={{
            style: { textTransform: "uppercase" },
          }}
        />
{!hasToBeFull &&            <FormControlLabel
          style={{ gridColumn: "1 / 3" }}
          control={
            <Checkbox
              color="primary"
              checked={containerForm.values.empty}
              disabled={containerForm.values.containerMovementType === "IMP" ||containerForm.values.containerMovementType === "EXP" }
              onChange={async () => {
                await containerForm.setFieldValue(
                  "empty",
                  !containerForm.values.empty
                );
                await containerForm.setFieldValue("netWeight", 0);
                await containerForm.setFieldValue("sealNumber", "");
                containerForm.setFieldValue("commodity", "");
              }}
            />
          }
          label="Empty"
        />}
                    <TextField
          fullWidth
          disabled={containerForm.values.empty}
          label="Seal Number"
          value={containerForm.values.sealNumber}
          onChange={containerForm.handleChange("sealNumber")}
          onBlur={containerForm.handleBlur("sealNumber")}
          error={
            containerForm.touched.sealNumber &&
            Boolean(containerForm.errors.sealNumber)
          }
          helperText={
            containerForm.touched.sealNumber && containerForm.errors.sealNumber
          }
          inputProps={{
            style: { textTransform: "uppercase" },
          }}
          required={!containerForm.values.empty}
        />
        <TextField
          required={!containerForm.values.empty}
          type="number"
          fullWidth
          disabled={containerForm.values.empty}
          label="Net Weight"
          value={containerForm.values.netWeight.toString()}
          InputProps={{
            endAdornment: <InputAdornment position="end">kg</InputAdornment>,
          }}
          onChange={async (event) => {
            await containerForm.setFieldValue(
              "netWeight",
              event.target.value !== ""
                ? parseInt(event.target.value.toString().slice(0, 5))
                : 0
            );
            containerForm.setFieldTouched("netWeight", true);
          }}
          onBlur={containerForm.handleBlur("netWeight")}
          error={
            containerForm.touched.netWeight &&
            Boolean(containerForm.errors.netWeight)
          }
          helperText={
            containerForm.touched.netWeight && containerForm.errors.netWeight
          }
          inputProps={{
            min: 0,
            max: 40000,
            maxLength: 5,
          }}
        />
        <Autocomplete
          fullWidth
          disabled={containerForm.values.empty}
          options={COMMODITIES}
          isOptionEqualToValue={(option, value) =>
            value === undefined || value === "" || option === value
          }
          componentsProps={{
            paper:{
              style:{
                zIndex:1500
              }
            },
            popper:{
              style:{
                zIndex:1500
              }
            },
            popupIndicator:{
              style:{
                zIndex:1500
              }
            
            },
            clearIndicator:{
              style:{
                zIndex:1500
              }
            }
          }}
          value={
            containerForm.values.commodity
          }
          onChange={(e, v) => {
            containerForm.setFieldValue("commodity", v);
          }}
          onBlur={(e) => {
            containerForm.setFieldTouched("commodity", true);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={containerForm.values.empty}
              required={!containerForm.values.empty}
              variant="outlined"
              label="Commodity"
              
              error={containerForm.touched.commodity && Boolean(containerForm.errors.commodity)}
              helperText={containerForm.touched.commodity && containerForm.errors.commodity}
              FormHelperTextProps={{
                sx: { position: "absolute", bottom: "-1.3rem" },
              }}
            />
          )}
        />

        <div
          style={{
            gridColumn: "1 / 3",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "1rem",
          }}
        >
          <input
            accept="application/pdf"
            style={{ display: "none" }}
            id="documents"
            name="documents"
            type="file"
            multiple
            //TODO:: implement uplaod and remove disabled
            disabled
            onChange={(event) => {
              if (event.target.files?.length !== undefined) {
                let files = [...containerForm.values.documents];
                for (let i = 0; i < event.target.files.length; i++) {
                  if (event.target.files[i].type === "application/pdf") {
                    files.push(event.target.files[i]);
                  }
                }
                containerForm.setFieldValue("documents", files);
              }
            }}
          />
          <label htmlFor="documents">
            <Button
              variant="outlined"
              style={{
                width: "100%",
                alignItems: "start",
                marginTop: "0.5rem",
                height: "3rem",
              }}
              component="span"
              color="primary"
              //TODO:: implement uplaod and remove disabled
              disabled
            >
              <FileUploadButtonDiv>
                <UploadFile />
                upload documents
              </FileUploadButtonDiv>
            </Button>
          </label>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gridColumn: "1 / 3",
            width: "100%",
            gap: "0.5rem",
            maxHeight: "15rem",
            overflowY: "scroll",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
        >
          {containerForm.values.documents.map((file, index) => {
            return (
              <div
                key={index}
                style={{
                  backgroundColor: "lightGrey",
                  color: "black",
                  padding: "0.2rem",
                  borderRadius: "0.2rem",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <> {file.name}</>
                <IconButton
                  style={{ color: "black" }}
                  onClick={() => {
                    let files = [...containerForm.values.documents];
                    files.splice(index, 1);
                    containerForm.setFieldValue("documents", files);
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
            );
          })}
        </div>
      </div>)
}
const FileUploadButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  align-items: center;
`;