import { Outlet, Navigate } from "react-router-dom";
import Loading from "../Pages/Loading/Loading";
import { AuthContextValue, useAuth } from "../Contexts/AuthContext";
export default function UnauthenticatedRoutes() {
  const authContext:AuthContextValue=useAuth();    
  return (
    <>
    {
      authContext.authLoading ? <Loading /> :
      authContext.currentUser ?  <Navigate to="/Dashboard" /> : <Outlet />
    }
    </>
    ) ;
}
