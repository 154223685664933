import styled from "styled-components";
import DeleteIcon from '@mui/icons-material/Delete';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { Typography, IconButton } from "@mui/material";
import { Truck, ACTIONS } from "../Logic/NewShipmentLogic";

export default function TruckCard({truck,index,selectedTruck,setSelectedTruck,globalDispatch}:{truck:Truck,index:number,selectedTruck:number|undefined,setSelectedTruck:Function,globalDispatch:Function}){
    return(
        <TruckCardDiv onClick={()=>{setSelectedTruck(index)}} selected={selectedTruck===index} style={{position:"relative"}}>
            <LocalShippingIcon  sx={{fontSize:"2rem"}}/>
            <TruckCardDetails>
                <Typography style={{fontWeight:700,fontSize:"1rem",height:"100%"}} >{truck.type?truck.type:"Edit Trcuk Details"}</Typography>
                {truck.movements.length!==0? truck.movements.length+" movements":null}
                <IconButton style={{position:"absolute",right:0,bottom:0}} onClick={(e)=>{
                  e.stopPropagation();
                  if(window.confirm(`Are you sure you want to delete truck number ${index+1}`)){
                    if (selectedTruck===index){
                      setSelectedTruck(undefined);
                    }
                    globalDispatch({
                      type:ACTIONS.TRUCKS.REMOVE_TRUCK,
                      payload:{
                        index:index
                      }
                    })

                  }

                }}><DeleteIcon color="error"/></IconButton>
            </TruckCardDetails>
        </TruckCardDiv>
    )
}
const TruckCardDiv = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#1c2a57" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  &:hover {
    background-color: ${(props)=>(props.selected?"rgb(19, 29, 60)":"#EDEDED")};
  }
`;
const TruckCardDetails=styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  `;