import Box from '@mui/material/Box';
import { styled as muiStyled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import React, { useMemo } from 'react';
import gpsArrow from "../../Assets/SVG/gps-arrow.svg";
import NavDrawer from "../globalComponents/Navigation/Navigation";

import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import Loading from '../Loading/Loading';
// import { Shipment } from '../../Models/Shipments';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditLocationAltIcon from '@mui/icons-material/EditLocationAlt';
import ForkRightIcon from '@mui/icons-material/ForkRight';
import InfoIcon from '@mui/icons-material/Info';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PersonIcon from '@mui/icons-material/Person';
import SportsScoreIcon from '@mui/icons-material/SportsScore';
import { Alert, Button, Card, CardActionArea, CardActions, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slide, Snackbar, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import dayjs from 'dayjs';
import { onValue, ref } from 'firebase/database';
import { GeoPoint, Timestamp, collection, doc, onSnapshot, orderBy, query, runTransaction, where } from 'firebase/firestore';
import styled from 'styled-components';
import { useAuth } from '../../Contexts/AuthContext';
import { firestore, realTimeDB } from '../../Firebase/Firebase';
import { FirebaseMovement, Shipment, ShipmentDoc, addNewMovement, endShipment } from '../../Firebase/Firestore/Shipments';
import { Container, Movement, Truck } from '../POSTGITEXT-NewShipment/Logic/NewShipmentLogic';
import { DrawerHeader } from '../POSTGITEXT-NewShipment/Styles/NewShipmentStyles';
import onlineSound from "../../Assets/Audio/online.mp3";
import offlineSound from "../../Assets/Audio/offline.mp3";
import { useNavigate, useParams } from 'react-router-dom';
import AddMovementDialog from '../POSTGITEXT-NewShipment/Components/AddMovementDialog';
import { act } from 'react-dom/test-utils';

const mapStyling = [
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "poi.business",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "transit",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  }
];
const mapOptions: google.maps.MapOptions = {
  zoom: 10,
  center: {
    lat: 25.987545,
    lng: 50.549084
  },
  clickableIcons: false,
  fullscreenControl: false,
  mapTypeControl: false,
  streetViewControl: false,
  zoomControl: false,
  draggable: true,
  scrollwheel: true,
  disableDoubleClickZoom: true,
  gestureHandling: "greedy",
  disableDefaultUI: true,
  styles: mapStyling,
  mapTypeId: "hybrid",

}
export type LiveTruckDoc={
  heading:number,
  latitude:number,
  longitude:number,
  timestamp:number,
}
const libraries = ["places"] as ["places"]; //used for adding new facility

export default function Tracking() {
  const {URLShipment}=useParams();
  const navigate=useNavigate();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [shipments, setShipments] = React.useState<Shipment[]>([]);
  const [selectedShipment, setSelectedShipment] = React.useState<Shipment | null>(null);
  const [follow, setFollow] = React.useState<string>("");
  //TODO:: do not load live truck if the driver didn't log on yet (they are being rendered at 0,0)
  const [liveTrucks, setLiveTrucks] = React.useState<{[truckTrackingId:string]:LiveTruckDoc}>({});
  const [liveTrucksOnlineStatus, setLiveTrucksOnlineStatus] = React.useState<{[truckId:string]:{status:string , timestamp:number}}>({});
  const [showRoadbanBanner,setShowRoadbanBanner]=React.useState<boolean>(false);
  const { currentUser } = useAuth();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_CLOUD_API_KEY as string,
    libraries: libraries,
  });
  const mapRef = React.useRef<any>(null);
  const center = React.useMemo<{ lat: number; lng: number }>(
    () => ({
      lat: 26.087545,
      lng: 50.549084,
    }),
    []
  );

  const onlineAudioRef=React.useRef<HTMLAudioElement>(null);
  const offlineAudioRef=React.useRef<HTMLAudioElement>(null);

  
  React.useEffect(() => {
    const q = query(collection(firestore, "Shipments"), where("shipperID", "==", currentUser?.uid), where("status", ">", 0), where("status", "<", 3), orderBy("status", "asc"), orderBy("dateTime", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const shipments: Shipment[] = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data(), "doc.data()");
        console.log(doc.id, "doc.id");
        const shipment: Shipment = {
          ...(doc.data() as ShipmentDoc & {
            assignedTrucks: {
              [key: string]: string;
            };
          }),
          id: doc.id,
          dateTime: dayjs(doc.data().dateTime.toDate()),
        };
        shipments.push(shipment);
      });
      setShipments(shipments);
    });
    return unsubscribe;
  }, []);
  React.useEffect(() => {
    if(URLShipment && shipments.length!==0){
      const shipment=shipments.find((shipment)=>shipment.id===URLShipment);
      if(shipment){
        setSelectedShipment(shipment);
      }else{
        alert("Shipment not found");
        navigate("/Tracking");
      }
    }
    else{
      setSelectedShipment(null);
    }
    
  }, [URLShipment,shipments]);
  React.useEffect(() => {
    //TODO:: check if a shipment has been removed from the list of shipments and remove its listeners
    const listeners: Function[] = [];
    const liveStatusListeners: Function[] = [];
    shipments.forEach((shipment) => {
      Object.keys(shipment.assignedTrucks).forEach((truckID) => {
        if (!liveTrucks[truckID]) {
          const unsub = onValue(ref(realTimeDB, `/tracking/${truckID}`), (snapshot) => {
            if (snapshot.exists()) {
              console.log(snapshot.val(), "snapshot.val()");
              setLiveTrucks((prev: any) => ({
                ...prev,
                [truckID]: snapshot.val(),
              }));
            }
          });
          listeners.push(unsub);

          const liveStatusUnsub = onValue(ref(realTimeDB, `/userStatus/${truckID}`), (snapshot) => {
            if (snapshot.exists()) {
              console.log(snapshot.val(), "snapshot.val()");
              if(snapshot.val().status==="online"){
                onlineAudioRef.current?.play().catch((error)=>{console.log(error)})
              }
              else{
                offlineAudioRef.current?.play().catch((error)=>{console.log(error)});
              }
              setLiveTrucksOnlineStatus((prev) => {
                return {...prev,  [truckID]: snapshot.val() };
              });
            }
          });
          liveStatusListeners.push(liveStatusUnsub);
        }
      });
    });
    return () => {
      listeners.forEach((unsub) => {
        unsub();
      });
      liveStatusListeners.forEach((unsub) => {
        unsub();
      });
    };
  }, [shipments]);
  React.useEffect(() => {
    if (mapRef.current !== null) {
      if (follow) {
        console.log("live trucks",liveTrucks);
        console.log("follow",follow)
        if(!liveTrucks[follow].latitude || !liveTrucks[follow].longitude) {return}
        mapRef.current.panTo({ lat: liveTrucks[follow].latitude, lng: liveTrucks[follow].longitude });
        mapRef.current.setZoom(18);
        //remove user control of map
        mapRef.current.setOptions({
          gestureHandling: "none",
        });
      } else {
        mapRef.current.setOptions({
          gestureHandling: "greedy",
        });
      }
    }
  }, [liveTrucks, follow]);

  React.  useEffect(() => {
    const calculateTimeout = ({startHour,startMinute,endHour,endMinute}:{startHour:number,startMinute:number,endHour:number,endMinute:number}) => {
      const now = new Date();
      const currentHour = now.getHours();
      const currentMinute = now.getMinutes();

      // Calculate the next time to show or hide the banner
      let nextCheck;
      if (
        (currentHour > startHour || (currentHour === startHour && currentMinute >= startMinute)) &&
        (currentHour < endHour || (currentHour === endHour && currentMinute < endMinute))
      ) {
        // The banner is currently shown, so we need to hide it at endHour:endMinute
        setShowRoadbanBanner(true);
        nextCheck = new Date(now.getFullYear(), now.getMonth(), now.getDate(), endHour, endMinute, 0);
      } else {
        // The banner is currently hidden, so we need to show it at startHour:startMinute
        setShowRoadbanBanner(false);
        if (currentHour > endHour || (currentHour === endHour && currentMinute >= endMinute)) {
          // If it's past the end time, set the next check for tomorrow's start time
          nextCheck = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, startHour, startMinute, 0);
        } else {
          // Otherwise, set the next check for today's start time
          nextCheck = new Date(now.getFullYear(), now.getMonth(), now.getDate(), startHour, startMinute, 0);
        }
      }

      // Calculate the timeout duration in milliseconds
      const timeoutDuration = nextCheck.getTime() - now.getTime();
      return timeoutDuration;
    };
    const periods=[
      {startHour:6,startMinute:0,endHour:8,endMinute:0},
      {startHour:14,startMinute:0,endHour:15,endMinute:0},
    ]
    let timeoutDuration=0;
    periods.forEach((period)=>{
      const temp=calculateTimeout(period);
      if(temp<timeoutDuration || timeoutDuration===0){
        timeoutDuration=temp;
      }
    })
    // Set a timeout to update the banner visibility at the calculated time
    const timeout = setTimeout(() => {
      setShowRoadbanBanner((prevShowBanner) => !prevShowBanner);
    }, timeoutDuration);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timeout);
  }, [showRoadbanBanner]);

  return (
    <>
      {isLoaded ? (
        <MainContainer>
          <audio ref={onlineAudioRef} src={onlineSound} />
          <audio ref={offlineAudioRef} src={offlineSound} />
          <NavDrawer />
          <Box component="main" sx={{ flexGrow: 1, overflowY: "hidden", height: "100vh", }}>
            <Snackbar
              open={follow !== ""}
              message={`Currently Following ${follow}`}
              action={
                <Button
                  size="small"
                  color="error"
                  onClick={() => {
                    setFollow("");
                  }}
                >
                  stop
                </Button>
              }
            />
            <Snackbar
            open={showRoadbanBanner}
            anchorOrigin={{vertical:"top",horizontal:"center"}}
            style={{top:"4.5rem"}}
            
            >
              <Alert
              severity='error'
              variant='filled'
              >
                Road ban in effect

                </Alert>
              </Snackbar>

            <DrawerHeader />
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 2 }} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "flex-start", width: "100%", height: "100%" }}>
              <GoogleMap
                center={center}
                mapContainerStyle={{ width: "100%", minHeight: "100svh" }}
                options={mapOptions}
                ref={mapRef}
                onLoad={(map) => {
                  mapRef.current = map;
                }}
              >
                {liveTrucks &&
                  Object.keys(liveTrucks).map((truckID) => {
                    return (
                      <MarkerF
                        position={{ lat: liveTrucks[truckID].latitude, lng: liveTrucks[truckID].longitude }}

                        clickable={true}
                        onClick={() => {
                          setFollow(truckID);
                        }}
                        key={truckID}
                        // icon={{
                        //   url: gpsArrow,
                        //   scaledSize: new google.maps.Size(50, 50),
                        //   origin: new google.maps.Point(0, 0),
                        //   labelOrigin: new google.maps.Point(25, 50),
                        //   rotation: liveTrucks[truckID].heading,
                        //   //labelSize

                        // }}
                        icon={{
                          path: google.maps.SymbolPath.CIRCLE,
                          scale: 8,
                          rotation: liveTrucks[truckID].heading,
                          fillColor: "#1c2a57",
                          fillOpacity: 1,
                          strokeWeight: 2,
                          strokeColor: "#1c2a57",
                          labelOrigin: new google.maps.Point(0, 3),
                        }}
                        label={{
                          text: truckID,
                          color: "#1c2a57",
                          fontSize: "0.8rem",
                          fontWeight: "bold",
                          
                        }}


                      />
                    );
                  })}
              </GoogleMap>
            </motion.div>
            <div style={{
              position: "absolute",
              right: 0,
              top: "64px",
              height: "100svh",
              overflowY: "scroll",
              width: "20rem",
              backgroundColor: "white",
              zIndex: "10000",
              boxShadow: "#4242427a -14px 0px 15px 0px",
              paddingBottom:"10rem"
            }}>
            <div
              style={{

                height:"fit-content",
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              {shipments.length > 0 ? (
                shipments.map((shipment) => {
                  return (
                    <ShipmentCard shipment={shipment} setSelectedShipment={setSelectedShipment} key={shipment.id} />
                  );
                })

              ) : (
                <Typography variant="h6" component="h6" width="100%" textAlign="center" color="error">
                  No Currently Active Shipments
                </Typography>
              )}
            </div>
            </div>
          </Box>
          {selectedShipment && <SelectedShipmentSection selectedShipment={selectedShipment} setSelectedShipment={setSelectedShipment} follow={follow} setFollow={setFollow} liveTrucksOnlineStatus={liveTrucksOnlineStatus} />}

        </MainContainer>
      ) : (
        <Loading fullscreen />
      )}
    </>
  );
}

function ShipmentCard({ shipment, setSelectedShipment }: { shipment: Shipment, setSelectedShipment: Function }) {
  const navigate=useNavigate();

  return (
    <Card onClick={() => {
      // setSelectedShipment(shipment);
      navigate(`/Tracking/${shipment.id}`);
      
    }}
    style={{height:"fit-content"}}
    >
      <CardActionArea>
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {shipment.id.toUpperCase()}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.2rem", marginBottom: "0.2rem" }}>
            <AccessTimeIcon fontSize='small' /> {shipment.dateTime.format("DD/MM/YYYY HH:mm")}
          </Typography>
          <Typography variant="body2" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
            <LocalShippingIcon fontSize='small' /> X {shipment.trucks.length}
          </Typography>
          <ArrowForwardIosIcon style={{ position: "absolute", right: "1rem", bottom: "1rem", color: "#00000099" }} />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
function SelectedShipmentSection({ selectedShipment, setSelectedShipment, follow, setFollow ,liveTrucksOnlineStatus }: { selectedShipment: Shipment, setSelectedShipment: Function, follow: string, setFollow: Function ,liveTrucksOnlineStatus:{[truckId:string]:{status:string , timestamp:number}}}) {
  const [trackingDocs, setTrackingDocs] = React.useState<{ [id: string]: (baseTrackingDocWithID | incomingTrackingDocWithID) }>({}); //TODO:: add tracking doc types
  const navigate=useNavigate();

  React.useEffect(() => {
    setTrackingDocs({});
  }, [selectedShipment]);
  React.useEffect(() => {
    let listeners: Function[] = [];
    if (selectedShipment) {
      Object.keys(selectedShipment.assignedTrucks).forEach((truckID) => {
        const unsub = onSnapshot(doc(firestore, "Tracking", truckID), (doc) => {
          if (doc.exists()) {
            console.log(doc.data(), "doc.data()");
            console.log(doc.id, "doc.id");
            if (doc.data().hasOwnProperty("carrierId")) {
              setTrackingDocs((prev) => {
                return { ...prev, [doc.id]: { ...doc.data() as incomingTrackingDoc, id: doc.id } };
              });
            } else {
              setTrackingDocs((prev) => {
                return { ...prev, [doc.id]: { ...doc.data() as baseTrackingDoc, id: doc.id } };
              });
            }
          }
        });
        listeners.push(unsub);
      });
    }
    return () => {
      listeners.forEach((unsub) => {
        unsub();
      });
    };
  }, [selectedShipment]);

  return (
    <motion.div
      variants={{
        open: { opacity: 1, zIndex: 10000 },
        closed: { opacity: 0, zIndex: -1 },
      }}
      initial="close"
      animate="open"
      transition={{ duration: 1 }}
      style={{
        position: "absolute",
        right: 0,
        top: "64px",
        backgroundColor: "white",
        height: "100svh",
        width: "25rem",
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        boxShadow: "#4242427a -14px 0px 15px 0px",
        gap: "1rem",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          onClick={() => {
            // setSelectedShipment(null);
            navigate("/Tracking");
          }}
        >
          <ArrowBackIosIcon />
        </IconButton>

        <Typography variant="h6" component="div">
          {selectedShipment.id.toUpperCase()}
        </Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
        <Typography variant="body2" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.2rem" }}>
          <LocalShippingIcon fontSize="small" /> X {selectedShipment.trucks.length}
        </Typography>

        <Typography variant="body2" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.2rem", marginBottom: "0.2rem" }}>
          <AccessTimeIcon fontSize="small" /> {selectedShipment.dateTime.format("DD/MM/YYYY HH:mm")}
        </Typography>
      </div>
      <Button
        variant="contained"
        color="error"
        startIcon={<SportsScoreIcon />}
        onClick={() => {
          if (window.confirm("Are you sure you want to end this shipment?")) {
            console.log("trackingDocs ", trackingDocs);
            const allTrucksEmpty = Object.keys(trackingDocs).every((truckId) => {
              return (trackingDocs[truckId] as incomingTrackingDocWithID).currentLoad === undefined ? true : (trackingDocs[truckId] as incomingTrackingDocWithID).currentLoad?.length === 0;
            });
            const allTrucksStarted = Object.keys(trackingDocs).every((truckId) => {
              return (trackingDocs[truckId] as incomingTrackingDocWithID).status > 0;
            });
            if (!allTrucksStarted && !window.confirm("Some trucks have not started, are you sure you want to end this shipment?")) {
              return;
            }
            if (!allTrucksEmpty && !window.confirm("Some trucks are not empty, are you sure you want to end this shipment?")) {
              return;
            }
            endShipment({ shipmentId: selectedShipment.id }).then((resp) => {
              if (!resp.success) {
                alert(resp.error);
              } else {
                // setSelectedShipment(null);
                navigate("/Tracking");
              }
            });
          }
        }}
      >
        end shipment
      </Button>
      <Divider />
      <div style={{height:"100%",overflowY:"scroll",paddingBottom:"10rem"}}>
        <div style={{height:"fit-content",gap:"1rem",display:"flex",flexDirection:"column"}}>
      {Object.keys(trackingDocs).map((docId) => {
        return <TruckCard truck={trackingDocs[docId]} onlineStatus={liveTrucksOnlineStatus[docId]} follow={follow} setFollow={setFollow} selectedShipment={selectedShipment} key={docId} />;
      })}
      </div>
      </div>

    </motion.div>
  );
}

function TruckCard({ truck,onlineStatus, follow, setFollow, selectedShipment }: { truck: (incomingTrackingDocWithID | baseTrackingDocWithID), onlineStatus:{status:string,timestamp:number} | undefined ,setFollow: Function, follow: string, selectedShipment: Shipment }) {
  const [editTrip, setEditTrip] = React.useState<string>("");
  const [movements, setMovements] = React.useState<FirebaseMovement[]>([]);
  const [currentMovement, setCurrentMovement] = React.useState<FirebaseMovement | null>(null);
  React.useEffect(() => {
    const q=query(collection(firestore, "Tracking", truck.id, "Movements"), orderBy("index", "asc"));
    const unsub = onSnapshot(q, (querySnapshot) => {
      const movements: FirebaseMovement[] = [];
      querySnapshot.forEach((doc) => {
        console.log(doc.data(), "doc.data()");
        console.log(doc.id, "doc.id");
        let movement: FirebaseMovement = {
          ...(doc.data() as FirebaseMovement),docId:doc.id};
        movements.push(movement);
      });
      setMovements(movements);
    });
    return unsub;
  }, []);
  React.useEffect(() => {
    if(movements.length===0){
      setCurrentMovement(null);
      return;
    }
    let localCurrentMovementIndex=movements.findIndex((movement)=>!movement.completed) as number;
    if(localCurrentMovementIndex===-1){
      //all movements complete
      localCurrentMovementIndex=movements.length-1;
    }

      setCurrentMovement(movements[localCurrentMovementIndex]);
  }, [movements]);
  return (
    <>
      {truck.status >= 1 ? (
        <Card style={{height:"fit-content"}}>
          <CardContent style={{ display: "flex", flexDirection: "column", gap: "0.3rem" ,height:"fit-content"}}>
            <Typography variant="h6" component="h6" fontSize="1rem">
              {truck.id}
              {onlineStatus?.status==="online" ? <Typography variant="caption" color="green" fontWeight="bold" component="span" style={{marginLeft:"0.5rem"}}>online</Typography>:<Typography variant="caption" color="error" fontWeight="bold" component="span" style={{marginLeft:"0.5rem"}}>offline</Typography>}
            </Typography>
            <Divider style={{ marginBottom: "0.2rem" }} />
            <Typography variant="body1" component="div" fontSize="0.8rem" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.1rem" }}>
              <PersonIcon fontSize="small" /> {(truck as incomingTrackingDocWithID).driverName}
            </Typography>
            <Typography variant="body1" component="div" fontSize="0.8rem" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.1rem",textDecoration:"underline",cursor:"pointer" }}
            onClick={()=>{
              (truck as incomingTrackingDocWithID).driverMobile.startsWith("00") || (truck as incomingTrackingDocWithID).driverMobile.startsWith("+")?window.open(`https://api.whatsapp.com/send?phone=${(truck as incomingTrackingDocWithID).driverMobile}&text=%20`,"_blank")
              :window.open(`https://api.whatsapp.com/send?phone=+973${(truck as incomingTrackingDocWithID).driverMobile}&text=%20`,"_blank")
            }}
            >
              <LocalPhoneIcon fontSize="small" /> {(truck as incomingTrackingDocWithID).driverMobile}
            </Typography>

            <Typography variant="body1" component="div" fontSize="0.8rem" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.1rem" }}>
              <ForkRightIcon fontSize="small" />{" "}
              {currentMovement && !currentMovement.completed && currentMovement.facility.name ? (
                currentMovement.facility.name
              ) : (
                <Typography color="error" fontSize="0.8rem" fontWeight="bold">
                  IDLE - (Add movement or End shipment)
                </Typography>
              )}
            </Typography>

            <Typography variant="body1" component="div" fontSize="0.8rem" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.1rem" }}>
              <LineWeightIcon fontSize="small" />{" "}
              {(truck as incomingTrackingDocWithID).currentLoad?.length !== 0 ? (
                (truck as incomingTrackingDocWithID).currentLoad?.map((container)=>{return container.number}).join(", ")
              ) : (
                <Typography fontSize="0.8rem" fontWeight="bold" textTransform="uppercase">
                  Empty
                </Typography>
              )}
            </Typography>

            <Typography variant="body1" component="div" fontSize="0.8rem" color="text.secondary" style={{ display: "flex", alignItems: "center", gap: "0.1rem" }}>
              <InfoIcon fontSize="small" />{" "}
              {currentMovement && currentMovement?.actionLog && currentMovement?.actionLog?.length !== 0 ? (
                `${currentMovement.actionLog?.at(-1)?.action} ${currentMovement.actionLog?.at(-1)?.container ? ` ${currentMovement.actionLog?.at(-1)?.container?.number}` : ""} ${currentMovement.facility.name} @ ${currentMovement.actionLog
                  ?.at(-1)
                  ?.dateTime.toDate()
                  .toLocaleTimeString()}`
              ) : currentMovement?.index === 0 ? (
                <Typography color="error" fontSize="0.8rem" fontWeight="bold" textTransform="uppercase">
                  No recorded actions
                </Typography>
              ) : (
                `${movements.at((currentMovement?.index as number) - 1)?.actionLog?.at(-1)?.action} ${movements.at((currentMovement?.index as number) - 1)?.actionLog?.at(-1)?.container ? ` ${movements.at((currentMovement?.index as number) - 1)?.actionLog?.at(-1)?.container}` : ""} ${movements.at((currentMovement?.index as number) - 1)?.facility.name} @ ${movements.at((currentMovement?.index as number) - 1)?.actionLog?.at(-1)
                  ?.dateTime.toDate()
                  .toLocaleTimeString()}`
              )}
            </Typography>
          </CardContent>
          <CardActions>
            <Button startIcon={<MyLocationIcon />}
              variant='contained'
              color={follow === truck.id ? "error" : "primary"}
              onClick={() => {
                if (follow !== truck.id) {
                  setFollow(truck.id);
                } else {
                  setFollow("");
                }
              }}>{follow !== truck.id ? "Follow" : "Stop Following"}</Button>

            <Button onClick={() => {
              setEditTrip(truck.id);
            }} startIcon={<EditLocationAltIcon />}>
              trip details
            </Button>
          </CardActions>
        </Card>
      ) : (
        <Card>
          <CardContent>
            <Typography variant="h6" component="h6" fontSize="1rem">
              {truck.id}
            </Typography>
            <Divider />
            <Typography variant="body1" component="div" fontSize="0.8rem" color="error" style={{ marginTop: "0.5rem" }}>
              Pneding Driver Login</Typography>
          </CardContent>
        </Card>
      )}
      <EditTripDialog editTrip={editTrip} setEditTrip={setEditTrip} selectedShipment={selectedShipment} truck={truck as incomingTrackingDocWithID} movements={movements}/>
    </>
  );
}
function EditTripDialog({ editTrip, setEditTrip, selectedShipment, truck,movements }: { editTrip: string, setEditTrip: Function, selectedShipment: Shipment, truck: incomingTrackingDocWithID,movements:FirebaseMovement[] }) {
  const [openAddMovementDialog, setOpenAddMovementDialog] = React.useState<boolean>(false);
  const [mergingMovements,setMergingMovements]=React.useState<boolean>(false);
  const currentLoad=useMemo<Container[]>(()=>{
      let currentLoad: Container[] = [];
        movements.forEach((movement) => {
          if(currentLoad.length!==0 && movement.containers.dropoff.length!==0){
            movement.containers.dropoff.forEach((dropoffContainer)=>{
              const index=currentLoad.findIndex((container)=>container.number===dropoffContainer.number && container.BLRef===dropoffContainer.BLRef && container.CRFRef===dropoffContainer.CRFRef && container.type===container.type)
              if(index!==-1){
                currentLoad.splice(index,1);
              }
            })
          }
          if(movement.containers.pickup.length!==0){
            currentLoad=[...currentLoad,...movement.containers.pickup];
          }
          // //add the picked up containers to the current load
          // currentLoad = [...currentLoad, ...movement.containers.pickup];
          // //remove the dropped off containers from the current load
          // currentLoad = currentLoad.filter((container) => {
          //   return container.number !== movement.containers.dropoff.find((dropoffContainer) => { return dropoffContainer.number === container.number })?.number;
          // })
        })
        return currentLoad;
  },[movements,selectedShipment,truck])
  function mergeMovements(trackingDocID:string,movement1ID:string,movement2ID:string){
    runTransaction(firestore,async(transaction)=>{
      const doc1=await transaction.get(doc(firestore,"Tracking",`${trackingDocID}/Movements/${movement1ID}`))
      const doc2=await transaction.get(doc(firestore,"Tracking",`${trackingDocID}/Movements/${movement2ID}`))
      const data1={...doc1.data(),docId:doc1.id} as FirebaseMovement;
      const data2={...doc2.data(),docId:doc2.id} as FirebaseMovement;
      if((data1.actionLog && data1.actionLog.length!==0) || (data2.actionLog && data2.actionLog.length!==0)){
        alert("Cannot merge movements that are already started");
        return;
      }
      const mergedPickup=data1.containers.pickup.concat(data2.containers.pickup);
      const mergedDropoff=data1.containers.dropoff.concat(data2.containers.dropoff);
      if(data1.index<data2.index){
        transaction.update(doc(firestore,"Tracking",`${trackingDocID}/Movements/${movement1ID}`),{containers:{pickup:mergedPickup,dropoff:mergedDropoff}});
        transaction.delete(doc(firestore,"Tracking",`${trackingDocID}/Movements/${movement2ID}`));
      }else{
        transaction.update(doc(firestore,"Tracking",`${trackingDocID}/Movements/${movement2ID}`),{containers:{pickup:mergedPickup,dropoff:mergedDropoff}});
        transaction.delete(doc(firestore,"Tracking",`${trackingDocID}/Movements/${movement1ID}`));
      }
    }).finally(()=>{
      setMergingMovements(false);
    })
  }
  return (
    <>
      <Dialog
        open={editTrip !== ""}
        onClose={() => {
          setEditTrip("");
        }}
      >
        <DialogTitle>Trip Details</DialogTitle>
        <Divider />
        <DialogContent style={{ minWidth: "35rem", maxHeight: "30rem", overflowY: "scroll" }}>
          <Stepper activeStep={-1} orientation="vertical" nonLinear style={{ columnGap: "0.5rem" }}>
            {movements.map((movement: FirebaseMovement, index) => (
              <Step key={index} expanded active={!movement.completed}>
                          <StepLabel>
                            {movement.facility.name} {movement.facility.selectedSequanceModifier? <Typography color="primary" fontWeight="bold" fontSize="0.75rem"> {`(${movement.facility.selectedSequanceModifier})`}</Typography> :null}{movement.TASAppointment? <Typography color="#ff6441"  fontSize="0.7rem" style={{display:"flex",alignItems:"center",gap:"0.2rem",margin:"0.2rem 0 0.2rem 0"}}> <AccessTimeIcon style={{fontSize:"0.8rem"}}/>{`TAS Apt: ${movement.TASAppointment.ref} @ ${dayjs(movement.TASAppointment.dateTime.toDate()).format('HH:mm DD/MM/YYYY')}`}</Typography> :null}
                          </StepLabel>                <StepContent>
                  <div style={{ display: "flex", gap: "0.5rem" }}>
                    {movement.containers.dropoff.length !== 0 && (
                      <div style={{ display: "flex", flexDirection: "column" ,width:"10rem" }}>
                        <Typography key={index} variant="caption" fontWeight="bold">
                          Drop-off
                        </Typography>
                        {movement.containers.dropoff.map((container, index) => {
                          return (
                            <Typography key={index} variant="caption" color="error" component="div" style={{display:"flex",flexDirection:"column"}}>
                            {/* {container.confirmedSelection || container.confirmedByDriver ? ` ${container.number}` : container.BLRef ? `Container W/ B/L ${container.BLRef}` : `Container w/ CRF ${container.CRFRef}`} */}
                            {(container.confirmedSelection || container.confirmedByDriver) && container.number? container.number : "No Container Number"}
                            <Typography color="grey" variant='caption' fontSize="0.7rem">{container.BLRef ? `B/L: ${container.BLRef}`: container.CRFRef?`CRF: ${container.CRFRef}`:""}</Typography>
                            </Typography>
                          );
                        })}
                      </div>
                    )}
                    {movement.containers.pickup.length !== 0 && (
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start",width:"10rem" }}>
                        <Typography key={index} variant="caption" fontWeight="bold">
                          Pickup
                        </Typography>
                        {movement.containers.pickup.map((container, index) => {
                          return (
                            <Typography key={index} variant="caption" color="green" component="div" style={{display:"flex",flexDirection:"column"}}>
                            {/* {container.confirmedSelection || container.confirmedByDriver ? ` ${container.number}` : container.BLRef ? `Container W/ B/L ${container.BLRef}` : `Container w/ CRF ${container.CRFRef}`} */}
                            {(container.confirmedSelection || container.confirmedByDriver) && container.number? container.number : "No Container Number"}
                            <Typography color="grey" variant='caption' fontSize="0.7rem">{container.BLRef ? `B/L: ${container.BLRef}`: container.CRFRef?`CRF: ${container.CRFRef}`:""}</Typography>
                            </Typography>
                          );
                        })}
                      </div>
                    )}
                    {movement.actionLog && movement.actionLog.length !== 0 && (
                      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <Typography key={index} variant="caption" fontWeight="bold">
                          Actions
                        </Typography>
                        {movement.actionLog.map((action, index) => {
                          if(movement.facility.selectedSequanceModifier){
                            if(action.container){
                              return (
                                <Typography key={index} variant="caption" color="text.secondary">
                                  {
                                    `
                                    ${action.action} ${action.container.confirmedSelection || action.container.confirmedByDriver ? ` ${action.container.number}` : action.container.BLRef ? `Container W/ B/L ${action.container.BLRef}` : action.container.CRFRef? `Container w/ CRF ${action.container.CRFRef}`:`Container W/ BULK ${action.container.bulkRef}`} @ ${action.dateTime.toDate().toLocaleTimeString()}
                                    `
                                  }
                                </Typography>
                              );
                            }
                            return(
                            <Typography key={index} variant="caption" color="text.secondary">
                              {`${action.action} @ ${action.dateTime.toDate().toLocaleTimeString()}`}
                              </Typography>
                              )
                          }
                          return (
                            <Typography key={index} variant="caption" color="text.secondary">
                              {`${action.action} ${action.container ? ` ${action.container.number} from` : ""} ${movement.facility.name} @ ${action.dateTime.toDate().toLocaleTimeString()}`}
                            </Typography>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  {movements[index+1] && !movement.completed && (movement.actionLog===undefined || movement.actionLog?.length===0) && movement.facility.id === movements[index+1].facility.id && movement.facility.selectedSequanceModifier === movements[index+1].facility.selectedSequanceModifier && (movement.containers.pickup.length===0 || movement.containers.dropoff.length===0) && ((movement.containers.pickup.length!==0 && movements[index+1].containers.pickup.length===0) || (movement.containers.dropoff.length!==0 && movements[index+1].containers.dropoff.length===0)) ? 
                  <Button variant='contained' fullWidth style={{marginTop:"2rem"}} disabled={mergingMovements} onClick={()=>{
                    setMergingMovements(true);
                    mergeMovements(truck.id,movement.docId,movements[index+1].docId);
                  }}>merge {mergingMovements && <CircularProgress size={20} style={{marginLeft:"0.5rem"}}/>}</Button>:null}
                  
                </StepContent>
              </Step>
            ))}
          </Stepper>
          <DialogActions>
            <Button
              startIcon={<AddIcon />}
              variant='contained'
              onClick={() => {
                setOpenAddMovementDialog(true);
              }}
            >
              add movement
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <AddMovementDialog
        open={openAddMovementDialog}
        setOpen={setOpenAddMovementDialog}
        truck={
          {
            basePrice: truck.basePrice,
            extraCosts: truck.extraCosts,
            type: truck.type,
            movements: movements,
            currentLoad: currentLoad
          } as Truck
        }
        onSubmit={async (movement: Movement) => {
          const resp=await addNewMovement({
            movement: movement,
            truckTrackingId: truck.id,
            shipmentId: selectedShipment.id,
          })
          if(!resp.success){
            alert(resp.error);
          }
          return;

        }}
      />
    </>
  );

}
const NewFacilityForm = styled.form`
display:flex;
flex-direction:column;
width:100%;
height:100%;
gap:1.3rem`;

const InlineInputs = styled.div`
display:flex;
flex-direction:row;
width:100%;
gap:1.3rem;
`;
const FileUploadButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 100%;
  align-items: center;
`;
const FacilityDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #c4c4c4;

`;
const MovementsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
  height: 100%;
  margin-top: 1rem;
  outline: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 1rem;
  position: relative;
  `;
const TruckCardDiv = styled.div<{ selected: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-height: 100px;
  border-radius: 5px;
  border: 1px solid #757575;
  gap: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#1c2a57" : "white")};
  color: ${(props) => (props.selected ? "white" : "black")};
  &:hover {
    background-color: ${(props) => (props.selected ? "rgb(19, 29, 60)" : "#EDEDED")};
  }
`;
const TruckCardDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  `;
const LocalMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;
const MainGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  gap: 2rem;
`;
const FormDiv = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height:fit-content;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  gap: 2rem;
  padding-bottom: 5rem;
  position: sticky;
  top: 5rem;
`;

const DriverDetailsDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
`;
const DateTimeSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;
const DateTimeDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 1rem;
`;

const TruckAdditionSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap: 0.5rem;
`;
const TruckAdditionDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr 1fr 0.5fr;
  width: 100%;
  gap: 1rem;
  align-items: center;
  justify-items: center;
`;

const TrucksSection = styled.section`
  display: grid;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  gap:1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  `;

const FacilityTile = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:flex-start;
width:100%;
height:6rem;
border-radius:10px;
border:1px solid #CAC4D0;
padding:0.5rem;
`;
const FacilityDetailsSection = styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:flex-start;
gap:0.5rem;
width:fit-content;
height:100%;
`;
const FacilityActionsSection = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:flex-end;
height:100%;

`;

const FacilityDetails = styled.div`
display:grid;
grid-template-columns:1fr 1fr;
width:100%;
height:100%;
justify-content:flex-start;
align-items:flex-start;
margin-top:1rem;
gap:rem;`;
const InlineDiv = styled.div`
display:flex;
flex-direction:row;
width:100%;
justify-content:flex-start;
align-items:center;
gap:0.1rem;
`;

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});




export type baseTrackingDoc = {
  shipmentId: string,
  shipmentDateTime: Timestamp,
  status: number,
  type: string,
  basePrice: number,
  extraCosts:any[]
}
export type baseTrackingDocWithID = baseTrackingDoc & {
  id: string
}
export type incomingTrackingDoc = baseTrackingDoc & {
  carrierId: string,
  currentLoad?: Container[],
  driverName: string,
  driverMobile: string,
  driverDeviceId: string,
  route: string,
  startDateTime: Timestamp,
  locationLog: any[]

}
export type incomingTrackingDocWithID = incomingTrackingDoc & {
  id: string
}
export type locationLog = {
  location: GeoPoint,
  dateTime: Timestamp
}
export const MainContainer = muiStyled('div')(() => ({
  position: 'fixed',
  overflowY: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  height: '100%',
  backgroundColor: '#EDEDED'

}));