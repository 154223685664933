import { FieldValue, Timestamp, addDoc, collection, serverTimestamp } from "firebase/firestore";
import { Facility, ShipmentForm,ShipmentFormValues } from "../../Pages/POSTGITEXT-NewShipment/Logic/NewShipmentLogic";
import { firestore } from "../Firebase";
import { useAuth } from "../../Contexts/AuthContext";
import { User } from "firebase/auth";
export async function createFacility({facilityValues}:{facilityValues: Facility}): Promise<{success:true,facility:Facility} | {success:false,error:string}> {

    type PreparedFacility=Omit<Facility,"id">;
    const { id, ...preparedFacility } = facilityValues;
    return addDoc(collection(firestore, "Facilities"), preparedFacility as PreparedFacility).then((doc) => {
        return { success: true as const,facility:{...facilityValues,id:doc.id}};
    }).catch((err) => {
        return { success: false as const,error:err.message };
    });
}