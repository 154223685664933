import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import styled from 'styled-components';
import { ACTIONS, Container, INITIAL_MOVEMENT, Movement, ShipmentForm as ShipmentFormType, Truck } from "../Logic/NewShipmentLogic";
import { SectionHeadingStyle } from '../Styles/NewShipmentStyles';
import AddContainerMovement from './AddContainerMovement';
import FacilityDialog from './FacilityDialog';
import { CONTAINER_TO_UNIT, TRUCKS_AND_CONTAINER_CAPACITY } from './ShipmentForm';
import ContainerInfoCells from './ContainerInfoCells';
export default function AddMovementDialog ({open,setOpen,truck,onSubmit}:{open:boolean,setOpen:Function,truck:Truck,onSubmit:(movement:Movement)=>Promise<void>}){
    const [openFacilityDialog,setOpenFacilityDialog]=React.useState<boolean>(false);
    const [openContainerDialog,setOpenContainerDialog]=React.useState<boolean>(false);
    const [submitting,setSubmitting]=React.useState<boolean>(false);
    const [movement,setMovement]=React.useState<Movement>(INITIAL_MOVEMENT);
    async function submitMovement(){
      if(movement.facility.id===""){
        alert("Please select a facility");
        return;
      }
      if(movement.containers.pickup.length===0 && movement.containers.dropoff.length===0){
        alert("Please add at least one container action (pick-up or drop-off)");
        return;
      }
      setSubmitting(true);
      await onSubmit(movement);
      setOpen(false);
      setSubmitting(false);
      setMovement(INITIAL_MOVEMENT);
    }
    function cancelMovement(){
      setOpen(false);
      setMovement(INITIAL_MOVEMENT);
    }
    function dropOffContainer(containerNumber:string){
      setMovement((movement)=>{
        return({
          ...movement,
          containers:{
            ...movement.containers,
            // dropoff:[...movement.containers.dropoff,globalFormState.values.trucks[selectedTruck].currentLoad.find((container)=>{return container.number===containerNumber})!],
            dropoff:[...movement.containers.dropoff,truck.currentLoad.find((container)=>{return container.number===containerNumber})!],
          }
        })
      })
  }
  function pickUpContainer(container:Container){
    setMovement((movement)=>{
      return({
        ...movement,
        containers:{
          ...movement.containers,
          pickup:[...movement.containers.pickup,container],
        }
      })
    })
  }
    
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          maxWidth: "fit-content",
          minWidth: "55%",
          minHeight: "95%",
        },
      }}
    >
      <DialogTitle>Add Movement</DialogTitle>
      <DialogContent style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
        <FacilityTile>
          <FacilityDetailsSection>
            <Typography fontWeight={700}>Destination Facility</Typography>
            {movement.facility.id ? (
              <>
                <Typography component="div" style={{display:"flex"}}>{movement.facility.name} {movement.facility.selectedSequanceModifier? <Typography fontWeight="bold" color="primary">,({movement.facility.selectedSequanceModifier})</Typography>:null}</Typography>
                <Typography>{movement.facility.city.description}</Typography>
              </>
            ) : (
              <Typography>{movement.facility.city.description}</Typography>
            )}
          </FacilityDetailsSection>
          <FacilityActionsSection>
            {movement.facility.id ? <CheckCircleIcon color="success" /> : <CheckCircleOutlineIcon color="disabled" />}
            <Button
              variant={movement.facility.id?"outlined":"contained"}
              color="primary"
              onClick={() => {
                setOpenFacilityDialog(true);
              }}
            >
              {movement.facility.id?"Edit Facility Details":"Add Facility Details"}
            </Button>
          </FacilityActionsSection>
        </FacilityTile>
        <Typography style={SectionHeadingStyle}>Load</Typography>
        <ByContainersTab  movement={movement} setMovement={setMovement} truck={truck} dropOffContainer={dropOffContainer} setOpenContainerDialog={setOpenContainerDialog} />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            cancelMovement();
          }}
          disabled={submitting}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            submitMovement();
          }}
          disabled={submitting}
        >
          Save
        </Button>
      </DialogActions>

      <FacilityDialog movement={movement} setMovement={setMovement} openFacilityDialog={openFacilityDialog} setOpenFacilityDialog={setOpenFacilityDialog} />
      <AddContainerMovement open={openContainerDialog} setOpen={setOpenContainerDialog} containerTypeOptions={Object.keys(CONTAINER_TO_UNIT).filter((containerType) => {

            return (
              CONTAINER_TO_UNIT[containerType][1] +
                movement.containers.pickup.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) +
                truck.currentLoad.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) -
                movement.containers.dropoff.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) <=
              TRUCKS_AND_CONTAINER_CAPACITY[truck.type]
            );
          
          return true;
        })}
        onSubmit={
          (values:Container)=>{
            pickUpContainer(values);
            setOpenContainerDialog(false);
          }
        }
        disableBL={

             movement.containers.pickup.reduce((accumulator, container) => {
                return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
              }, 0) +
                truck.currentLoad.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) -
                movement.containers.dropoff.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) >=
              TRUCKS_AND_CONTAINER_CAPACITY[truck.type]

        }
        disableCRF={

             movement.containers.pickup.reduce((accumulator, container) => {
                return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
              }, 0) +
                truck.currentLoad.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) -
                movement.containers.dropoff.reduce((accumulator, container) => {
                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                }, 0) >=
              TRUCKS_AND_CONTAINER_CAPACITY[truck.type]

        }
        />

    </Dialog>
  );
  }



  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box style={{display:"flex",gap:"0.5rem",flexDirection:"column"}}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


function ByContainersTab({movement,setMovement,truck,dropOffContainer,setOpenContainerDialog}:{movement:Movement,setMovement:React.Dispatch<React.SetStateAction<Movement>>,truck:Truck,dropOffContainer:Function,setOpenContainerDialog:Function}){
  return(
    <>
    <Typography style={{width:"100%"}} fontWeight="bold" textAlign="center" color="primary">Current Load + Pickup</Typography>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead style={{ backgroundColor: "lightGrey" }}>
          <TableRow>
            <TableCell>Container Number</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Net Weight&nbsp;(kg)</TableCell>
            <TableCell align="right">Booking Number</TableCell>
            <TableCell align="right">Seal Number</TableCell>
            <TableCell align="right">Related to B/L</TableCell>
            <TableCell align="right">Related to CRF</TableCell>
            <TableCell align="right">Documents</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { truck.currentLoad
                .filter((container) => {
                  for (let i = 0; i < movement.containers.dropoff.length; i++) {
                    if (movement.containers.dropoff[i].number === container.number) {
                      return false;
                    }
                  }
                  return true;
                })
                .map((container, index) => {
                  return (
                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }, backgroundColor: "lightGrey" }}>
                      <ContainerInfoCells container={container} />
                           <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => {
                            dropOffContainer(container.number);
                          }}
                        >
                          drop off
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
            }
          {movement.containers.pickup.map((container, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <ContainerInfoCells container={container} />

              <TableCell align="right">
                <IconButton
                  onClick={() => {
                    if (window.confirm(`Are you sure you want to delete this container?`)) {
                      setMovement({
                        ...movement,
                        containers: {
                          ...movement.containers,
                          pickup: movement.containers.pickup.filter((_, i) => {
                            return i !== index;
                          }),
                        },
                      });
                    }
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button
      variant="contained"
      onClick={() => {
        setOpenContainerDialog({ open: true, index: null });
      }}
      //TODO IMPORTANT:: if CRF container is selected to be 20 it is allowing users to add more containers than the truck capacity please disable
      disabled={
         movement.containers.pickup.reduce((accumulator, container) => {
              return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
            }, 0) +
              truck.currentLoad.reduce((accumulator, container) => {
                return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
              }, 0) -
              movement.containers.dropoff.reduce((accumulator, container) => {
                return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
              }, 0) >=
            TRUCKS_AND_CONTAINER_CAPACITY[truck.type]

      }
    >
      add container
    </Button>
    {movement.containers.dropoff.length !== 0 ? (
      <>
        <Typography style={{width:"100%"}} fontWeight="bold" textAlign="center" color="primary">Drop-off</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead style={{ backgroundColor: "lightGrey" }}>
              <TableRow>
              <TableCell>Container Number</TableCell>
            <TableCell align="right">Type</TableCell>
            <TableCell align="right">Net Weight&nbsp;(kg)</TableCell>
            <TableCell align="right">Booking Number</TableCell>
            <TableCell align="right">Seal Number</TableCell>
            <TableCell align="right">Related to B/L</TableCell>
            <TableCell align="right">Related to CRF</TableCell>
            <TableCell align="right">Documents</TableCell>
            <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {movement.containers.dropoff.map((container, index) => (
                <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    <ContainerInfoCells container={container} />
                  <TableCell align="right">
                    <Button
                    style={{display:"flex",gap:"0.5rem",alignItems:"center"}}
                    variant='contained'
                    color='error'
                      onClick={() => {
                        if (
                           movement.containers.pickup.reduce((accumulator, container) => {
                                return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                              }, 0) +
                                truck.currentLoad.reduce((accumulator, container) => {
                                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                                }, 0) -
                                movement.containers.dropoff.reduce((accumulator, container) => {
                                  return accumulator + CONTAINER_TO_UNIT[container.type][Number(container.empty)];
                                }, 0) +
                                CONTAINER_TO_UNIT[container.type][Number(container.empty)] >
                              TRUCKS_AND_CONTAINER_CAPACITY[truck.type]
                   
                        ) {
                          alert("Cannot remove container. Truck capacity will be exceeded. (Please delete container in current-load before cancelling the drop-off action).");
                        } else {
                          if (window.confirm(`Are you sure you want to cancel dropping off this container?`)) {
                            setMovement((prevMovement) => {
                              return {
                                ...prevMovement,
                                containers: {
                                  ...prevMovement.containers,
                                  dropoff: prevMovement.containers.dropoff.filter((_, i) => {
                                    return i !== index;
                                  }),
                                },
                              };
                            });
                          }
                        }
                      }}
                    >
                      <CancelIcon/>
                      Cancel Drop-off
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    ) : null}{" "}
    </>
  )
}
const FacilityTile=styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:flex-start;
width:100%;
height:6rem;
border-radius:10px;
border:1px solid #CAC4D0;
padding:0.5rem;
`;
const FacilityDetailsSection=styled.div`
display:flex;
flex-direction:column;
justify-content:flex-start;
align-items:flex-start;
gap:0.5rem;
width:fit-content;
height:100%;
`;
const FacilityActionsSection=styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:flex-end;
height:100%;

`;

const FacilityDetails=styled.div`
display:grid;
grid-template-columns:1fr 1fr;
width:100%;
height:100%;
justify-content:flex-start;
align-items:flex-start;
margin-top:1rem;
gap:rem;`;
const InlineDiv=styled.div`
display:flex;
flex-direction:row;
width:100%;
justify-content:flex-start;
align-items:center;
gap:0.1rem;
`;