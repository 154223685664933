import { signInWithEmailAndPassword, signOut } from 'firebase/auth';
import React from 'react';
import { ReactNode,FC } from 'react';
import {auth} from "../Firebase/Firebase";
import type { User, UserCredential } from 'firebase/auth';

export type AuthContextValue={
    currentUser:User|null;
    authLoading:boolean;
    signInEmail:Function;
    logOut:Function;
}
export type AuthError={
    message:string;
}
export type AuthFunctionReturn={
    success:boolean;
    error?:AuthError;

}
const AuthContext=React.createContext<AuthContextValue>({
    currentUser:null,
    authLoading:true,
    signInEmail:()=>{},
    logOut:()=>{},
});

export function useAuth()
{
    return React.useContext(AuthContext);
}
export function AuthProvider({ children }: { children: ReactNode }): JSX.Element{
    const [currentUser,setCurrentUser]=React.useState<User|null>(null);
    const [authLoading,setAuthLoading]=React.useState<boolean>(true);
    React.useEffect(()=>{
        const unsubscribe=auth.onAuthStateChanged((user:User|null)=>{   
            setCurrentUser(user);
            setAuthLoading(false);
        })
        return unsubscribe;
    },[])
    async function signInEmail({email,password}:{email:string,password:string}):Promise<AuthFunctionReturn>{
         return signInWithEmailAndPassword(auth,email,password).then(()=>{
            return {success:true};
         }
         ).
         catch(()=>{
            return {
                success:false,
                    error:{
                    message:"Login credentials are incorrect",
                    }
            }
        })
    }
    async function logOut():Promise<AuthFunctionReturn>{
       return signOut(auth).then(
              ()=>{
                return {success:true};
              }
       )
       .catch(()=>{
            return {
            success:false,
            error:{
                message:"Logout failed",
            }
            }
        })
    }
    const value: AuthContextValue={
        currentUser,
        authLoading,
        signInEmail,
        logOut,
    }
    return (
        <AuthContext.Provider value={value}>
            {authLoading ? <></> : children}
        </AuthContext.Provider>
    )
}