import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SignIn from './Pages/SignIn/SignIn';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import UnauthenticatedRoutes from './Routes/UnauthenticatedRoutes';
import Join from './Pages/Join/Join';
import PrivateRoutes from './Routes/PrivateRoutes';
import Dashboard from './Pages/Dashboard/Dashboard';
import Landing from './Pages/Landing/Landing';
import { AuthProvider } from './Contexts/AuthContext';
import NewShipment from './Pages/POSTGITEXT-NewShipment/NewShipment';
import Tracking from './Pages/Tracking/Tracking';
import History from './Pages/History/History';
import LocationLog from './Pages/LocationLog/LocationLog';

// import Tracking from './Pages/Tracking/Tracking';
function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1c2a57',
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
  
  });
  
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
        <Routes>
          <Route element={<UnauthenticatedRoutes />}>
            <Route path='/' element={<Navigate to={"/SignIn"}/>}/>
            <Route path="/SignIn" element={<SignIn />} />
            <Route path="/join" element={<Join />} />
          </Route>
          <Route element={<PrivateRoutes />}>
          <Route path='/' element={<Navigate to={"/Dashboard"}/>}/>
            <Route path="/Dashboard/:URLShipment?" element={<Dashboard />} />
            <Route path="/NewShipment/*" element={<NewShipment/>} />
            <Route path="/Tracking/:URLShipment?" element={<Tracking/>} />
            <Route path="/History/:URLShipment?" element={<History />} />
            <Route path="/LocationLog/:URLTrackingID?" element={<LocationLog/>} />
            <Route path="*" element={<div>not found</div>} />
          </Route>
        </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
