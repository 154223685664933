import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as Yup from 'yup';
import { INITIAL_CONTAINER } from "../Logic/NewShipmentLogic";
import ContainerSection from "./ContainerSection";

  function validateContainerNumber(containerNumber:string){
    const regex = /^[A-Z]{4}[0-9]{7}$/;
    if (regex.test(containerNumber)){
      const letterValue = {
        "A": 10,
        "B": 12,
        "C": 13,
        "D": 14,
        "E": 15,
        "F": 16,
        "G": 17,
        "H": 18,
        "I": 19,
        "J": 20,
        "K": 21,
        "L": 23,
        "M": 24,
        "N": 25,
        "O": 26,
        "P": 27,
        "Q": 28,
        "R": 29,
        "S": 30,
        "T": 31,
        "U": 32,
        "V": 34,
        "W": 35,
        "X": 36,
        "Y": 37,
        "Z": 38
    }
    //HLBU8116408
    let sum=0;
    for(let i=0;i<10 ; i++){
      if(i<4){
        //@ts-ignore
        sum+=letterValue[containerNumber[i].toUpperCase()]*(2**i);
      }else{
        sum+=Number(containerNumber[i])*(2**i);
      }
    }
    const first=Math.floor(sum/11);
    const second=first * 11;
    const checkDigit=(sum-second) % 10;
    if(checkDigit===Number(containerNumber[10])){
      return true;
    }
  }
  }

export default function ContainerDialog({containerTypeOptions,onSubmit,openContainerDialog,setOpenContainerDialog,hasToBeFull,excludeContainerNumbers,hasToBeMovementType}:{containerTypeOptions:string[],openContainerDialog:boolean,setOpenContainerDialog:React.Dispatch<React.SetStateAction<boolean>>,onSubmit:Function,hasToBeFull?:boolean,excludeContainerNumbers?:string[],hasToBeMovementType:string}) {

    console.log("containerDialog",openContainerDialog)
    const containerForm=useFormik({
      initialValues:{...INITIAL_CONTAINER,containerMovementType:hasToBeMovementType!==""?hasToBeMovementType:""},
      validationSchema:Yup.object({
        number:Yup.string().required("Invalid Container Number").test("valid-container-number","Invalid Container Number",validateContainerNumber).notOneOf(excludeContainerNumbers?excludeContainerNumbers:[],"Container Number already added"),
        type:Yup.string().required("Required"),
        sealNumber:Yup.string().when('empty',{
          is:false,
          then:()=>{return(Yup.string().matches(/^[a-zA-Z0-9]+$/,"Invalid seal number").required("Required"))},
          otherwise:()=>{return(Yup.string().equals([""],"Invalid Seal Number"))}
        }),
        commodity:Yup.string().when('empty',{
          //@ts-ignore
          is:false,
          then:()=>{return(Yup.string().required("Required"))},
          otherwise:()=>{return(Yup.string().equals([""],"Invalid Commodity"))}
        }),
        empty:Yup.boolean().required("Required"),
        //@ts-ignore
        netWeight:Yup.number().required("Required").when('empty',{
          is:false,
          then:()=>{return(Yup.number().required("Required").min(0,"Invalid Weight").max(40000,"Invalid Weight"))},
          otherwise:()=>{return(Yup.number().equals([0],"Invalid Weight"))}
        }),
      }),
      onSubmit:(values)=>{
        console.log(containerForm.values);
        console.log(containerForm.errors);
        onSubmit({...values,sealNumber:values.sealNumber.toUpperCase(),number:values.number.toUpperCase()});
        setOpenContainerDialog(false);
        containerForm.resetForm();
      },
    })
    return (
      <Dialog
        fullWidth
        PaperProps={{
          style: {
            maxWidth: "fit-content",
            minWidth: "10rem",
            minHeight: "10rem",
            zIndex: 1500,
          },
        }}
        style={{
          zIndex: 1500,
        }}
        open={openContainerDialog}
        // TransitionComponent={Transition}
        keepMounted
        aria-describedby="Add Container Details"
        
      >
        <DialogTitle>{`Add Container Details`}</DialogTitle>
        <DialogContent
          style={{
            height: "100%",
            minHeight: "30rem",
            width: "clamp(25rem, 70vw, 50rem)",
            padding: "1rem ",
          }}
        >
          <ContainerSection containerForm={containerForm} containerTypeOptions={containerTypeOptions} hasToBeFull={hasToBeFull} hasToBeMovementType={hasToBeMovementType}/>
         
        </DialogContent>
        <DialogActions>
        <Button
            variant="outlined"
            onClick={() => {
              setOpenContainerDialog(false);
              containerForm.resetForm();
            }}
          >
            cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              console.log(containerForm.errors)
              containerForm.submitForm();
            }}
          >
            save
          </Button>
  
        </DialogActions>
      </Dialog>
    );
  }
  