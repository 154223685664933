import NavDrawer from "../globalComponents/Navigation/Navigation";
import Box from '@mui/material/Box';
import { styled as muiStyled } from '@mui/material/styles';
import styled from "styled-components";
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
// import ShipmentsTile from "./Components/ShipmentsTile/ShipmentsTile";
import { AuthContextValue, useAuth } from "../../Contexts/AuthContext";
import React from "react";
import { Shipment, ShipmentDoc } from "../../Firebase/Firestore/Shipments";
import { firestore } from "../../Firebase/Firebase";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";
import ShipmentsTile from "./Components/ShipmentsTile/ShipmentsTile";
import { useNavigate } from "react-router-dom";

const DrawerHeader = muiStyled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }));
const MainContainer=muiStyled('div')(()=>({
    display:'flex',
    flexDirection:'row',
    justifyContent:'center',
    alignItems:'flex-start',
    width:'100%',
    height:'100%',
    backgroundColor:'#EDEDED',
    position:'fixed',
    overflowY:'scroll',

}));
const TilesContainer=styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:center;
width:100%;
height:100%;
margin-top:2rem;
`
export default function Dashboard() {
    const authContext:AuthContextValue=useAuth();
    // const [shipments,setShipments]=React.useState<ShipmentDoc[]>([]);
    // const [loading,setLoading]=React.useState<boolean>(true);
    // const [pagination,setPagination]=React.useState<number>(0);
    // // React.useEffect(()=>{
    // //     if(
    // //         authContext.currentUser?.uid === undefined
    // //     ){
    // //         return;
    // //     }
    // //     window.alert("Dashboard useEffect user "+ authContext.currentUser?.uid);
    // //     const q=query(collection(firestore,"Shipments"),where("shipperID","==",authContext.currentUser?.uid),where("status","<=",3),orderBy("status","asc"),orderBy("dateTime","desc"));
    // //     const unsubscribe=onSnapshot(q,(querySnapshot)=>{
    // //         const shipments:ShipmentDoc[]=[];
    // //         querySnapshot.forEach((doc)=>{
    // //             shipments.push(doc.data() as ShipmentDoc);
    // //         })
    // //         setShipments(shipments);
    // //     })
    // //     return unsubscribe;
    // // },[authContext.currentUser?.uid])

    return(

            <MainContainer>
            <NavDrawer />
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <motion.div 
                initial={{ opacity: 0  }}
                animate={{ opacity: 1 }}
                transition={{duration: 2}}>
                    <DrawerHeader />
                    <Typography style={{fontWeight: 700,fontSize: "2.25rem",letterSpacing: "0.1px"}} color="primary">Hello, {authContext.currentUser?.displayName ? authContext.currentUser?.displayName : authContext.currentUser?.email}</Typography>
                    <Typography variant="body2">{new Date().toLocaleString("en-US", {
                        timeZone: "Asia/Bahrain",
                        hour12: false,
                        weekday: "long",
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                    }) }</Typography>
                    <TilesContainer>
                        <ShipmentsTile style={{width:"90%",margin:"0 auto"}}/>
                    </TilesContainer>
                </motion.div>
            </Box>
            </MainContainer>
    );
}

